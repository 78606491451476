import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";

const Comp = (props) => {
  let settaDati = props.settaDati;
  let dati = props.dati;
  let arr = props.arr;
  let index = props.index;

  const arrotonda = (valore) => {
    return Math.round(valore * 100) / 100;
  };

  return (
    <>
      <Grid item xs={12} className="rigaFlexIngressi">
        <TextField
          size="small"
          label="Descrizione"
          type="text"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom"
          value={dati.descrizione || ""}
          onChange={(e) => {
            settaDati(
              arr,
              index,
              { descrizione: e.target.value },
              props.totalePaganti,
              props.totaleNonPaganti
            );
          }}
          style={{ minWidth: "25%" }}
        />
        <TextField
          size="small"
          label="Descrizione interna"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom"
          value={dati.descrizione_interna || ""}
          onChange={(e) => {
            settaDati(
              arr,
              index,
              { descrizione_interna: e.target.value },
              props.totalePaganti,
              props.totaleNonPaganti
            );
          }}
          style={{ minWidth: "25%" }}
        />
        <TextField
          size="small"
          label="A Pagante"
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          value={dati.prezzoPax || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            settaDati(
              arr,
              index,
              { prezzoPax: e.target.value },
              props.totalePaganti,
              props.totaleNonPaganti
            );
          }}
        />
        <TextField
          size="small"
          label="A non pagante"
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          value={dati.prezzoNonPag || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            settaDati(
              arr,
              index,
              { prezzoNonPag: e.target.value },
              props.totalePaganti,
              props.totaleNonPaganti
            );
          }}
        />
        <TextField
          size="small"
          label="A Gruppo"
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          value={dati.prezzoGruppo || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            settaDati(
              arr,
              index,
              { prezzoGruppo: e.target.value },
              props.totalePaganti,
              props.totaleNonPaganti
            );
          }}
        />
        <TextField
          size="small"
          label="Quota a pagante"
          disabled
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          onWheel={(e) => e.target.blur()}
          value={arrotonda(dati.totale / props.totalePaganti) || 0}
        />
        <TextField
          size="small"
          label="Totale"
          disabled
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          onWheel={(e) => e.target.blur()}
          value={dati.totale || 0}
        />
        <DeleteIcon
          className="pointer"
          color="error"
          onClick={(e) => {
            props.eliminaIngresso(index);
          }}
        />
      </Grid>
    </>
  );
};

export default Comp;
