import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import LibriAggiorna from "./LibriAggiorna";
// import PrenotaLibro from "./PrenotaLibro";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Pallino, convertiData, controllaLogin, monFetch } from "../inc/inc";

import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

const Comp = (props) => {
  //const ruoloLogin = controllaLogin();

  let location = useLocation();

  const [mezzi, setMezzi] = useState([]);

  async function query() {
    const res = await monFetch("/transportList", {});
    // console.log(res);
    setMezzi(res.transport);
  }

  async function salva(dati, index) {
    const res = await monFetch("/pdfTransportDescriptionSet", dati);
    if (res.success) {
      setta(index, "disabled", true);
    }
    query();
  }

  function setta(index, key, value) {
    let temp = [...mezzi];
    temp[index][key] = value;
    console.log(temp, index, key)
    setMezzi(temp);
  }

  useEffect(() => {
    query();
    let barraTop = (
      <>
        <h2>Modifica Descrizioni</h2>
      </>
    );
    props.setElementiBarraTop(barraTop);
  }, []);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Mezzo</TableCell>
            <TableCell>Descrizione</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mezzi.map((m, index) => {
            return (
              <TableRow
                key={m.id}
                // className="rigaSelezionabile"
              >
                {/* <TableCell>{m.company_name}</TableCell> */}
                <TableCell style={{width: 250 }}>{m.name}</TableCell>
                <TableCell style={{width: "60%"}}>
                  <TextField
                    size="small"
                    label="Descrizione"
                    rows={2}
                    multiline
                    fullWidth
                    disabled={m.disabled !== false}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="inputCustom"
                    value={m.descriz_pdf || ""}
                    onChange={(e) => {
                      setta(index, "descriz_pdf", e.target.value);
                    }}
                  />
                </TableCell>
                <TableCell style={{ minWidth: 230, textAlign: "right" }}>
                  {m.disabled !== false ? (
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        setta(index, "disabled", false);
                      }}
                    >
                      Modifica
                    </Button>
                  ) : (
                    <>
                      <Button
                        style={{ marginRight: 15 }}
                        onClick={(e) => {
                          setta(index, "disabled", true);
                        }}
                      >
                        Annulla
                      </Button>
                      <Button
                        variant="contained"
                        onClick={(e) => {
                          salva(
                            {
                              id: m.id,
                              descriz_pdf: m.descriz_pdf,
                            },
                            index
                          );
                        }}
                      >
                        Salva
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {/* <Popup open={popupElimina} fnAnnulla={(e)=>setPopupElimina(false)} 
      fnSuccess={(e)=>eliminamarkup(utenteDaEliminare)}
      /> */}
    </>
  );
};

// const Popup = (props) => {
//   return (
//     <Dialog open={props.open} onClose={props.fnAnnulla}>
//       <DialogTitle>Conferma Eliminazione</DialogTitle>
//       <DialogContent>
//         <DialogContentText>
//           Confermi l'eliminazione dell'elemento?
//         </DialogContentText>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={props.fnAnnulla}>Annulla</Button>
//         <Button onClick={props.fnSuccess} variant="contained">
//           Elimina
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

export default Comp;
