import Cookies from "js-cookie";

function Pallino(props) {
  let colore;
  if (props.attivo == 1) {
    colore = "verde";
  } else {
    colore = "rosso";
  }

  return <div className={"pallino " + colore}></div>;
}

function pad(s) {
  return s < 10 ? "0" + s : s;
}

function convertiData(input) {
  if (!input) {
    return;
  }
  var d = new Date(input);
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
}

function convertiDataHM(input) {
  if (!input) {
    return;
  }
  var d = new Date(input);
  let data = [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join(
    "/"
  );
  let oreMinuti = pad(d.getHours()) + ":" + pad(d.getMinutes());
  return data + " " + oreMinuti;
}

function calcDataFine(dataInizio, giorni) {

  let calcDate = new Date(dataInizio);
  calcDate.setDate(calcDate.getDate() + Number(giorni) -1);
  return (
    calcDate.getFullYear() +
    "-" +
    pad(calcDate.getMonth() + 1) +
    "-" +
    pad(calcDate.getDate())
  );
}

function calcDataSommaGiorni(dataInizio, giorni) {
  if(!dataInizio){
    return null;
  }
  let calcDate = new Date(dataInizio);
  calcDate.setDate(calcDate.getDate() + Number(giorni));
  return (
    calcDate.getFullYear() +
    "-" +
    pad(calcDate.getMonth() + 1) +
    "-" +
    pad(calcDate.getDate())
  );
}

const controllaLogin = () => {
  // console.log(window.location);
  if (!Cookies.get("token")) {
    window.location.href = "/login";
  }
};

const monFetch = async (percorso, data) => {
  const response = await fetch(process.env.REACT_APP_BACKEND + percorso, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      monToken: Cookies.get("token") || "",
      monId: Cookies.get("id") || "",
      monAutistaId: Cookies.get("autistaId") || "",
      monComId: Cookies.get("companyId") || "",
    },
    body: JSON.stringify(data),
  });
  let res = await response.json();
  if (res.monCheck == false) {
    Cookies.remove("roles");
    Cookies.remove("token");
    Cookies.remove("id");
    Cookies.remove("companyId");
    Cookies.remove("adminLevel");
    
    Cookies.remove("filtriLista");
    window.location.href = "/login";
  }
  //console.log(res);
  return res;
};

const monMedia = async (percorso, data) => {
  const response = await fetch(process.env.REACT_APP_BACKEND + percorso, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      monToken: Cookies.get("token"),
      monId: Cookies.get("id") || "",
      monAutistaId: Cookies.get("autistaId") || "",
      monComId: Cookies.get("companyId"),
    },
    body: JSON.stringify(data),
  });
  // console.log( await response.json())
  // return
  let res = await response.blob();
  //console.log(res);
  return res;
};

const monForm = async (percorso, data) => {
  const response = await fetch(process.env.REACT_APP_BACKEND + percorso, {
    method: "POST",
    headers: {
      monToken: Cookies.get("token"),
      monId: Cookies.get("id"),
      monComId: Cookies.get("companyId"),
    },
    body: data,
  });
  let res = await response.json();
  //console.log(res);
  return res;
};

const selezionaElemento = (selettore, id) => {
  document.querySelectorAll(selettore).forEach((el) => {
    el.classList.remove("elSelezionato");
    if (el.getAttribute("data-id") == id) {
      el.classList.add("elSelezionato");
    }
  });
};

const checkRuoloUtente = (role) => {
  //array di ruoli
  if (Cookies.get("roles")) {
    let ruoli = JSON.parse(Cookies.get("roles"));
    //console.log(ruoli);
    if (ruoli.includes(role)) {
      return true;
    }
  }
  return false;
};

/**
 * Manda una notifica al browser
 * @param string t Titolo della notifica
 * @param string b Corpo della notifica
 */
const notifyMe = (t, b) => {
  if ("Notification" in window) {
    // console.log("NOTIFICA TRIGGER", t, b);
    var title = t;
    var options = {
      body: b,
      icon: "/img/logo.png",
    };
    if (Notification.permission === "granted") {
      var notification = new Notification(title, options);
      //commentato perchè molti browser danno errore se non c'è interazione
      // document.getElementById("pop-sound").play();
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission(function (permission) {
        if (permission === "granted") {
          var notification = new Notification(title, options);
          document.getElementById("pop-sound").play();
        }
      });
    }
  }
};

// torna tutti i dati delle cose da fare
const notificationPolling = async function () {
  const res = await monFetch("/polling", {});
  return res;
};

const settaCookieFiltri = (chiave, valore) => {
  if (Cookies.get("filtriLista") == undefined) {
    Cookies.set("filtriLista", JSON.stringify({}));
  }
  let filtri = JSON.parse(Cookies.get("filtriLista"));
  filtri[chiave] = valore;

  Cookies.set("filtriLista", JSON.stringify(filtri));
};

const resettaCookieFiltri = () => {
  Cookies.remove("filtriLista");
}

const getValoreCookieFiltri = (chiave) => {
  if (Cookies.get("filtriLista") == undefined) {
    Cookies.set("filtriLista", JSON.stringify({}));
  }

  let dati = JSON.parse(Cookies.get("filtriLista"));
  let valore = null;
  if (
    dati[chiave] != null &&
    dati[chiave] != "" &&
    dati[chiave] != undefined
  ) {
    valore = dati[chiave];
  }
  // console.log(chiave, valore)
  return valore;
};


const checkAutista = ()=>{
  let ret = false
  if (Cookies.get("autistaId")) {
    ret = true;
    // console.log("AUTISTA LOGGATO!");
  }
  return ret;
}

const notificaSuccess = ()=>{
  let notifBox = document.createElement("div");
  notifBox.classList.add("notifica")
  notifBox.classList.add("success")
  notifBox.innerHTML = "Operazione effettuata"
  document.body.append(notifBox);

  setTimeout(()=>{
    notifBox.remove()
  }, 5000)
}

const notificaError = ()=>{
  let notifBox = document.createElement("div");
  notifBox.classList.add("notifica")
  notifBox.classList.add("error")
  notifBox.innerHTML = "C'è stato un errore"
  document.body.append(notifBox);

  setTimeout(()=>{
    notifBox.remove()
  }, 5000)
}

export {
  Pallino,
  convertiData,
  convertiDataHM,
  controllaLogin,
  monFetch,
  monForm,
  monMedia,
  selezionaElemento,
  checkRuoloUtente,
  notifyMe,
  notificationPolling,
  settaCookieFiltri,
  resettaCookieFiltri,
  getValoreCookieFiltri,
  checkAutista,
  notificaSuccess,
  calcDataFine,
  calcDataSommaGiorni,
  pad
};
