import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { monFetch } from "../../inc/inc";

import { useLocation, useParams } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

import "react-image-crop/dist/ReactCrop.css";

const Comp = (props) => {
  let params = useParams();
  let isModifica = false;

  let id = params.id;
  const [testoDomanda, setTestoDomanda] = useState("");
  const [risposte, setRisposte] = useState([""]);

  const [optionsCategorie, setOptionsCategorie] = useState([]);
  const [optionsCategorieSel, setOptionsCategorieSel] = useState([]);
  const [categorie, setCategorie] = useState([]);
  const [errore, setErrore] = useState("");

  // const [aziende, setAziende] = useState([]);
  // const [azienda, setAzienda] = useState([]);

  // const [optionsCategorie, setOptionsCategorie] = useState([]);
  // const [optionsCategorieSel, setOptionsCategorieSel] = useState([]);
  // const [categorie, setCategorie] = useState([]);

  async function query(cat) {
    const res = await monFetch("/faqQuestionGet", {
      id,
    });

    setTestoDomanda(res.question);
    setRisposte(res.answer);

    ///categorie
    setCategorie(res.categories);
    let temp = [];
    res.categories.forEach((el) => {
      let opt = cat.find((el2) => {
        return el == el2.id;
      });
      if (opt) {
        temp.push(opt);
      }
    });
    setOptionsCategorieSel(temp);
  }

  async function queryCategorie() {
    const res = await monFetch("/faqCategoryList", {});
    // console.log(res);
    let temp = [];
    res.res.forEach((el) => {
      temp.push({ label: el.name, id: el.id });
    });
    // console.log(aziende);
    setOptionsCategorie(temp);
    return temp;
  }

  async function salva(e) {
    e.preventDefault();
    const res = await monFetch("/faqQuestionSet", {
      id,
      questionBody: testoDomanda,
      answers: risposte,
      categories: categorie,
    });

    if (res.success) {
      window.location.href = "/faqdomande";
    } else {
      setErrore("Errore nel salvataggio dei dati");
    }
  }

  useEffect(() => {
    async function fn() {
      let cat = await queryCategorie();
      if (id) {
        query(cat);
      }
    }
    fn();

    let barraTop = (
      <h2>{!id ? "Nuova Domanda Faq" : "Modifica Domanda Faq"}</h2>
    );
    props.setElementiBarraTop(barraTop);
  }, []);

  return (
    <form onSubmit={(e) => salva(e)}>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          {errore && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {errore}
            </Alert>
          )}
        </Grid>
        <Grid item sm={12} xs={12}>
          <TextField
            required
            multiline
            rows={2}
            label="Domanda"
            fullWidth
            variant="outlined"
            className="inputCustom"
            value={testoDomanda}
            // disabled={id ? true : false}
            onChange={(e) => {
              setTestoDomanda(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <Autocomplete
            multiple
            value={optionsCategorieSel}
            onChange={(e, value) => {
              // console.log("change autoc", value);
              const temp = [];
              //const opzioniSelezionate = [];
              value.forEach((el) => {
                temp.push(el.id);
              });
              setCategorie(temp);
              setOptionsCategorieSel(value);
            }}
            options={optionsCategorie}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Categorie"
                className="inputCustom"
              />
            )}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <h4 style={{ marginTop: 0 }}>Risposte</h4>
          {risposte.map((el, index) => {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 15,
                }}
              >
                <TextField
                  required
                  multiline
                  rows={2}
                  label="Risposta"
                  fullWidth
                  variant="outlined"
                  className="inputCustom"
                  value={el || ""}
                  // disabled={id ? true : false}
                  onChange={(e) => {
                    let temp = [...risposte];
                    temp[index] = e.target.value;
                    setRisposte(temp);
                  }}
                />
                <DeleteIcon
                  color="error"
                  style={{ marginLeft: 15, cursor: "pointer" }}
                  onClick={(e) => {
                    let temp = [...risposte];
                    temp.splice(index, 1);
                    setRisposte(temp);
                  }}
                />
              </div>
            );
          })}
          <Button
            className="inputCustom"
            variant="contained"
            style={{ marginBottom: 30 }}
            onClick={(e) => {
              let temp = [...risposte];
              temp.push("");
              setRisposte(temp);
            }}
          >
            Aggiungi risposta
          </Button>
        </Grid>

        <Grid item sm={12} xs={12}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              className="inputCustom"
              variant="contained"
              href="/faqdomande"
            >
              Indietro
            </Button>

            <Button
              className="inputCustom bottoneSalva"
              type="submit"
              variant="contained"
            >
              Salva
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

export default Comp;
