import TextField from "@mui/material/TextField";
// import { monFetch } from "../inc/inc";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
// import { useLocation, useParams } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";

const Comp = (props) => {
  let settaDati = props.settaDati;
  let dati = props.dati;
  let arr = props.arr;
  let index = props.index;

  return (
    <>
     {props.mostraCompagnia && <Grid item xs={2}>
        <TextField
          size="small"
          label="Compagnia"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          value={dati.compagniaTrans || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            settaDati(arr, index, { compagniaTrans: e.target.value });
          }}
        />
      </Grid>}
      <Grid item xs={2}>
        <TextField
          size="small"
          label="Andata"
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          value={dati.prezzoAndata || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            settaDati(arr, index, { prezzoAndata: e.target.value });
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          size="small"
          label="Ritorno"
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          value={dati.prezzoRitorno || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            settaDati(arr, index, { prezzoRitorno: e.target.value });
          }}
        />
      </Grid>

      <Grid item xs={2}>
        <TextField
          size="small"
          label="N. Posti"
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          value={dati.postiBus || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            settaDati(arr, index, { postiBus: e.target.value });
          }}
        />
      </Grid>

      <Grid item xs={2}>
        <TextField
          size="small"
          label="Totale"
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          disabled
          variant="outlined"
          className="inputCustom euro"
          onWheel={(e) => e.target.blur()}
          value={dati.totale || 0}
        />
      </Grid>

      <Grid item xs={1}>
        <DeleteIcon
          className="pointer"
          color="error"
          onClick={(e) => {
            props.elimina(index);
          }}
        />
      </Grid>
    </>
  );
};

export default Comp;
