import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { convertiData, controllaLogin, monFetch } from "../../inc/inc";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useLocation, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";

import { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";

import FileUpload from "./FileUpload";

const Comp = () => {
  let location = useLocation();
  let params = useParams();

  let id = params.id;
  const [destinazione, setDestinazione] = useState("");
  const [descrizione1, setDescrizione1] = useState("");
  const [descrizione2, setDescrizione2] = useState("");
  const [descrizione3, setDescrizione3] = useState("");
  const [descrizione4, setDescrizione4] = useState("");
  const [descrizione5, setDescrizione5] = useState("");

  const [contenutoImmagine, setContenutoImmagine] = useState(null);
  const [contenutoImmagine2, setContenutoImmagine2] = useState(null);
  const [mimeImmagine, setMimeImmagine] = useState(null);
  const [mimeImmagine2, setMimeImmagine2] = useState(null);
  const [immagineDB, setImmagineDB] = useState("");
  const [immagineDB2, setImmagineDB2] = useState("");

  const [inCaricamento, setInCaricamento] = useState(false);

  const [errore, setErrore] = useState("");

  const [popupElimina, setPopupElimina] = useState(false);

  async function getImmagine() {
    const res = await monFetch("/destinationImageGet", {
      id,
    });

    setDestinazione(res.imageDestination.destination);
    setDescrizione1(res.imageDestination.description_1);
    setDescrizione2(res.imageDestination.description_2);
    setDescrizione3(res.imageDestination.description_3);
    setDescrizione4(res.imageDestination.description_4);
    setDescrizione5(res.imageDestination.description_5);
    setImmagineDB(res.pathImg.copertina);
    setImmagineDB2(res.pathImg.programma);
  }

  async function salvaImmagine(e) {
    e.preventDefault();
    setInCaricamento(true);
    const res = await monFetch("/destinationImageSet", {
      id,
      destination: destinazione,
      description_1: descrizione1,
      description_2: descrizione2,
      description_3: descrizione3,
      description_4: descrizione4,
      description_5: descrizione5,
      image_mime: mimeImmagine ? mimeImmagine : null,
      image_content: contenutoImmagine ? contenutoImmagine : null,
      image2_mime: mimeImmagine2 ? mimeImmagine2 : null,
      image2_content: contenutoImmagine2 ? contenutoImmagine2 : null,
    });
    setInCaricamento(false);
    if (res.success) {
      window.location.href = "/dest_immagini";
    } else {
      setErrore(res.msg);
    }
  }

  async function eliminaImmagine(id) {
    const res = await monFetch("/destinationImageDelete", { id });

    window.location.href="/dest_immagini"
  }

  useEffect(() => {
    if (id) {
      getImmagine();
    }
  }, []);

  return (
    <form onSubmit={(e) => salvaImmagine(e)}>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <h2 style={{ margin: "10px 0" }}>Immagine destinazione</h2>
          {errore && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {errore}
            </Alert>
          )}
        </Grid>
        <Grid item container spacing={2} sm={4} xs={12}>
          <Grid item sm={12} xs={12}>
            <TextField
              required
              label="Destinazione"
              fullWidth
              variant="outlined"
              className="inputCustom"
              value={destinazione || ""}
              disabled={id ? true : false}
              onChange={(e) => {
                setDestinazione(e.target.value.toUpperCase());
              }}
            />
          </Grid>

          <Grid item sm={12} xs={12}>
            <TextField
              multiline
              rows={2}
              label="Descrizione 1"
              fullWidth
              variant="outlined"
              className="inputCustom"
              value={descrizione1 || ""}
              onChange={(e) => {
                setDescrizione1(e.target.value);
              }}
            />

            <TextField
              multiline
              rows={2}
              label="Descrizione 2"
              fullWidth
              variant="outlined"
              className="inputCustom"
              value={descrizione2 || ""}
              onChange={(e) => {
                setDescrizione2(e.target.value);
              }}
            />

            <TextField
              multiline
              rows={2}
              label="Descrizione 3"
              fullWidth
              variant="outlined"
              className="inputCustom"
              value={descrizione3 || ""}
              onChange={(e) => {
                setDescrizione3(e.target.value);
              }}
            />

            <TextField
              multiline
              rows={2}
              label="Descrizione 4"
              fullWidth
              variant="outlined"
              className="inputCustom"
              value={descrizione4 || ""}
              onChange={(e) => {
                setDescrizione4(e.target.value);
              }}
            />

            <TextField
              multiline
              rows={2}
              label="Descrizione 5"
              fullWidth
              variant="outlined"
              className="inputCustom"
              value={descrizione5 || ""}
              onChange={(e) => {
                setDescrizione5(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid item sm={8} xs={12}>
          <h4 style={{marginTop: 0}}>Copertina</h4>
          <div className="boxImmagine">
            <FileUpload
              setContenutoImmagine={setContenutoImmagine}
              setMimeImmagine={setMimeImmagine}
              classe="canvasImg"
              classeImg="immagineDest"
            />
            <div style={{ width: "100%", marginBottom: 5 }}></div>
            {(id && !contenutoImmagine) ? (
              <>
                <hr style={{ width: "100%" }} />
                {immagineDB && (
                  <div>

                    {immagineDB.media && <img
                      style={{ maxWidth: 400 }}
                      src={
                        "data:" +
                        immagineDB.mime +
                        ";base64," +
                        immagineDB.media
                      }
                    ></img>}
                  </div>
                )}
              </>
            ) : (
              null
            )}
          </div>
          <h4>Programma</h4>
          <div className="boxImmagine2">
            <FileUpload
              setContenutoImmagine={setContenutoImmagine2}
              setMimeImmagine={setMimeImmagine2}
              classe="canvasImg2"
              classeImg="immagineDest2"
            />
            <div style={{ width: "100%", marginBottom: 5 }}></div>
            {(id && !contenutoImmagine2) ? (
              <>
                <hr style={{ width: "100%" }} />
                {immagineDB2 && (
                  <div className="boxImmagine">

                   {immagineDB2.media2 && <img
                      style={{ maxWidth: 400 }}
                      src={
                        "data:" +
                        immagineDB2.mime2 +
                        ";base64," +
                        immagineDB2.media2
                      }
                    ></img>}
                  </div>
                )}
              </>
            ) : (
              null
            )}


          </div>
        </Grid>
        <Grid item sm={12} xs={12}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="contained"
              className="inputCustom"
              href="/dest_immagini"
            >
              Indietro
            </Button>

            {!id ? (
              ////// nuovo
              contenutoImmagine ? (
                <Button
                  className="inputCustom bottoneSalva"
                  type="submit"
                  variant="contained"
                  disabled={inCaricamento}
                >
                  Salva
                </Button>
              ) : (
                <Button disabled>
                  Carica e taglia l'immagine per continuare
                </Button>
              )
            ) : (
              <>
                {/* ///////edit */}
                <Button
                  className="inputCustom bottoneSalva"
                  type="submit"
                  variant="contained"
                  disabled={inCaricamento}
                >
                  Salva
                </Button>
                <Button
                  onClick={() => {
                    setPopupElimina(true);
                  }}
                  variant="contained"
                  className="bottoneElimina"
                >
                  Elimina
                </Button>
              </>
            )}
          </div>
        </Grid>
      </Grid>

      <Popup
        open={popupElimina}
        fnAnnulla={(e) => setPopupElimina(false)}
        fnSuccess={(e) => eliminaImmagine(id)}
      />
    </form>
  );
};

const Popup = (props) => {
  return (
    <Dialog open={props.open} onClose={props.fnAnnulla}>
      <DialogTitle>Conferma Eliminazione</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Confermi l'eliminazione dell'elemento?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Annulla</Button>
        <Button onClick={props.fnSuccess} variant="contained">
          Elimina
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Comp;
