import Button from "@mui/material/Button";
import React, { useState, useEffect, useCallback } from "react";
import Cookies from "js-cookie";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { monFetch, convertiData } from "../../inc/inc";
import Draggable from "react-draggable";
import Paper from "@mui/material/Paper";
import LinkIcon from "@mui/icons-material/Link";
import EditorFull from "../componenti_generici/EditorFull";
import DeleteIcon from "@mui/icons-material/Delete";

import { MediaLista } from "../media/Media";

function PaperComponent(props) {
  const nodeRef = React.useRef(null);
  return (
    <Draggable handle="h2" nodeRef={nodeRef}>
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

const PopupInvioMail = (props) => {
  const [tourOperatorOpt, setTourOperatorOpt] = useState([]);
  const [tourOperator, setTourOperator] = useState(null);
  const [emailTo, setEmailTo] = useState(null);

  const [oggetto, setOggetto] = useState("");
  const [corpo, setCorpo] = useState("");

  const [nomeCognome, setNomeCognome] = useState("");

  const [popupAllegati, setPopupAllegati] = useState(false);
  const [popupConferma, setPopupConferma] = useState(false);

  const [allegatiMail, setAllegatiMail] = useState([]);

  async function getUtente() {
    let res = await monFetch("/userGet", {
      id: Cookies.get("id"),
    });
    if (res.user) {
      setNomeCognome(res.user.first_name + " " + res.user.last_name);
    }
  }

  async function query() {
    const res = await monFetch("/tourOperatorList", {
      response: true,
    });
    let temp = [];
    res.res.forEach((el) => {
      temp.push({
        label:
          el.name + " - " + (el.email ? el.email : "NESSUNA MAIL ASSOCIATA"),
        value: el.id,
        email: el.email,
      });
    });
    setTourOperatorOpt(temp);
  }

  async function inviaMail() {
    console.log(emailTo, corpo);

    let res = await monFetch("/sendMailTourOperator", {
      email: emailTo,
      corpo: corpo,
      oggetto: oggetto,
      idPrev: props.id,
      allegatiMail,
    });
    if (res.success) {
      props.fnAnnulla();
    }
  }

  function rimuoviAllegato(index) {
    let temp = [...allegatiMail];
    temp.splice(index, 1);
    setAllegatiMail(temp);
  }

  useEffect(() => {
    if (!props.open) {
      return;
    }

    setAllegatiMail([]);
    query();
    getUtente();

    let oggettoTemp =
      "Conferma servizi grp sdt ns. rif. #" +
      props.numeroPreventivo +
      " | " +
      props.istituto +
      " | " +
      props.destinazione +
      " | " +
      convertiData(props.partenza) +
      " | " +
      convertiData(props.rientro) +
      " con vs. rif. come da off. allegata";

    //testo introduttivo ----------

    let corpoTemp =
      "Ciao, <br> con piacere sono a <b>CONFERMARE</b> il gruppo di studenti come segue:";
    corpoTemp += "<br><br>";

    corpoTemp +=
      "<b>" + props.totalePaganti + " studenti</b>  (X maschi + Y femmine) ";

    if (props.pagantiInSingola + props.pagantiInDoppia > 0) {
      let testoSingola = "";
      let testoDoppia = "";
      let testoE = "";
      if (props.pagantiInSingola > 0 && props.pagantiInDoppia > 0) {
        testoE = " e ";
      }

      if (props.pagantiInSingola > 0) {
        testoSingola = String(props.pagantiInSingola) + " in singola ";
      }
      if (props.pagantiInDoppia > 0) {
        testoDoppia = String(props.pagantiInDoppia) + " in doppia ";
      }

      corpoTemp += " di cui " + testoSingola + testoE + testoDoppia;
    }

    corpoTemp +=
      "+ <b>" +
      props.totaleNonPaganti +
      " docenti</b> (" +
      props.gratuitiInSingola +
      " in singola + " +
      props.gratuitiInDoppia +
      " in doppia) ";

    if (props.autisti > 0) {
      corpoTemp += "+ <b>" + props.autisti + " autisti</b>";
    }
    corpoTemp += "<br><br>";

    ///date viaggio
    let data1 = props.partenza;
    let data2 = props.rientro;

    let piuGiorni = false;

    if (data2 > data1) {
      piuGiorni = true;
    }

    let mesi = [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre",
    ];

    //giorno e mese inizio
    let inizio = data1.split("-");
    let giornoI = inizio[2];
    let meseI = mesi[inizio[1] - 1];

    //giorno e mese fine
    let fine = data2.split("-");
    let giornoF = fine[2];
    let meseF = mesi[fine[1] - 1];
    let annoF = fine[0];

    //testo inizio (mostro mese solo se diverso dal mese di fine)
    if (meseI != meseF) {
      inizio = giornoI + " " + meseI;
    } else {
      inizio = giornoI;
    }

    //testo fine
    fine = " " + meseF + " " + annoF;

    let data = "";
    if (piuGiorni) {
      data += "dal ";
      data += inizio;
      data += " al ";
      data += giornoF;
      data += fine;
    } else {
      data += inizio + " " + meseI + " " + annoF;
    }

    corpoTemp += "<b>" +( data? data.toUpperCase() : "") + "</b><br>";

    //transfer ----------

    let transL = props.transferLoco;
    if (transL.length > 0) {
      corpoTemp += "<br><b>Transfer in loco:</b>";
      corpoTemp += "<table><tbody>";
      transL.forEach((t) => {
        corpoTemp += "<tr>";
        corpoTemp += "<td>Andata: " + (t.prezzoAndata || 0) + " €   </td>";
        corpoTemp += "<td>Ritorno: " + (t.prezzoRitorno || 0) + " €   </td>";
        corpoTemp += "<td>N. Posti: " + (t.postiBus || 0) + "   </td>";
        // corpoTemp +=
        //   "<td>Totale: " + (t.totale || 0) + " €" + "   </td>";
        corpoTemp += "</tr>";
      });
      corpoTemp += "</tbody></table>";
    }

    //aereo ------------------------
    let aereo = props.aereo;

    if (aereo.length > 0) {
      corpoTemp += "<b>Aereo:</b>";
      corpoTemp += "<table><tbody>";
      aereo.forEach((a, index) => {
        if (index > 0) {
          return;
        }
        corpoTemp += "<tr>";
        corpoTemp += "<td colspan='4'>Andata </td>";
        corpoTemp += "</tr>";
        corpoTemp += "<tr>";
        corpoTemp += "<td>Vettore: " + (a.vettoreA || "") + "</td>";
        corpoTemp += "<td>N. volo: " + (a.nrPartenza || "") + "</td>";
        corpoTemp +=
          "<td>Partenza: " +
          (a.locPartenza ? a.locPartenza.toUpperCase() : "") +
          " " +
          (a.partenzaA || "") +
          "  </td>";
        corpoTemp +=
          "<td>Arrivo: " +
          (a.locPartenzaEnd ? a.locPartenzaEnd.toUpperCase() : "") +
          " " +
          (a.arrivoA || "") +
          "  </td>";
        corpoTemp += "</tr>";
        corpoTemp += "<tr>";
        corpoTemp += "<td colspan='4'>Ritorno </td>";
        corpoTemp += "</tr>";
        corpoTemp += "<tr>";
        corpoTemp += "<td>Vettore: " + (a.vettoreR || "") + "</td>";
        corpoTemp += "<td>N. volo: " + (a.nrRitorno || "") + "</td>";
        corpoTemp +=
          "<td>Partenza: " +
          (a.locRitorno ? a.locRitorno.toUpperCase() : "") +
          " " +
          (a.partenzaR || "") +
          "  </td>";
        corpoTemp +=
          "<td>Arrivo: " +
          (a.locRitornoEnd ? a.locRitornoEnd.toUpperCase() : "") +
          " " +
          (a.arrivoR || "") +
          "  </td>";
        corpoTemp += "</tr>";
      });
      corpoTemp += "</tbody></table>";
    }

    // sistemazioni------------------------
    let sist = props.sistemazioni;
    // let nottiStart = 0;
    if (sist.length > 0) {
      corpoTemp += "<b>Hotel:</b>";
      corpoTemp += "<table><tbody>";
      sist.forEach((s, index) => {
        if (s[0]) {
          let s1 = s[0];
          if (s1.notti == 0) {
            return;
          }
          // let date = new Date(props.partenza);
          // // add days
          // date.setDate(date.getDate() + nottiStart);

          // let date2 = new Date(props.partenza);
          // // add days
          // date2.setDate(date2.getDate() + (nottiStart + Number(s1.notti)));

          corpoTemp += "<tr>";
          corpoTemp +=
            "<td><span> Dal: " +
            (convertiData(s1.inizio) || "") +
            " </span></td>";
          corpoTemp +=
            "<td><span >Al: " + (convertiData(s1.fine) || "") + " </span></td>";
          corpoTemp += "<td>Notti: " + (s1.notti || "") + " </td>";
          corpoTemp += "</tr><tr>";
          corpoTemp += "<td>Località: " + (s1.localita || "") + " </td>";
          corpoTemp +=
            "<td colspan='2'>Nome Struttura: " + (s1.hotel || "") + " </td>";
          corpoTemp += "</tr><tr>";
          corpoTemp +=
            "<td colspan='2'>Posizione: " +
            (s1.posizioneText || "") +
            "  </td>";
          corpoTemp += "<td>Tipo: " + (s1.tipoText || "") + "  </td>";
          corpoTemp += "</tr><tr>";
          corpoTemp +=
            "<td colspan='3'>Trattamento: " +
            (s1.trattamentoText || "") +
            "</td>";
          corpoTemp += "</tr>";

          corpoTemp +=
            "<tr><td  colspan='2'>Costo Notte BB: " +
            (s1.costoNotteBB || 0) +
            " €  </td>";
          corpoTemp +=
            "<td> Gratuità: " +
            (s1.gratuitaBB > 0 ? "1/" + s1.gratuitaBB : "-") +
            "  </td>";
          corpoTemp += "</tr>";
          corpoTemp +=
            "<tr><td  colspan='2'>Costo Notte HB:" +
            (s1.costoNotteHB || 0) +
            " €  </td>";
          corpoTemp +=
            "<td> Gratuità: " +
            (s1.gratuitaHB > 0 ? "1/" + s1.gratuitaHB : "-") +
            "  </td>";
          corpoTemp += "</tr>";
          corpoTemp +=
            "<tr><td  colspan='2'>Costo Notte Pasto:" +
            (s1.costoNottePasto || 0) +
            " €  </td>";
          corpoTemp +=
            "<td> Gratuità: " +
            (s1.gratuitaPasto > 0 ? "1/" + s1.gratuitaPasto : "-") +
            "  </td>";
          corpoTemp += "</tr>";
          corpoTemp +=
            "<tr><td>Suppl. singola: " +
            (s1.suppSingola || 0) +
            " €" +
            "   </td>";
          corpoTemp +=
            "<td>Suppl. doppia: " + (s1.suppDoppia || 0) + " €" + "   </td>";
          corpoTemp +=
            "<td>Suppl. 1 notte: " + (s1.supp1Notte || 0) + " €" + "   </td>";
          corpoTemp += "</tr>";
          corpoTemp += "<tr>";
          corpoTemp +=
            "<td colspan='3'>Pacchetto: " +
            (s1.forfait ? "SI" : "NO") +
            "   </td>";
          corpoTemp += "</tr>";

          if (index != sist.length - 1) {
            //riga vuota
            corpoTemp += "<tr ><td colspan='3'></td></tr>";
          }

          // nottiStart += Number(s1.notti);
        }
      });
      corpoTemp += "</tbody></table>";
    }

    let guide = props.guide;
    if (guide.length > 0) {
      corpoTemp += "<br><b>Visite guidate:</b>";
      corpoTemp += "<table><tbody>";
      guide.forEach((t) => {
        corpoTemp += "<tr>";
        corpoTemp += "<td>Descrizione: " + (t.localita || "") + "  </td>";
        corpoTemp += "<td>Giorno: </td>";
        corpoTemp += "<td>1 guida ogni: " + (t.guidaPers || "") + "  </td>";
        corpoTemp += "<td>Half day: " + (t.halfDay || 0) + " €" + "  </td>";
        corpoTemp += "<td>Full day: " + (t.fullDay || 0) + " €  </td>";
        corpoTemp += "</tr>";
      });
      corpoTemp += "</tbody></table>";
    }

    let escursioni = props.escursioni;
    if (escursioni.length > 0) {
      corpoTemp += "<br><b>Escursioni:</b>";
      corpoTemp += "<table><tbody>";
      escursioni.forEach((t) => {
        corpoTemp += "<tr>";
        corpoTemp += "<td>Descrizione: " + (t.localita || "") + "   </td>";
        corpoTemp += "<td>Giorno: </td>";
        corpoTemp += "<td>Max Pax: " + (t.maxPax || "") + "  </td>";
        corpoTemp += "<td>A gruppo: " + (t.euroGruppo || 0) + " €" + "  </td>";
        corpoTemp += "<td>A pagante: " + (t.euroPax || 0) + " €" + "   </td>";
        // corpoTemp +=
        //   "<td>Totale: " + (t.totale || 0) + " €  </td>";
        corpoTemp += "</tr>";
      });
      corpoTemp += "</tbody></table>";
    }

    let ingressi = props.ingressi;
    if (ingressi.length > 0) {
      corpoTemp += "<br><b>Ingressi:</b>";
      corpoTemp += "<table><tbody>";
      ingressi.forEach((t) => {
        corpoTemp += "<tr>";
        corpoTemp += "<td>Descrizione: " + (t.descrizione || "") + "</td>";
        corpoTemp += "<td>Giorno: </td>";
        corpoTemp += "<td>A pagante: " + (t.prezzoPax || 0) + " €" + "</td>";
        corpoTemp +=
          "<td>A non pagante: " + (t.prezzoNonPag || 0) + " €" + " </td>";
        corpoTemp += "<td>A gruppo: " + (t.prezzoGruppo || 0) + " €" + " </td>";
        // corpoTemp +=
        //   "<td>Totale: " + (t.totale || 0) + " €   </td>";
        corpoTemp += "</tr>";
      });
      corpoTemp += "</tbody></table>";
    }

    corpoTemp += "<br>";
    corpoTemp +=
      "Mi segnalano le seguenti <b>ALLERGIE/INTOLLERANZE:</b><br><br>";

    corpoTemp += "In allegato elenco partecipanti.<br><br>";

    corpoTemp += "Attendo tua riconferma con EC.<br> Cordiali saluti <br>";

    corpoTemp += nomeCognome;

    setOggetto(oggettoTemp);
    setCorpo(corpoTemp);
  }, [props.open, nomeCognome]);

  if (!props.open) {
    return null;
  }
  //controllo peso massimo allegati

  let pesoAllegati = 0;
  allegatiMail.forEach((el) => {
    pesoAllegati += el.size;
  });

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.fnAnnulla}
        maxWidth={"md"}
        fullWidth
        PaperComponent={PaperComponent}
        className="trascinabile"
      >
        <DialogTitle style={{ cursor: "grab" }}>
          Invia Mail Tour Operator
        </DialogTitle>
        <DialogContent style={{ paddingTop: 10 }}>
          <Autocomplete
            freeSolo
            value={tourOperator}
            // onInputChange={(e, value) => {
            //
            // }}
            onChange={(e, value) => {
              // console.log(value)
              setTourOperator(value);
              setEmailTo(value?.email);
            }}
            options={tourOperatorOpt}
            getOptionDisabled={(option) => !option.email}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                label="Fornitore"
                className="inputCustom"
              />
            )}
          />
          <br />
          <TextField
            size="small"
            label="Oggetto"
            type="text"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            multiline
            variant="outlined"
            value={oggetto}
            onChange={(e) => {
              setOggetto(e.target.value);
            }}
          />
          <br />
          <br />
          <EditorFull value={corpo} setValue={setCorpo} />
        </DialogContent>
        <DialogActions>
          {/* allegati mail */}
          <div style={{ width: "100%" }}>
            {allegatiMail.length > 0 && (
              <div className="allegatiMail">
                Allegati:
                {allegatiMail.map((all, index) => {
                  return (
                    <div key={index} className="allegato">
                      <small>
                        <b>
                          {all.nome}{" "}
                          {all.size ? <small>{all.size}MB</small> : ""}
                        </b>
                      </small>
                      <DeleteIcon
                        color="error"
                        style={{ cursor: "pointer" }}
                        onClick={() => rimuoviAllegato(index)}
                      />
                    </div>
                  );
                })}
              </div>
            )}
            {pesoAllegati >= 20 && (
              <div style={{ padding: "0 15px", color: "red" }}>
                Il peso degli allegati deve essere minore di 20 MB
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px 20px",
              }}
            >
              <div>
                <Button
                  variant="contained"
                  onClick={() => setPopupAllegati(true)}
                >
                  <LinkIcon />
                </Button>
              </div>
              <div>
                <Button onClick={props.fnAnnulla}>Annulla</Button>
                <Button
                  onClick={() => {
                    setPopupConferma(true);
                  }}
                  variant="contained"
                  disabled={!emailTo || pesoAllegati > 20} //20 mb
                >
                  INVIA
                </Button>
              </div>
            </div>
          </div>
        </DialogActions>
      </Dialog>

      <PopupAllegati
        open={popupAllegati}
        idPrev={props.id}
        allegatiMail={allegatiMail}
        setAllegatiMail={setAllegatiMail}
        fnAnnulla={(e) => setPopupAllegati(false)}
      />

      <PopupConferma
        open={popupConferma}
        fnSuccess={(e) => {
          inviaMail();
          setPopupConferma(false);
        }}
        fnAnnulla={(e) => setPopupConferma(false)}
      />
    </>
  );
};

const PopupAllegati = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.fnAnnulla}
      fullWidth
      maxWidth="lg"
      PaperComponent={PaperComponent}
      className="trascinabile"
    >
      <DialogTitle style={{ cursor: "grab" }}>Allega</DialogTitle>
      <DialogContent>
        <MediaLista
          allegaON={true}
          idPrev={props.idPrev}
          allegatiMail={props.allegatiMail}
          setAllegatiMail={props.setAllegatiMail}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Chiudi</Button>
        {/* <Button
          variant="contained"
          onClick={props.fnSuccess}
          disabled={!props.tipoMedia}
        >
          Associa
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

const PopupConferma = (props) => {
  return (
    <Dialog open={props.open} onClose={props.fnAnnulla}>
      <DialogTitle style={{ cursor: "grab" }}>
        Confermi l'invio della mail?
      </DialogTitle>
      {/* <DialogContent>Confermi l'invio della mail?</DialogContent> */}
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Chiudi</Button>
        <Button variant="contained" onClick={props.fnSuccess}>
          Invia
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupInvioMail;
