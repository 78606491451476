// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { checkAutista, monFetch, notificaSuccess } from "../inc/inc";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useLocation, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";

import EmailIcon from "@mui/icons-material/Email";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";


const Comp = (props) => {
  //const ruoloLogin = controllaLogin();
  let params = useParams();
  // let location = useLocation();
  let isModifica = false;
  if (!props.nuovo) {
    isModifica = true;
  }

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [attivo, setAttivo] = useState(true);
  const [livelloAdmin, setLivelloAdmin] = useState(0);
  const [azienda, setAzienda] = useState(null);

  const [ruoliFornBus, setRuoliFornBus] = useState({});
  const [aziende, setAziende] = useState([]);

  const [isAutista, setIsAutista] = useState(false);

  const [popupMail, setPopupMail] = useState(false);
  const [logMail, setLogMail] = useState([]);

  async function queryFornBus() {
    const res = await monFetch("/busProviderGet", {
      id: params.id,
    });
    const forn = res.res;
    setNome(forn.name);
    setEmail(forn.email);
    setAttivo(forn.active ? true : false);
    setAzienda(forn.company_id);
  }

  async function queryAziende() {
    const res = await monFetch("/companyList", {});
    // console.log(res);
    let aziende = [];
    res.company.forEach((el) => {
      aziende.push({ label: el.name, id: el.id });
    });
    // console.log(aziende);
    setAziende(aziende);
  }

  async function salvaFornBus(e) {
    e.preventDefault();

    const data = {
      id: params.id,
      email: email,
      name: nome,
      password: password,
      active: attivo ? 1 : 0,
      company_id: azienda,
    };

    const res = await monFetch("/busProviderSet", data);
    // console.log(res);
    // return
    if (res.success) {
      if (isAutista) {
        window.location.href = "/preventivi";
      } else {
        window.location.href = "/fornitori-bus";
      }
      //window.location.reload();
    }
  }

  async function inviaMailBus() {

    const res = await monFetch("/busProviderSendMailPassword", {
      id: params.id,
    });
    if (res.success) {
      setPopupMail(false);
      notificaSuccess()
    }
  }

  useEffect(() => {
    setIsAutista(checkAutista());
    if (Cookies.get("adminLevel") == 2) {
      queryAziende();
    }

    let barraTop = (
      <>
        <h2>
          {isModifica ? "Modifica Fornitore Bus" : "Aggiungi Fornitore Bus"}
        </h2>
      </>
    );
    props.setElementiBarraTop(barraTop);
    if (isModifica) {
      queryFornBus();
    }

    
  }, []);

  return (
    <form onSubmit={(e) => salvaFornBus(e)}>
      <Grid container spacing={2}>
          <Grid item sm={5}>
            <TextField
              label="Email"
              required
              fullWidth
              type="email"
              disabled={isAutista}
              variant="outlined"
              className="inputCustom"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Grid>
          {!isAutista && <Grid item sm={1} xs={1}>
            <Button
              className={
                "inputCustom " + (logMail.length > 0 ? "bottoneSalva" : "")
              }
              href="/preventivi"
              variant="contained"
              fullWidth
              style={{ height: "56px" }}
              disabled={!isModifica}
              onClick={(e) => {
                e.preventDefault();
                setPopupMail(true);
              }}
            >
              <EmailIcon />
            </Button>
          </Grid>}
        <Grid item sm={3} xs={12}>
          <TextField
            label="Nome"
            required
            fullWidth
            disabled={isAutista}
            variant="outlined"
            className="inputCustom"
            value={nome}
            onChange={(e) => {
              setNome(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <TextField
            label="Password"
            fullWidth
            //type="password"
            required={!isModifica}
            variant="outlined"
            className="inputCustom"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {/* <Grid item sm={4} xs={12}>
          {Cookies.get("adminLevel") == 2 && livelloAdmin < 2 && (
            <>
              <h4>Azienda</h4>
              <Autocomplete
                value={aziende.find((op) => op.id == azienda) || null}
                onChange={(e, value) => {
                  // console.log("change autoc", aziende, value);
                  if (!value) {
                    return;
                  }
                  setAzienda(value.id);
                }}
                options={aziende}
                renderInput={(params) => (
                  <TextField {...params} label="Azienda" required />
                )}
              />
            </>
          )}
        </Grid> */}
        <Grid item sm={2} xs={2}>
          <div className="valign inputCustom">
            <span>Attivo: </span>
            <Checkbox
              disabled={isAutista}
              checked={attivo}
              onChange={(e) => setAttivo(e.target.checked)}
            />
          </div>
        </Grid>

        <Grid item sm={12} xs={12} style={{ textAlign: "right" }}>
          <Button
            className="inputCustom bottoneSalva"
            type="submit"
            variant="contained"
          >
            Salva
          </Button>
        </Grid>
      </Grid>
      <Popup
        open={popupMail}
        fnAnnulla={(e) => setPopupMail(false)}
        fnSuccess={(e) => inviaMailBus()}
      />
    </form>
  );
};

const Popup = (props) => {
  return (
    <Dialog open={props.open} onClose={props.fnAnnulla}>
      <DialogTitle>Conferma invio password</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Una mail con una nuova password autogenerata verrà inviata al fornitore selezionato.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Annulla</Button>
        <Button onClick={props.fnSuccess} variant="contained">
          Invia
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Comp;
