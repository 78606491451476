
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import {
  Pallino,
  convertiData,
  controllaLogin,
  monFetch,
  monForm,
} from "../inc/inc";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useLocation, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import { FileUploader } from "react-drag-drop-files";
import VisibilityIcon from "@mui/icons-material/Visibility";

const Comp = (props) => {
  //const ruoloLogin = controllaLogin();
  let params = useParams();
  let location = useLocation();

  const [nome, setNome] = useState("");
  const [cognome, setCognome] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [attivo, setAttivo] = useState(false);
  const [livelloAdmin, setLivelloAdmin] = useState(0);
  const [azienda, setAzienda] = useState(null);
  const [immagine, setImmagine] = useState(null);

  const [ruoli, setRuoli] = useState([]);
  const [ruoliUtente, setRuoliUtente] = useState({});
  const [aziende, setAziende] = useState([]);

  const [optionsLivello, setOptionsLivello] = useState([]);

  const [nomeFile, setNomeFile] = useState("");
  const [file, setFile] = useState(null);

  async function queryUtente() {
    const res = await monFetch("/userGet", {
      id: params.utente,
    });
    const user = res.user;
    setNome(user.first_name);
    setCognome(user.last_name);
    setEmail(user.email);
    setAttivo(user.active ? true : false);
    setLivelloAdmin(user.admin_level);
    setAzienda(user.company_id);
    setImmagine(user.image_path);
    //ruoli utente
    const ruoliTemp = {};
    user.roles.forEach((el) => {
      ruoliTemp[el.role_id] = true;
    });
    setRuoliUtente(ruoliTemp);
  }

  async function queryRuoli() {
    const res = await monFetch("/roleList", {});
    // console.log(res);
    setRuoli(res.role);
  }

  async function queryLivelliAdmin() {
    const res = await monFetch("/adminLevelList", {});
    // console.log(res);
    const livelliAdmin = [];
    res.level.forEach((el) => {
      livelliAdmin.push({ label: el.level, value: el.id });
    });
    // console.log(livelliAdmin);
    setOptionsLivello(livelliAdmin);
  }

  async function queryAziende() {
    const res = await monFetch("/companyList", {});
    // console.log(res);
    let aziende = [];
    res.company.forEach((el) => {
      aziende.push({ label: el.name, id: el.id });
    });
    // console.log(aziende);
    setAziende(aziende);
  }

  async function salvaUtente(e) {
    e.preventDefault();

    //array di id ruoli da passare
    const ruoliUtenteSalva = [];
    Object.entries(ruoliUtente).forEach((k) => {
      if (k[1] == true) {
        ruoliUtenteSalva.push(k[0]);
      }
    });

    // console.log(password);
    // return

    const data = {
      id: params.utente,
      email: email,
      first_name: nome,
      last_name: cognome,
      password: password,
      active: attivo ? 1 : 0,
      roles: ruoliUtenteSalva,
      admin_level: livelloAdmin,
      company_id: azienda,
    };

    if (livelloAdmin == 2) {
      delete data.company_id;
    }
    const res = await monFetch("/userUpdate", data);
    // console.log(res);
    // return

    /////carico file immagine
    await caricaFile(file);

    if (res.success) {
      //window.location.reload();
      window.location.href = "/utenti";
    }
  }

  async function caricaFile(file, action = null) {
    // e.preventDefault();

    var formData = new FormData();
    // let file = document.querySelector("#fileUpload").files[0];
    if (!file && !action) {
      return;
    }
    // let idPrev = p.idPrev;
    const d = new Date();
    if (action == "delete") {
      formData.append("user_id", params.utente);
      formData.append("delete", true);
    } else {
      formData.append("file", file);
      formData.append("user_id", params.utente);
    }

    const res = await monForm("/userFileAdd", formData);
    if (res.media) {
      setNomeFile("File Caricato!");
    } else if (res.msg) {
      setNomeFile("File Eliminato");
      setImmagine(null);
    }
  }

  useEffect(() => {
    queryRuoli();

    if (Cookies.get("adminLevel") == 2) {
      queryAziende();
    }

    let barraTop = (
      <>
        <h2>Modifica utente</h2>
      </>
    );
    props.setElementiBarraTop(barraTop);
    queryLivelliAdmin();
    queryUtente();
  }, []);

  return (
    <form
      onSubmit={(e) => {
        salvaUtente(e);
        caricaFile(file);
      }}
    >
      <Grid container spacing={2}>
        <Grid item sm={4} xs={12}>
          <TextField
            label="Email"
            required
            fullWidth
            type="email"
            variant="outlined"
            className="inputCustom"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextField
            label="Nome"
            required
            fullWidth
            variant="outlined"
            className="inputCustom"
            value={nome}
            onChange={(e) => {
              setNome(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextField
            label="Cognome"
            required
            fullWidth
            variant="outlined"
            className="inputCustom"
            value={cognome}
            onChange={(e) => {
              setCognome(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextField
            label="Password"
            fullWidth
            //type="password"
            variant="outlined"
            className="inputCustom"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={4} xs={12}>
          <h4>Livello Admin</h4>
          <Autocomplete
            value={
              optionsLivello.length > 0
                ? optionsLivello.find((op) => op.value == livelloAdmin)
                : null
            }
            onChange={(e, value) => {
              // console.log("change autoc", value);
              if (!value) {
                return;
              }
              setLivelloAdmin(value.value);
            }}
            options={optionsLivello}
            renderInput={(params) => (
              <TextField {...params} label="Livello" required />
            )}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          {Cookies.get("adminLevel") == 2 && livelloAdmin < 2 && (
            <>
              <h4>Azienda</h4>
              <Autocomplete
                value={aziende.find((op) => op.id == azienda) || null}
                onChange={(e, value) => {
                  // console.log("change autoc", aziende, value);
                  if (!value) {
                    return;
                  }
                  setAzienda(value.id);
                }}
                options={aziende}
                renderInput={(params) => (
                  <TextField {...params} label="Azienda" required />
                )}
              />
            </>
          )}
        </Grid>
        <Grid item sm={12} xs={12}>
          <div className="valign inputCustom">
            <span>Attivo: </span>
            <Checkbox
              checked={attivo}
              onChange={(e) => setAttivo(e.target.checked)}
            />
          </div>
        </Grid>

        <Grid item sm={12} xs={12}>
          <h4 style={{ marginTop: 0 }}>Ruoli</h4>
          {ruoli.map((ruolo) => {
            return (
              <div
                key={ruolo.id}
                className="valign"
                style={{ display: "inline-block" }}
              >
                <Checkbox
                  checked={ruoliUtente[ruolo.id] || false}
                  onChange={(e) => {
                    const ruoliUtente2 = { ...ruoliUtente };
                    ruoliUtente2[ruolo.id] = e.target.checked;
                    setRuoliUtente(ruoliUtente2);
                  }}
                />
                <span>{ruolo.name}</span>
              </div>
            );
          })}
        </Grid>
        <Grid item sm={12} xs={12}>
          <h4 style={{ marginTop: 0 }}>Immagine</h4>

          <div style={{ width: "100%" }}>
            <FileUploader
              classes="fileArea"
              label="Carica file"
              name="fileUpload"
              handleChange={(file) => {
                setNomeFile(file?.name ? file?.name : "");
                setFile(file);
              }}
            />
            <div style={{ marginTop: 10 }}>{nomeFile}</div>
            {immagine && (
              <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
                <img
                  src={
                    process.env.REACT_APP_BACKEND +
                    "/userFileGet/" +
                    params.utente
                  }
                  style={{ width: 100, marginTop: 10 }}
                ></img>
                <Button
                className="bottoneElimina"
                  variant="contained"
                  onClick={(e) => {
                    caricaFile(null, "delete");
                  }}
                >
                  <DeleteIcon 
                  // color="error" 
                  style={{ cursor: "pointer" }} />
                </Button>
              </div>
            )}
          </div>
        </Grid>
        <Grid item sm={12} xs={12} style={{ textAlign: "right" }}>
          <Button
            className="inputCustom bottoneSalva"
            type="submit"
            variant="contained"
          >
            Salva
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Comp;
