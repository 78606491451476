// import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";


import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";



const PopupRinomina = (props) => {
  return (
    <Dialog open={props.open} onClose={props.fnAnnulla}>
      <DialogTitle>Rinomina file</DialogTitle>
      <DialogContent>
        <TextField
          label="Nuovo nome"
          fullWidth
          variant="outlined"
          style={{ width: 450, marginTop: 10 }}
          value={props.nomeFileDaRinominare}
          onChange={(e) => {
            props.setNomeFileDaRinominare(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Annulla</Button>
        <Button onClick={props.fnSuccess} variant="contained">
          Salva
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupRinomina ;
