import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import LibriAggiorna from "./LibriAggiorna";
// import PrenotaLibro from "./PrenotaLibro";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Pallino, convertiData, controllaLogin, monFetch } from "../inc/inc";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useLocation, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";

const Comp = () => {
  //const ruoloLogin = controllaLogin();
  let params = useParams();
  let location = useLocation();

  const [nome, setNome] = useState("");
  const [descrizione, setDescrizione] = useState("");
  const [keyRuolo, setKeyRuolo] = useState("");

  async function queryRuolo() {
    const res = await monFetch("/roleGet", {
      id: params.ruolo,
    });
    console.log(res);
    const level = res.role;
    setNome(level.name);
    setDescrizione(level.description);
    setKeyRuolo(level.roleKey)
  }

  async function salvaUtente(e) {
    e.preventDefault();
    // console.log(password);
    // return
    const res = await monFetch("/roleSet", {
      id: params.ruolo,
      name: nome,
      description: descrizione,
      roleKey: keyRuolo
    });
    // console.log(res);

    if (res.success) {
      window.location.reload();
    }
  }

  useEffect(() => {
    queryRuolo();
  }, []);

  return (
    <form onSubmit={(e) => salvaUtente(e)}>
      <h2>Modifica Ruolo</h2>
      <TextField
      style={{marginBottom:"20px"}}
        required
        label="Nome"
        fullWidth
        variant="outlined"
        className="inputCustom"
        value={nome || ""}
        onChange={(e) => {
          setNome(e.target.value);
        }}
      />
      <TextField
       style={{marginBottom:"20px"}}
        label="Descrizione"
        fullWidth
        variant="outlined"
        className="inputCustom"
        value={descrizione || ""}
        onChange={(e) => {
          setDescrizione(e.target.value);
        }}
      />
      <TextField
       style={{marginBottom:"20px"}}
        label="Chiave"
        fullWidth
        variant="outlined"
        className="inputCustom"
        value={keyRuolo || ""}
        onChange={(e) => {
          setKeyRuolo(e.target.value);
        }}
      />
      <Button className="inputCustom" type="submit" variant="contained">
        Salva
      </Button>
    </form>
  );
};

export default Comp;
