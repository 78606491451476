import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import LibriAggiorna from "./LibriAggiorna";
// import PrenotaLibro from "./PrenotaLibro";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Pallino, convertiData, controllaLogin, monFetch } from "../inc/inc";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const Comp = (props) => {
  //const ruoloLogin = controllaLogin();

  let location = useLocation();

  const [markup, setMarkup] = useState([]);

  async function queryMarkup() {
    const res = await monFetch("/markupList", {});
    // console.log(res);
    setMarkup(res.markup);
  }

  async function salvaMarkup(dati, index) {
    const res = await monFetch("/markupSet", dati);
    if (res.success) {
      settaMarkup(index, "disabled", true);
    }
    queryMarkup()
  }

  function settaMarkup(index, key, value) {
    let temp = [...markup];
    temp[index][key] = value;
    setMarkup(temp);
  }

  useEffect(() => {
    queryMarkup();
    let barraTop = (
      <>
        <h2>Modifica Markup</h2>
      </>
    );
    props.setElementiBarraTop(barraTop);
  }, []);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Mezzo</TableCell>
            <TableCell>Markup entro limite</TableCell>
            <TableCell>Limite</TableCell>
            <TableCell>Markup oltre limite</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {markup.map((markup, index) => {
            return (
              <TableRow
                key={markup.id}
                // className="rigaSelezionabile"
              >
                {/* <TableCell>{markup.company_name}</TableCell> */}
                <TableCell>{markup.name}</TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    label="Mu entro limite"
                    fullWidth
                    disabled={markup.disabled !== false}
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="inputCustom"
                    value={markup.markup_under || 0}
                    onChange={(e) => {
                      settaMarkup(index, "markup_under", e.target.value);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    label="Limite"
                    fullWidth
                    type="number"
                    disabled={markup.disabled !== false}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="inputCustom"
                    value={markup.n_limit || 0}
                    onChange={(e) => {
                      settaMarkup(index, "n_limit", e.target.value);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    label="Mu oltre limite"
                    fullWidth
                    type="number"
                    disabled={markup.disabled !== false}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="inputCustom"
                    value={markup.markup_over || 0}
                    onChange={(e) => {
                      settaMarkup(index, "markup_over", e.target.value);
                    }}
                  />
                </TableCell>
                <TableCell style={{ minWidth: 250 }}>
                  {markup.disabled !== false ? (
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        settaMarkup(index, "disabled", false);
                      }}
                    >
                      Modifica
                    </Button>
                  ) : (
                    <>
                      <Button
                        style={{ marginRight: 15 }}
                        onClick={(e) => {
                          settaMarkup(index, "disabled", true);
                        }}
                      >
                        Annulla
                      </Button>
                      <Button
                        variant="contained"
                        onClick={(e) => {
                          salvaMarkup(
                            {
                              id: markup.id,
                              transport_id:  markup.transport_id,
                              markup_over: markup.markup_over,
                              markup_under: markup.markup_under,
                              n_limit: markup.n_limit,
                            },
                            index
                          );
                        }}
                      >
                        Salva
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {/* <Popup open={popupElimina} fnAnnulla={(e)=>setPopupElimina(false)} 
      fnSuccess={(e)=>eliminamarkup(utenteDaEliminare)}
      /> */}
    </>
  );
};

// const Popup = (props) => {
//   return (
//     <Dialog open={props.open} onClose={props.fnAnnulla}>
//       <DialogTitle>Conferma Eliminazione</DialogTitle>
//       <DialogContent>
//         <DialogContentText>
//           Confermi l'eliminazione dell'elemento?
//         </DialogContentText>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={props.fnAnnulla}>Annulla</Button>
//         <Button onClick={props.fnSuccess} variant="contained">
//           Elimina
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

export default Comp;
