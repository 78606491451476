import * as React from "react";
import { useState, useEffect } from "react";
import {
  controllaLogin,
  notifyMe,
  notificationPolling,
  checkRuoloUtente,
} from "./inc/inc";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route, Link } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Cookies from "js-cookie";

import Login from "./comp/Login";
import RecuperaPassword from "./comp/RecuperaPassword";
import ReimpostaPassword from "./comp/ReimpostaPassword";
import UtentiLista from "./comp/UtentiLista";
import UtentiNuovo from "./comp/UtentiNuovo";
import UtentiModifica from "./comp/UtentiModifica";

import RuoliLista from "./comp/RuoliLista";
import RuoliNuovo from "./comp/RuoliNuovo";
import RuoliModifica from "./comp/RuoliModifica";

import AziendeLista from "./comp/AziendeLista";
import AziendeNuovo from "./comp/AziendeNuovo";
import AziendeModifica from "./comp/AziendeModifica";

import PreventiviLista from "./comp/PreventiviLista";
import PreventiviNuovoAggiorna from "./comp/PreventiviNuovoAggiorna";
import PreventiviMedia from "./comp/media/PreventiviMedia";

import FileLista from "./comp/media/FileLista";

import Dashboard from "./comp/Dashboard";

import Layout from "./comp/layout/Layout";

import CalcoloPreventivo from "./comp/CalcoloPreventivo";

import PrezziModifica from "./comp/PrezziModifica";
import MarkupModifica from "./comp/MarkupModifica";
import DescrizioniPDF from "./comp/DescrizioniPDF";

import BusFornitoriLista from "./comp/BusFornitoriLista";
import BusFornitoriModifica from "./comp/BusFornitoriModifica";

import DestImmaginiLista from "./comp/generazionePDF/DestImmaginiLista";
import DestImmaginiEdit from "./comp/generazionePDF/DestImmaginiEdit";
import DestProgrammiLista from "./comp/generazionePDF/DestProgrammiLista";
import DestProgrammiEdit from "./comp/generazionePDF/DestProgrammiEdit";

import DestGuide from "./comp/DestGuide";
import DestIngressi from "./comp/DestIngressi";
import DestEscursioni from "./comp/DestEscursioni";
import DestHotel from "./comp/DestHotel";

import HotelFornitori from "./comp/HotelFornitori";

import CategoryLista from "./comp/faq/CategoryLista";
import CategoryEdit from "./comp/faq/CategoryEdit";
import UserEdit from "./comp/faq/UserEdit";
import UserLista from "./comp/faq/UserLista";

import DomandaEdit from "./comp/faq/DomandaEdit";
import DomandaLista from "./comp/faq/DomandaLista";

import VoliLista from "./comp/VoliLista";
import VoliEdit from "./comp/VoliEdit";

function App() {
  const [elementiBarraTop, setElementiBarraTop] = useState(null);

  let arrayNotifichePrec = [];

  const theme = createTheme({
    palette: {
      primary: {
        main: "#666",
      },
    },
  });

  function arraySonoUguali(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  useEffect(() => {
    //notifica -------------
    if (Cookies.get("token")) {
      //solo se loggato

      //recupera dati notifiche
      let fne = async () => {
        let res = await notificationPolling();
        //setArrayResNotifiche(res);

        let temp = [];
        if (checkRuoloUtente("assegnazione-operatore")) {
          temp.push(res.toAssign);
        }
        if (checkRuoloUtente("realizzazione-preventivo")) {
          temp.push(res.toDo);
          temp.push(res.toReview);
        }
        if (checkRuoloUtente("controllore")) {
          temp.push(res.toCheck);
        }
        if (checkRuoloUtente("finalizzazione-preventivo")) {
          temp.push(res.toFinalize);
        }
        if (checkRuoloUtente("invio-preventivo")) {
          temp.push(res.toSend);
        }
        // console.log(arrayNotifichePrec, temp);
        //se non è cambiato nulla return

        if (Cookies.get("arrayNotifiche") == undefined) {
          Cookies.set("arrayNotifiche", JSON.stringify([]));
        }
        if (arraySonoUguali(JSON.parse(Cookies.get("arrayNotifiche")), temp)) {
          // console.log(JSON.parse(Cookies.get("arrayNotifiche")), temp);
          return;
        }
        //arrayNotifichePrec = temp;
        Cookies.set("arrayNotifiche", JSON.stringify(temp));

        //contenuto notifica
        let totaleNotifiche = 0;

        let $contenutoNotifica = "";
        if (checkRuoloUtente("assegnazione-operatore")) {
          if (res.toAssign > 0) {
            $contenutoNotifica +=
              "Hai " + res.toAssign + " preventivi da assegnare\r\n";
            totaleNotifiche += res.toAssign;
          }
        }
        if (checkRuoloUtente("realizzazione-preventivo")) {
          if (res.toDo > 0) {
            $contenutoNotifica += "Hai " + res.toDo + " preventivi da fare\r\n";
            totaleNotifiche += res.toDo;
          }
          if (res.toReview > 0) {
            $contenutoNotifica +=
              "Hai " + res.toReview + " preventivi da rivedere\r\n";

            totaleNotifiche += res.toReview;
          }
        }
        if (checkRuoloUtente("controllore")) {
          if (res.toCheck > 0) {
            $contenutoNotifica +=
              "Hai " + res.toCheck + " preventivi da controllare\r\n";
            totaleNotifiche += res.toCheck;
          }
        }
        if (checkRuoloUtente("finalizzazione-preventivo")) {
          if (res.toFinalize > 0) {
            $contenutoNotifica +=
              "Hai " + res.toFinalize + " preventivi da finalizzare\r\n";
            totaleNotifiche += res.toFinalize;
          }
        }
        if (checkRuoloUtente("invio-preventivo")) {
          if (res.toSend > 0) {
            $contenutoNotifica +=
              "Hai " + res.toSend + " preventivi da inviare\r\n";
            totaleNotifiche += res.toSend;
          }
        }

        //console.log($contenutoNotifica);
        //setArrayTriggerNotifiche(temp);
        if (totaleNotifiche > 0) {
          notifyMe(
            "Hai " + totaleNotifiche + " cose da fare",
            $contenutoNotifica
          );
        }
      };

      setInterval(fne, 30 * 1000); // ogni 30 secondi
      fne();
    }
  }, []);

  // useEffect(() => {
  //   if (arrayTriggerNotifiche.length == 0) {
  //     return;
  //   }
  // }, [arrayTriggerNotifiche]);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <CssBaseline />

        {/* <Container style={{maxWidth: "100%"}}> */}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="recupera-password" element={<RecuperaPassword />} />
          <Route path="reimposta-password" element={<ReimpostaPassword />} />

          <Route
            path="/"
            element={<Layout elementiBarraTop={elementiBarraTop} />}
          >
            <Route
              path="/"
              element={<Dashboard setElementiBarraTop={setElementiBarraTop} />}
            />

            <Route
              path="preventivi"
              element={
                <PreventiviLista setElementiBarraTop={setElementiBarraTop} />
              }
            />
            <Route
              path="preventivo-nuovo"
              element={
                <PreventiviNuovoAggiorna
                  setElementiBarraTop={setElementiBarraTop}
                />
              }
            />
            <Route
              path="preventivo/:id"
              element={
                <PreventiviNuovoAggiorna
                  setElementiBarraTop={setElementiBarraTop}
                />
              }
            />

            <Route
              path="preventivo/media/:id"
              element={
                <PreventiviMedia
                  elementiBarraTop={elementiBarraTop}
                  setElementiBarraTop={setElementiBarraTop}
                />
              }
            />

            <Route
              path="utenti"
              element={
                <UtentiLista setElementiBarraTop={setElementiBarraTop} />
              }
            />
            <Route
              path="utente-nuovo"
              element={
                <UtentiNuovo setElementiBarraTop={setElementiBarraTop} />
              }
            />
            <Route
              path="utente/:utente"
              element={
                <UtentiModifica setElementiBarraTop={setElementiBarraTop} />
              }
            />

            <Route
              path="ruoli"
              element={<RuoliLista setElementiBarraTop={setElementiBarraTop} />}
            />
            <Route
              path="ruolo-nuovo"
              element={<RuoliNuovo setElementiBarraTop={setElementiBarraTop} />}
            />
            <Route
              path="ruolo/:ruolo"
              element={
                <RuoliModifica setElementiBarraTop={setElementiBarraTop} />
              }
            />

            <Route
              path="aziende"
              element={
                <AziendeLista setElementiBarraTop={setElementiBarraTop} />
              }
            />
            <Route
              path="azienda-nuovo"
              element={
                <AziendeNuovo setElementiBarraTop={setElementiBarraTop} />
              }
            />
            <Route
              path="azienda/:azienda"
              element={
                <AziendeModifica setElementiBarraTop={setElementiBarraTop} />
              }
            />

            <Route
              path="files"
              element={<FileLista setElementiBarraTop={setElementiBarraTop} />}
            />

            <Route
              path="calcoloPreventivo/:id/:idProposta?"
              element={
                <CalcoloPreventivo setElementiBarraTop={setElementiBarraTop} />
              }
            />

            <Route
              path="modificaPrezzi"
              element={
                <PrezziModifica setElementiBarraTop={setElementiBarraTop} />
              }
            />
            <Route
              path="modificaMarkup"
              element={
                <MarkupModifica setElementiBarraTop={setElementiBarraTop} />
              }
            />

            <Route
              path="descrizioniPdf"
              element={
                <DescrizioniPDF setElementiBarraTop={setElementiBarraTop} />
              }
            />

            {/* fornitori bus ---------- */}
            <Route
              path="fornitori-bus"
              element={
                <BusFornitoriLista setElementiBarraTop={setElementiBarraTop} />
              }
            />
            <Route
              path="fornitori-bus/nuovo"
              element={
                <BusFornitoriModifica
                  setElementiBarraTop={setElementiBarraTop}
                  nuovo={true}
                />
              }
            />
            <Route
              path="fornitori-bus/:id"
              element={
                <BusFornitoriModifica
                  setElementiBarraTop={setElementiBarraTop}
                  nuovo={false}
                />
              }
            />

            {/* FASE 3 --- immagini e programmi viaggi - destinazioni */}
            <Route
              path="dest_immagini/"
              element={
                <DestImmaginiLista setElementiBarraTop={setElementiBarraTop} />
              }
            />

            <Route
              path="dest_immagini/nuovo"
              element={
                <DestImmaginiEdit
                  setElementiBarraTop={setElementiBarraTop}
                  nuovo={true}
                />
              }
            />

            <Route
              path="dest_immagini/:id"
              element={
                <DestImmaginiEdit
                  setElementiBarraTop={setElementiBarraTop}
                  nuovo={false}
                />
              }
            />

            {/*  */}

            <Route
              path="dest_programmi/"
              element={
                <DestProgrammiLista setElementiBarraTop={setElementiBarraTop} />
              }
            />

            <Route
              path="dest_programmi/nuovo"
              element={
                <DestProgrammiEdit
                  setElementiBarraTop={setElementiBarraTop}
                  nuovo={true}
                />
              }
            />

            <Route
              path="dest_programmi/:id"
              element={
                <DestProgrammiEdit
                  setElementiBarraTop={setElementiBarraTop}
                  nuovo={false}
                />
              }
            />

            <Route
              path="dest_guide"
              element={<DestGuide setElementiBarraTop={setElementiBarraTop} />}
            />

            <Route
              path="dest_escursioni"
              element={
                <DestEscursioni setElementiBarraTop={setElementiBarraTop} />
              }
            />

            <Route
              path="dest_ingressi"
              element={
                <DestIngressi setElementiBarraTop={setElementiBarraTop} />
              }
            />

            <Route
              path="dest_hotel"
              element={<DestHotel setElementiBarraTop={setElementiBarraTop} />}
            />

            <Route
              path="fornitori_hotel"
              element={
                <HotelFornitori setElementiBarraTop={setElementiBarraTop} />
              }
            />

            {/* <Route
              path="files/:file"
              element={
                <FileModifica setElementiBarraTop={setElementiBarraTop} />
              }
            /> */}

            {/* FAQ ------------------------------ */}
            {/* categorie faq */}
            <Route
              path="faqcategorie/"
              element={
                <CategoryLista setElementiBarraTop={setElementiBarraTop} />
              }
            />
            <Route
              path="faqcategorie/:id"
              element={
                <CategoryEdit setElementiBarraTop={setElementiBarraTop} />
              }
            />
            <Route
              path="faqcategorie/nuovo"
              element={
                <CategoryEdit setElementiBarraTop={setElementiBarraTop} />
              }
            />
            {/* utenti faq */}
            <Route
              path="faqutenti/"
              element={<UserLista setElementiBarraTop={setElementiBarraTop} />}
            />
            <Route
              path="faqutenti/:id"
              element={<UserEdit setElementiBarraTop={setElementiBarraTop} />}
            />
            <Route
              path="faqutenti/nuovo"
              element={<UserEdit setElementiBarraTop={setElementiBarraTop} />}
            />

            {/* domande faq */}
            <Route
              path="faqdomande/"
              element={
                <DomandaLista setElementiBarraTop={setElementiBarraTop} />
              }
            />
            <Route
              path="faqdomande/:id"
              element={
                <DomandaEdit setElementiBarraTop={setElementiBarraTop} />
              }
            />
            <Route
              path="faqdomande/nuovo"
              element={
                <DomandaEdit setElementiBarraTop={setElementiBarraTop} />
              }
            />

            {/* voli */}

            <Route
              path="flight/"
              element={<VoliLista setElementiBarraTop={setElementiBarraTop} />}
            />

            <Route
              path="flight/nuovo"
              element={<VoliEdit setElementiBarraTop={setElementiBarraTop} />}
            />
            <Route
              path="flight/:id"
              element={<VoliEdit setElementiBarraTop={setElementiBarraTop} />}
            />
          </Route>
        </Routes>
        {/* </Container> */}
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
