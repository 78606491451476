import TextField from "@mui/material/TextField";
// import { monFetch } from "../inc/inc";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
// import { useLocation, useParams } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";

const Comp = (props) => {
  let settaDati = props.settaDati;
  let dati = props.dati;
  let arr = props.arr;
  let index = props.index;

  const arrotonda = (valore) => {
    return Math.round(valore * 100) / 100;
  };

  return (
    <>
      <Grid item xs={12} className="rigaFlexIngressi">
        <TextField
          size="small"
          label="Descrizione"
          type="text"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom"
          value={dati.localita || ""}
          onChange={(e) => {
            settaDati(arr, index, { localita: e.target.value });
          }}
          style={{ minWidth: "40%" }}
        />
        <TextField
          size="small"
          label="Max Pax"
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom"
          value={dati.maxPax || 0}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            settaDati(arr, index, { maxPax: e.target.value });
          }}
        />

        <TextField
          size="small"
          label="A Pax"
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          value={dati.euroPax || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            settaDati(arr, index, { euroPax: e.target.value });
          }}
        />

        <TextField
          size="small"
          label="A Gruppo"
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          value={dati.euroGruppo || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            settaDati(arr, index, { euroGruppo: e.target.value });
          }}
        />
        <TextField
          size="small"
          label="Quota a pagante"
          disabled
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          onWheel={(e) => e.target.blur()}
          value={arrotonda(dati.totale / props.totalePaganti) || 0}
        />
        <TextField
          size="small"
          label="Totale"
          disabled
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          onWheel={(e) => e.target.blur()}
          value={dati.totale || 0}
        />
        <DeleteIcon
          className="pointer"
          color="error"
          onClick={(e) => {
            props.eliminaEscursione(index);
          }}
        />
      </Grid>
    </>
  );
};

export default Comp;
