import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Pallino, convertiData, controllaLogin, monFetch } from "../inc/inc";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useLocation, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";

import { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";

const Comp = () => {

  let location = useLocation();

  const [nome, setNome] = useState("");

  const [errore, setErrore] = useState("");

  async function salvaAzienda(e) {
    e.preventDefault();
    const res = await monFetch("/companySet", {
      name: nome
    });
    if (res.success) {
      window.location.href = "/azienda/" + res.company;
    } else {
      setErrore(res.msg);
    }
  }


  return (
    <form onSubmit={(e) => salvaAzienda(e)}>
      <h2>Nuova Azienda</h2>
      {errore && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errore}
        </Alert>
      )}

      <TextField
        required
        label="Nome"
        fullWidth
        variant="outlined"
        className="inputCustom"
        value={nome}
        onChange={(e) => {
          setNome(e.target.value);
        }}
      />
      <br /> <br />
      <Button className="inputCustom" type="submit" variant="contained">
        Salva
      </Button>
    </form>
  );
};

export default Comp;
