import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import EditorFull from "../componenti_generici/EditorFull";
import Autocomplete from "@mui/material/Autocomplete";
import { monFetch } from "../../inc/inc";

import { useLocation, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

import "react-image-crop/dist/ReactCrop.css";

const Comp = (props) => {
  let params = useParams();
  let isModifica = false;

  const [optionsTrasporti, setOptionsTrasporti] = useState([]);

  let id = params.id;
  const [destinazione, setDestinazione] = useState("");
  const [giorni, setGiorni] = useState(1);
  const [programma, setProgramma] = useState("");
  const [mezzoTrasporto, setMezzoTrasporto] = useState();

  const [errore, setErrore] = useState("");

  async function getProgramma() {
    const res = await monFetch("/destinationPlanGet", {
      id,
    });

    setDestinazione(res.plan.destination);
    setMezzoTrasporto(res.plan.transport_id);
    setGiorni(res.plan.days);
    setProgramma(res.plan.plan);
  }

  async function salvaProgramma(e) {
    e.preventDefault();
    const res = await monFetch("/destinationPlanSet", {
      id,
      destination: destinazione,
      plan: programma,
      days: giorni,
      transport_id: mezzoTrasporto,
    });

    if (res.success) {
      window.location.href = "/dest_programmi";
    } else {
      setErrore("Errore nel salvataggio dei dati");
    }
  }
  async function queryMezziTrasporto() {
    const res = await monFetch("/transportList", {});
    const trasporti = [];
    res.transport.forEach((el) => {
      trasporti.push({ label: el.name, value: el.id });
    });
    setOptionsTrasporti(trasporti);
  }

  useEffect(() => {
    if (id) {
      getProgramma();
    }
    queryMezziTrasporto();
  }, []);

  return (
    <form onSubmit={(e) => salvaProgramma(e)}>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <h2>Nuovo programma destinazione</h2>
          {errore && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {errore}
            </Alert>
          )}
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextField
            required
            size="small"
            label="Destinazione"
            fullWidth
            variant="outlined"
            className="inputCustom"
            value={destinazione}
            // disabled={id ? true : false}
            onChange={(e) => {
              setDestinazione(e.target.value.toUpperCase());
            }}
          />
        </Grid>
        <Grid item sm={2} xs={12}>
          <TextField
            required
            size="small"
            label="Giorni"
            fullWidth
            variant="outlined"
            className="inputCustom"
            value={giorni}
            // disabled={id ? true : false}
            onChange={(e) => {
              setGiorni(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={2} xs={12}>
          <Autocomplete
            size="small"
            value={
              optionsTrasporti.find((op) => op.value == mezzoTrasporto)
                ? optionsTrasporti.find((op) => op.value == mezzoTrasporto)
                : null
            }
            onChange={(e, value) => {
              setMezzoTrasporto(value?.value);
            }}
            options={optionsTrasporti}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Mezzo"
                className="inputCustom"
                required={true}
              />
            )}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <div style={{ paddingBottom: 10 }}>Programma di viaggio</div>

          <EditorFull value={programma} setValue={setProgramma} />
        </Grid>

        <Grid item sm={12} xs={12}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              className="inputCustom"
              variant="contained"
              href="/dest_programmi"
            >
              Indietro
            </Button>
            <Button
              className="inputCustom bottoneSalva"
              type="submit"
              variant="contained"
            >
              Salva
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

export default Comp;
