// import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { monFetch } from "../../inc/inc";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";

const PopupDuplicaMedia = (props) => {
  // console.log(props)
  const [lista, setLista] = useState([]);
  const [selezionato, setSelezionato] = useState(null);
  const [messaggio, setMessaggio] = useState("");

  async function query(id) {
    let res;
    ///query lista codici
    res = await monFetch("/quoteListCodes", { id: id });
    let temp = [];
    res.res.forEach((el) => {
      temp.push({
        label:
          el.code +
          " - " +
          el.year +
          " - " +
          el.destination +
          " - Inserimento: " +
          el.date_insert,
        value: el.id,
      });
    });
    setLista(temp);
  }

  async function duplicaMedia(id) {
    let res;
    let dati = {
      idPrevTo: selezionato,
      idMedia: props.daDuplicare,
    };
    console.log(dati);

    res = await monFetch("/mediaClone", dati);
    console.log(res)
    if (res.success) {
      setMessaggio("Media duplicato nel preventivo selezionato");
      setSelezionato(null)
      setTimeout(()=>{
        props.fnSuccess();
      }, 4000)
    }
  }

  useEffect(
    (e) => {
      if (props.open) {
        setSelezionato(null);
        setMessaggio("");
        query();
      }
    },
    [props.open]
  );

  return (
    <Dialog open={props.open} onClose={props.fnAnnulla}>
      <DialogTitle>
        Seleziona il preventivo nel quale duplicare il file
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          value={
            lista.find((el) => {
              return el.value == selezionato;
            })
              ? lista.find((el) => {
                  return el.value == selezionato;
                })
              : null
          }
          onChange={(e, value) => {
            setSelezionato(value?.value);
          }}
          options={lista}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              className="inputCustom"
            />
          )}
        />

        {messaggio && (
          <div style={{ marginTop: 15 }}>
            <b>{messaggio}</b>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Annulla</Button>
        <Button
          disabled={!selezionato}
          onClick={() => {
            duplicaMedia();
            // props.fnSuccess();
          }}
          variant="contained"
        >
          Salva
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupDuplicaMedia;
