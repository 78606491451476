import TextField from "@mui/material/TextField";
// import { monFetch } from "../inc/inc";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import Input from "@mui/material/Input";
// import { useLocation, useParams } from "react-router-dom";

const Comp = (props) => {
  let settaDati = props.settaDati;
  let dati = props.dati;
  let arr = props.arr;
  let index = props.index;

  const arrotonda = (valore) => {
    return Math.round(valore * 100) / 100;
  };

  // const [timeoutId, setTimeoutId] = useState(null);

  //calcolo totale
  let totaleN =
    Number(dati.costoPaxR || 0) +
    Number(dati.costoPaxA || 0) +
    Number(dati.bagManoTotale || 0) +
    Number(dati.bagStivaTotale || 0);

  // console.log(totaleN);
  //   dati.notti *
  //   ((props.totalePass - Math.floor(props.totalePass / dati.gratutita)) *
  //     dati.costoNotte +
  //     Number(props.totaleInSingola * dati.suppSingola) +
  //     Number(props.totaleInDoppia * dati.suppDoppia));

  let totaleAPagante =
    totaleN *
    ((Number(props.totaleNonPaganti) + Number(props.totalePaganti)) /
      Number(props.totalePaganti));

  totaleAPagante = arrotonda(totaleAPagante);

  let totaleBagMano =
    Number(dati.bagManoPartenza || 0) + Number(dati.bagManoArrivo || 0);
  let totaleBagStiva =
    Number(dati.bagStivaPartenza || 0) + Number(dati.bagStivaArrivo || 0);

  useEffect(() => {
    // if (
    //   !Number(totaleBagStiva) &&
    //   !Number(totaleBagMano) &&
    //   !Number(totaleAPagante)
    // ) {
    //   console.log("NO TRIGGER");
    //   return;
    // }
    console.log("SI TRIGGER");
    let timeout = setTimeout((el) => {
      // settaDati(arr, "totaleAPagante", index, totaleAPagante);
      // settaDati(arr, "bagManoTotale", index, totaleBagMano);
      // settaDati(arr, "bagStivaTotale", index, totaleBagStiva);

      settaDati(
        arr,
        {
          bagStivaTotale: totaleBagStiva,
          bagManoTotale: totaleBagMano,
          totaleAPagante: totaleAPagante,
        },
        index
      );
    }, 50);

    return () => clearTimeout(timeout);
  }, [totaleAPagante, totaleBagMano, totaleBagStiva, props.idProp ]);

  return (
    <>
      <Grid item sm={6} xs={12} className="alignCenter">
        <label className="labelGruppo noBorder">Proposta {index + 1}</label>
      </Grid>
      <Grid item sm={3} xs={12}>
        <TextField
          size="small"
          label="Quota a pagante"
          disabled
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom totalePaganti euro"
          value={dati.totaleAPagante || ""}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          size="small"
          label="Quota totale"
          disabled
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom totaleComplessivo euro"
          value={arrotonda(((dati.totaleAPagante || 0) * props.totalePaganti))}
        />
      </Grid>
      <Grid item sm={12} xs={12} className="hideOnPrint">
        <div className="labelGruppoCont"></div>
      </Grid>
      <Grid item xs={3}>
        <div className="andataRitornoTesto">Andata</div>
      </Grid>
      <Grid item xs={3}>
        <TextField
          size="small"
          label="Vettore"
          type="text"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom"
          value={dati.vettoreA || ""}
          onChange={(e) => {
            settaDati(arr, { vettoreA: e.target.value }, index);
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          size="small"
          label="Loc. Partenza"
          type="text"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom"
          value={dati.locPartenza || ""}
          onChange={(e) => {
            settaDati(arr, { locPartenza: e.target.value }, index);
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          size="small"
          label="Loc. Arrivo"
          type="text"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom"
          value={dati.locPartenzaEnd || ""}
          onChange={(e) => {
            settaDati(arr, { locPartenzaEnd: e.target.value }, index);
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          size="small"
          label="Orario Partenza"
          type="time"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom"
          value={dati.partenzaA || ""}
          onChange={(e) => {
            settaDati(arr, { partenzaA: e.target.value }, index);
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          size="small"
          label="Orario Arrivo"
          type="time"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom"
          value={dati.arrivoA || ""}
          onChange={(e) => {
            settaDati(arr, { arrivoA: e.target.value }, index);
          }}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          size="small"
          label="€ / pax"
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          value={dati.costoPaxA || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            settaDati(arr, { costoPaxA: e.target.value }, index);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
      <Grid item xs={3}>
        <div className="andataRitornoTesto">Ritorno</div>
      </Grid>
      <Grid item xs={3}>
        <TextField
          size="small"
          label="Vettore"
          type="text"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom"
          value={dati.vettoreR || ""}
          onChange={(e) => {
            settaDati(arr, { vettoreR: e.target.value }, index);
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          size="small"
          label="Loc. Partenza"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom"
          value={dati.locRitorno || ""}
          onChange={(e) => {
            settaDati(arr, { locRitorno: e.target.value }, index);
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          size="small"
          label="Loc. Arrivo"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom"
          value={dati.locRitornoEnd || ""}
          onChange={(e) => {
            settaDati(arr, { locRitornoEnd: e.target.value }, index);
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          size="small"
          label="Orario Partenza"
          type="time"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom"
          value={dati.partenzaR || ""}
          onChange={(e) => {
            settaDati(arr, { partenzaR: e.target.value }, index);
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          size="small"
          label="Orario Arrivo"
          type="time"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom"
          value={dati.arrivoR || ""}
          onChange={(e) => {
            settaDati(arr, { arrivoR: e.target.value }, index);
          }}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          size="small"
          label="€ / pax"
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          value={dati.costoPaxR || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            settaDati(arr, { costoPaxR: e.target.value }, index);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
      <Grid item xs={12}>
        Bag. a Mano
      </Grid>

      <Grid item xs={4}>
        <TextField
          size="small"
          label="Andata"
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          value={dati.bagManoPartenza || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            settaDati(arr, { bagManoPartenza: e.target.value }, index);
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          size="small"
          label="Ritorno"
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          value={dati.bagManoArrivo || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            settaDati(arr, { bagManoArrivo: e.target.value }, index);
          }}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          size="small"
          label="€ / pax"
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          disabled
          variant="outlined"
          className="inputCustom euro"
          value={dati.bagManoTotale || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            settaDati(arr, { bagManoTotale: e.target.value }, index);
          }}
        />
      </Grid>

      <Grid item xs={12}>
        Bag. Stiva 20Kg
      </Grid>

      <Grid item xs={4}>
        <TextField
          size="small"
          label="Andata"
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          value={dati.bagStivaPartenza || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            settaDati(arr, { bagStivaPartenza: e.target.value }, index);
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          size="small"
          label="Ritorno"
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          value={dati.bagStivaArrivo || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            settaDati(arr, { bagStivaArrivo: e.target.value }, index);
          }}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          size="small"
          label="€ / pax"
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          disabled
          variant="outlined"
          className="inputCustom euro"
          value={dati.bagStivaTotale || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            settaDati(arr, { bagStivaTotale: e.target.value }, index);
          }}
        />
      </Grid>
    </>
  );
};

export default Comp;
