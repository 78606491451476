import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { useEffect, useState } from "react";
// import { Font } from '@ckeditor/ckeditor5-font';
// import { Alignment } from '@ckeditor/ckeditor5-alignment';

const Comp = (props) => {

  const [caricato, setCaricato] = useState(false);

  useEffect(()=>{
    setCaricato(true)
  }, [])

  if(!caricato){
    return null;
  }
  return (
    <CKEditor
      config={{
        // plugins: [Alignment],
        placeholder: "Inserisci",
        toolbar: [
          "undo",
          "redo",
          "|",
          "heading",
          "|",
          "alignment",
          "|",
          "bold",
          "italic",
          "blockQuote",
          "link",
          "numberedList",
          "bulletedList",
          "|",
          "outdent",
          "indent",
          "|",
          "fontColor",
          "fontBackgroundColor",
          "insertTable",
          "tableColumn",
          "tableRow",
          "mergeTableCells",
         
        ],
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
            {
              model: "heading3",
              view: "h3",
              title: "Heading 3",
              class: "ck-heading_heading3",
            },
            {
              model: "heading4",
              view: "h4",
              title: "Heading 4",
              class: "ck-heading_heading4",
            },
            {
              model: "heading5",
              view: "h5",
              title: "Heading 5",
              class: "ck-heading_heading5",
            },
          ],
        },
        htmlSupport: {
          allow:{
            name:"td",
            styles: 'background-color'
          }
        }
      }}
      editor={Editor}
      data={props.value || ""}
      onChange={(event, editor) => {
        const data = editor.getData();
        props.setValue(data);
      }}
    />
  );
};

// import { Component } from "react";
// import { CKEditor } from "@ckeditor/ckeditor5-react";

// // NOTE: Use the editor from source (not a build)!
// import { ClassicEditor } from "@ckeditor/ckeditor5-editor-classic";
// import { Essentials } from "@ckeditor/ckeditor5-essentials";
// import { Bold, Italic } from "@ckeditor/ckeditor5-basic-styles";
// import { Paragraph } from "@ckeditor/ckeditor5-paragraph";
// // const { styles } = require("@ckeditor/ckeditor5-dev-utils");

// const editorConfiguration = {
//   language: "it",
//   plugins: [Essentials, Bold, Italic, Paragraph],
//   toolbar: ["bold", "italic"],
// };
// function Comp(props) {
//   return (
//     <CKEditor
/**config={{
        placeholder: "Programma",

        toolbar: [
          "undo",
          "redo",
          "|",
          "heading",
          "|",
          "alignment",
          "|",
          "bold",
          "italic",
          "blockQuote",
          "link",
          "numberedList",
          "bulletedList",
          "|",
          "outdent",
          "indent",
          "|",
          "insertTable",
          "tableColumn",
          "tableRow",
          "mergeTableCells",
        ],
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
            {
              model: "heading3",
              view: "h3",
              title: "Heading 3",
              class: "ck-heading_heading3",
            },
            {
              model: "heading4",
              view: "h4",
              title: "Heading 4",
              class: "ck-heading_heading4",
            },
            {
              model: "heading5",
              view: "h5",
              title: "Heading 5",
              class: "ck-heading_heading5",
            },
          ],
        },
      }}**/
// editor={ClassicEditor}
// config={editorConfiguration}
// data={props.value}
// onChange={(event, editor) => {
//   const data = editor.getData();
//   props.setValue(data);
// }}
//   />
// );
// }

export default Comp;
