import TextField from "@mui/material/TextField";
import { monFetch, calcDataSommaGiorni } from "../../inc/inc";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
// import Checkbox from "@mui/material/Checkbox";
import Rating from "@mui/material/Rating";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

async function queryHotelNome(value, localita, setOptionsHotel) {
  const res = await monFetch("/hotelNameList", {
    name: value,
    location: localita,
  });
  if (res.success) {
    let temp = [];
    res.res.forEach((el) => {
      temp.push({ label: el.name });
    });
    setOptionsHotel(temp);
  }
}

async function queryHotelLocalita(value, setOptionsLocalita) {
  const res = await monFetch("/hotelLocationList", {
    value,
  });
  if (res.success) {
    let temp = [];
    res.res.forEach((el) => {
      temp.push({ label: el.location });
    });
    setOptionsLocalita(temp);
  }
}

async function queryHotelDesc(nome, localita, settaDati, arr, index, index2) {
  const res = await monFetch("/hotelGetDescription", {
    name: nome,
    location: localita,
  });
  if (res.success) {
    settaDati(arr, "descrizione", index, index2, res.res);
  }
}

const Comp = (props) => {
  const [optionsHotel, setOptionsHotel] = useState([]);
  const [optionsLocalita, setOptionsLocalita] = useState([]);
  const [mostraDesc, setMostraDesc] = useState(false);

  let settaDati = props.settaDati;
  let dati = props.dati;
  let arr = props.arr;
  let index = props.index;
  let index2 = props.index2;
  let optHotel = props.optHotel;
  let pagInSing = Number(props.pagInSing);
  let pagInDopp = Number(props.pagInDopp);
  let gratInSing = Number(props.gratInSing);
  let gratInDopp = Number(props.gratInDopp);
  let totaleInSingola = pagInSing + gratInSing;
  let totaleInDoppia = pagInDopp + gratInDopp;

  let isAutista = props.isAutista;
  //calcolo totale
  let totaleN = 0;

  let nNotti = dati.notti;

  if (arr && arr.length == 1 && arr[0].length == 1 && nNotti == 0) {
    nNotti = props.notti;
    dati.notti = props.notti;
  }
  // console.log("notti", nNotti, props.notti, arr)
  // console.log(dati)
  const arrotonda = (valore) => {
    return Math.round(valore * 100) / 100;
  };

  let personeCalcolo =
    (props.totalePass -
      Math.floor(
        props.totalePass /
          ((Number(dati.gratuitaBB) != 0 ? Number(dati.gratuitaBB) : 999) + 1)
      )) *
      dati.costoNotteBB +
    (props.totalePass -
      Math.floor(
        props.totalePass /
          ((Number(dati.gratuitaHB) != 0 ? Number(dati.gratuitaHB) : 999) + 1)
      )) *
      dati.costoNotteHB +
    (props.totalePass -
      Math.floor(
        props.totalePass /
          ((Number(dati.gratuitaPasto) != 0
            ? Number(dati.gratuitaPasto)
            : 999) +
            1)
      )) *
      dati.costoNottePasto;

  if (!dati.forfait) {
    //se non forfait (a persona)
    totaleN =
      dati.notti *
      (personeCalcolo +
        Number(totaleInSingola * dati.suppSingola) +
        Number(totaleInDoppia * dati.suppDoppia) +
        Number(dati.supp1Notte * props.totalePass || 0));
  } else {
    //se forfait
    totaleN =
      personeCalcolo +
      Number(dati.suppSingola * totaleInSingola || 0) +
      Number(dati.suppDoppia * totaleInDoppia || 0) +
      Number(dati.supp1Notte || 0);
  }

  // console.log("totale", personeCalcolo,totaleN)

  let totaleAPagante = totaleN / props.totalePaganti;
  totaleAPagante = arrotonda(totaleAPagante);

  if (totaleN == 0) {
    totaleAPagante = 0;
  }

  totaleN = arrotonda(totaleN);
  //incidenza non paganti
  let incid = 0;
  let passGratuiti = 0;
  if (dati.gratuitaBB != 0) {
    passGratuiti = Math.floor(
      props.totalePass /
        ((Number(dati.gratuitaBB) != 0 ? Number(dati.gratuitaBB) : 999) + 1)
    );
  }
  if (props.totalePaganti != 0) {
    //commentato, da rivedere
    // incid =
    //   ((Number(props.totalePass) -
    //     Number(props.totalePaganti) -
    //     Number(passGratuiti)) *
    //     Number(dati.costoNotte) +
    //     Number(dati.suppSingola) * Number(gratInSing) +
    //     Number(dati.suppDoppia) * Number(gratInDopp)) /
    //   Number(props.totalePaganti);
  }

  // console.log("INCID2", incid)
  incid = arrotonda(incid);
  useEffect(() => {
    settaDati(arr, "incidNonPaganti", index, index2, incid);
  }, [incid]);

  useEffect(() => {
    let timeout = setTimeout((el) => {
      settaDati(arr, "totaleAPagante", index, index2, Number(totaleAPagante));
    }, 20);

    return () => {
      clearTimeout(timeout);
    };
  }, [totaleAPagante, dati, , props.idProp]);

  useEffect(() => {
    let dataFine = calcDataSommaGiorni(dati.inizio, nNotti);
    settaDati(arr, "fine", index, index2, dataFine || null);
  }, [nNotti, dati.inizio]);

  return (
    <>
      <Grid item xs={3} className="alignCenter">
        <label className="labelGruppo noBorder">Proposta {index2 + 1}</label>
      </Grid>
      {!isAutista && (
        <Grid item xs={3} style={{ display: "flex", alignItems: "center" }}>
          <Rating
            // name="simple-controlled"
            value={dati.stelle || 0}
            onChange={(event, value) => {
              settaDati(arr, "stelle", index, index2, value);
            }}
          />
        </Grid>
      )}
      {!isAutista && (
        <Grid item xs={3} className="alignCenter">
          <Autocomplete
            freeSolo
            value={
              dati.forn || ""
              // optHotel.supplier &&
              // optHotel.supplier.find((el) => {
              //   return el.value == dati.forn;
              // })
              //   ? optHotel.supplier.find((el) => {
              //       return el.value == dati.forn;
              //     })
              //   : null
            }
            onInputChange={(e, value) => {
              settaDati(arr, "forn", index, index2, value);
              // console.log(value)
            }}
            options={optHotel.supplier || []}
            disableClearable
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                label="Fornitore"
                className="inputCustom"
              />
            )}
          />{" "}
        </Grid>
      )}
      {!isAutista && (
        <Grid item xs={3}>
          <TextField
            size="small"
            label="Quota a pagante"
            disabled
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            className="inputCustom totalePaganti euro"
            value={arrotonda(dati.totaleAPagante || 0)}
          />
        </Grid>
      )}
      <Grid item sm={12} xs={12} className="hideOnPrint">
        <div className="labelGruppoCont"></div>
      </Grid>
      <Grid item xs={4}>
        <Autocomplete
          freeSolo
          filterOptions={(x) => x}
          inputValue={dati.localita || ""}
          onInputChange={(e, value) => {
            settaDati(arr, "localita", index, index2, value);
            if (value) {
              queryHotelLocalita(value, setOptionsLocalita);
            } else {
              setOptionsLocalita([]);
            }
            queryHotelDesc(dati.hotel, value, settaDati, arr, index, index2);
          }}
          options={optionsLocalita}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              label="Località"
              className="inputCustom"
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Autocomplete
          freeSolo
          filterOptions={(x) => x}
          inputValue={dati.hotel || ""}
          onInputChange={(e, value) => {
            settaDati(arr, "hotel", index, index2, value);
            if (value) {
              queryHotelNome(value, dati.localita, setOptionsHotel);
            } else {
              setOptionsHotel([]);
            }
            queryHotelDesc(value, dati.localita, settaDati, arr, index, index2);
          }}
          options={optionsHotel}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              label="Nome"
              className="inputCustom"
            />
          )}
        />
      </Grid>

      <Grid item xs={4}>
        {/* tipo hotel ----------------- */}
        <Autocomplete
          value={
            optHotel.tipi &&
            optHotel.tipi.find((el) => {
              return el.value == dati.tipo;
            })
              ? optHotel.tipi.find((el) => {
                  return el.value == dati.tipo;
                })
              : null
          }
          onChange={(e, value) => {
            settaDati(arr, "tipo", index, index2, value?.value);
            settaDati(arr, "tipoText", index, index2, value?.label);
          }}
          options={optHotel.tipi || []}
          disableClearable
          disabled={isAutista}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              label="Tipo"
              className="inputCustom"
            />
          )}
        />
      </Grid>

      {!isAutista && (
        <Grid item xs={3}>
          {/* trattamento hotel ----------------- */}
          <Autocomplete
            value={
              optHotel.trattamento &&
              optHotel.trattamento.find((el) => {
                return el.value == dati.trattamento;
              })
                ? optHotel.trattamento.find((el) => {
                    return el.value == dati.trattamento;
                  })
                : null
            }
            onChange={(e, value) => {
              settaDati(arr, "trattamento", index, index2, value?.value);
              settaDati(arr, "trattamentoText", index, index2, value?.label);
            }}
            options={optHotel.trattamento || []}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                label="Trattamento"
                className="inputCustom"
              />
            )}
          />
        </Grid>
      )}
      {!isAutista && (
        <Grid item xs={5}>
          {/* posizione hotel ----------------- */}
          <Autocomplete
            value={
              optHotel.posizione &&
              optHotel.posizione.find((el) => {
                return el.value == dati.posizione;
              })
                ? optHotel.posizione.find((el) => {
                    return el.value == dati.posizione;
                  })
                : null
            }
            onChange={(e, value) => {
              settaDati(arr, "posizione", index, index2, value?.value);
              settaDati(arr, "posizioneText", index, index2, value?.label);
            }}
            options={optHotel.posizione || []}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                label="Posizione"
                className="inputCustom"
              />
            )}
          />
        </Grid>
      )}

      {!isAutista && (
        <>
          <Grid item xs={4}>
            <TextField
              size="small"
              label="N. Notti"
              type="number"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              className="inputCustom"
              value={nNotti || ""}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => {
                let valore = e.target.value;
                if (valore > props.notti) {
                  valore = props.notti;
                }
                settaDati(arr, "notti", index, index2, valore || 0);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              type="date"
              label="Data Inizio"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              className="inputCustom"
              value={dati.inizio || ""}
              onChange={(e) => {
                settaDati(arr, "inizio", index, index2, e.target.value || null);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              type="date"
              label="Data Fine"
              disabled
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              className="inputCustom"
              value={dati.fine || ""}
              // onChange={(e) => {
              //   settaDati(arr, "fine", index, index2, e.target.value || null);
              // }}
            />
          </Grid>
        </>
      )}
      {/* <div style={{ display: "flex", gap: 10 }}> */}
      {/* {!isAutista && (
            <TextField
              size="small"
              label={dati.forfait ? "Costo/notte tot." : "Costo/notte pax."}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              className="inputCustom euro"
              value={dati.costoNotte || ""}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => {
                settaDati(
                  arr,
                  "costoNotte",
                  index,
                  index2,
                  e.target.value || 0
                );
              }}
            />
          )} */}
      {!isAutista && (
        <Grid item xs={4}>
          <TextField
            size="small"
            label="Costo Notte BB"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            className="inputCustom euro"
            value={dati.costoNotteBB || ""}
            onWheel={(e) => e.target.blur()}
            onChange={(e) => {
              settaDati(
                arr,
                "costoNotteBB",
                index,
                index2,
                e.target.value || 0
              );
            }}
          />
        </Grid>
      )}
      {!isAutista && (
        <Grid item xs={2}>
          {/* gratuita hotel BB----------------- */}
          <Autocomplete
            value={String(dati.gratuitaBB)}
            freeSolo
            onChange={(e, value) => {
              // settaDati(arr, "gratuitaId", index, index2, value);
              settaDati(arr, "gratuitaBB", index, index2, value);
            }}
            onInputChange={(e, value) => {
              settaDati(arr, "gratuitaBB", index, index2, value);
            }}
            options={optHotel.gratuita || []}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ minWidth: 80 }}
                size="small"
                label="Gratuità BB"
                className="inputCustom"
              />
            )}
          />
        </Grid>
      )}
      {!isAutista && (
        <Grid item xs={4}>
          <TextField
            size="small"
            label="Costo Notte HB"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            className="inputCustom euro"
            value={dati.costoNotteHB || ""}
            onWheel={(e) => e.target.blur()}
            onChange={(e) => {
              settaDati(
                arr,
                "costoNotteHB",
                index,
                index2,
                e.target.value || 0
              );
            }}
          />
        </Grid>
      )}
      {!isAutista && (
        <Grid item xs={2}>
          {/* gratuita hotel HB----------------- */}
          <Autocomplete
            value={String(dati.gratuitaHB)}
            freeSolo
            onChange={(e, value) => {
              // settaDati(arr, "gratuitaId", index, index2, value);
              settaDati(arr, "gratuitaHB", index, index2, value);
            }}
            onInputChange={(e, value) => {
              settaDati(arr, "gratuitaHB", index, index2, value);
            }}
            options={optHotel.gratuita || []}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ minWidth: 80 }}
                size="small"
                label="Gratuità"
                className="inputCustom"
              />
            )}
          />
        </Grid>
      )}
      {/* </div> */}
      {!isAutista && (
        <Grid item xs={4}>
          <TextField
            size="small"
            label="Costo Notte Pasto"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            className="inputCustom euro"
            value={dati.costoNottePasto || ""}
            onWheel={(e) => e.target.blur()}
            onChange={(e) => {
              settaDati(
                arr,
                "costoNottePasto",
                index,
                index2,
                e.target.value || 0
              );
            }}
          />
        </Grid>
      )}

      {!isAutista && (
        <Grid item xs={2}>
          {/* gratuita hotel pasto----------------- */}
          <Autocomplete
            value={String(dati.gratuitaPasto)}
            freeSolo
            onChange={(e, value) => {
              // settaDati(arr, "gratuitaId", index, index2, value);
              settaDati(arr, "gratuitaPasto", index, index2, value);
            }}
            onInputChange={(e, value) => {
              settaDati(arr, "gratuitaPasto", index, index2, value);
            }}
            options={optHotel.gratuita || []}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                label="Gratuità"
                className="inputCustom"
              />
            )}
          />
        </Grid>
      )}
      <Grid item xs={6}></Grid>
      {!isAutista && (
        <Grid item xs={4}>
          <TextField
            size="small"
            label="Supp. C. Singola"
            type="number"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            className="inputCustom euro"
            value={dati.suppSingola || ""}
            onWheel={(e) => e.target.blur()}
            onChange={(e) => {
              settaDati(arr, "suppSingola", index, index2, e.target.value || 0);
            }}
          />
        </Grid>
      )}
      {!isAutista && (
        <Grid item xs={4}>
          <TextField
            size="small"
            label="Supp. C. Doppia"
            type="number"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            className="inputCustom euro"
            value={dati.suppDoppia || ""}
            onWheel={(e) => e.target.blur()}
            onChange={(e) => {
              settaDati(arr, "suppDoppia", index, index2, e.target.value || 0);
            }}
          />
        </Grid>
      )}
      {!isAutista && (
        <Grid item xs={4}>
          <TextField
            size="small"
            label="Supp. 1 Notte"
            type="number"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            className="inputCustom euro"
            value={dati.supp1Notte || ""}
            onWheel={(e) => e.target.blur()}
            onChange={(e) => {
              settaDati(arr, "supp1Notte", index, index2, e.target.value || 0);
            }}
          />
        </Grid>
      )}
      {!isAutista && (
        <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
          <span style={{ fontSize: 14 }}>Pacchetto</span>
          <input
            type="checkbox"
            checked={dati.forfait ? true : false}
            onChange={(e) =>
              settaDati(arr, "forfait", index, index2, e.target.checked)
            }
          />
        </Grid>
      )}
      {!isAutista && (
        <Grid item xs={3}>
          <TextField
            size="small"
            label="Incid. non paganti"
            type="number"
            disabled
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            className="inputCustom euro"
            onWheel={(e) => e.target.blur()}
            value={dati.incidNonPaganti || 0}
          />
        </Grid>
      )}
      {!isAutista && (
        <Grid item xs={3}>
          <TextField
            size="small"
            label="Totale"
            disabled
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            className="inputCustom totaleComplessivo"
            value={totaleN}
          />
        </Grid>
      )}
      {!isAutista && (
        <Grid item xs={4} style={{ textAlign: "right" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
              paddingTop: 7,
              cursor: "pointer",
              fontSize: 14,
            }}
            onClick={(e) => {
              setMostraDesc(!mostraDesc);
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
              className={
                !dati.descrizione && !mostraDesc ? "btnDesc add" : "btnDesc"
              }
            >
              {!mostraDesc ? (
                <>
                  {dati.descrizione ? "Mostra" : "Aggiungi"} Descr.{" "}
                  <KeyboardArrowRightIcon />
                </>
              ) : (
                <>
                  Nascondi Descr. <KeyboardArrowDownIcon />
                </>
              )}
            </div>
          </div>
        </Grid>
      )}
      {!isAutista && mostraDesc && (
        <Grid item xs={12}>
          <TextField
            size="small"
            label="Descrizione"
            multiline
            rows={3}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ max: 250 }}
            variant="outlined"
            className="inputCustom"
            value={dati.descrizione || ""}
            onChange={(e) =>
              settaDati(arr, "descrizione", index, index2, e.target.value)
            }
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          size="small"
          label="Note"
          multiline
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom"
          value={dati.note || ""}
          onChange={(e) => {
            settaDati(arr, "note", index, index2, e.target.value);
          }}
        />
      </Grid>
    </>
  );
};

export default Comp;
