import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { monFetch } from "../../inc/inc";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';



import Pagination from "@mui/material/Pagination";

const Comp = (props) => {
  let location = useLocation();

  const [utenti, setUtenti] = useState([]);

  const [popupElimina, setPopupElimina] = useState(false);
  const [daEliminare, setDaEliminare] = useState(null);

  // const [pagina, setPagina] = useState(1);
  // const [risPerPagina, setRisPerPagina] = useState(50);
  // const [totaleRis, setTotaleRis] = useState(50);

  // const [filtroCitta, setFiltroCitta] = useState(null);

  async function query() {
    const res = await monFetch("/faqUserList", {});
    setUtenti(res.res);
  }

  async function elimina(id) {
    const res = await monFetch("/faqUserDelete", { id });
    setPopupElimina(false);
    query();
  }

  useEffect(() => {
    let barraTop = <h2>Utenti Faq</h2>;
    props.setElementiBarraTop(barraTop);
  }, []);

  useEffect(() => {
    query();
  }, []);

  // useEffect(() => {
  //   if(filtroCitta === null){
  //     return;
  //   }
  //   if (pagina != 1) {
  //     setPagina(1);
  //   } else {
  //     query(1);
  //   }
  // }, [filtroCitta]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={3} xs={12}>
          <Button variant="contained" href="/faqutenti/nuovo">
            Nuovo
          </Button>
        </Grid>
        <Grid item sm={6} xs={12}></Grid>
        {/* <Grid item sm={3} xs={12}>
          <TextField
            size="small"
            label="Cerca Destinazione"
            fullWidth
            variant="outlined"
            className="inputCustom"
            value={filtroCitta || ""}
            onChange={(e) => {
              setFiltroCitta(e.target.value.toUpperCase());
            }}
          />
        </Grid> */}
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Nome</TableCell>
            <TableCell>Cognome</TableCell>

            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {utenti.map((p) => {
            return (
              <TableRow
                key={p.id}
                className="rigaSelezionabile"
                onClick={(e) => {
                  e.stopPropagation();
                  window.location.href = "/faqutenti/" + p.id;
                }}
              >
                <TableCell>{p.email}</TableCell>
                <TableCell>{p.first_name}</TableCell>

                <TableCell>{p.last_name}</TableCell>

                <TableCell
                  style={{
                    textAlign: "right",
                  }}
                >
                  <DeleteIcon
                    color="error"
                    onClick={(e) => {
                      e.stopPropagation();
                      setPopupElimina(true);
                      setDaEliminare(p.id);
                    }}
                    style={{ marginRight: "20px" }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <br />
      {/* <Pagination
        count={Math.ceil(totaleRis / risPerPagina)}
        page={pagina}
        onChange={(e, p) => {
          setPagina(p);
        }}
      /> */}
      <Popup
        open={popupElimina}
        fnAnnulla={(e) => setPopupElimina(false)}
        fnSuccess={(e) => elimina(daEliminare)}
      />
    </>
  );
};

const Popup = (props) => {
  return (
    <Dialog open={props.open} onClose={props.fnAnnulla}>
      <DialogTitle>Conferma Eliminazione</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Confermi l'eliminazione dell'elemento?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Annulla</Button>
        <Button onClick={props.fnSuccess} variant="contained">
          Elimina
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Comp;
