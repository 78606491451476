import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { useLocation, Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import ApartmentIcon from "@mui/icons-material/Apartment";
import Menu from "@mui/material/Menu";
import ViewListIcon from "@mui/icons-material/ViewList";
import GroupIcon from "@mui/icons-material/Group";
import Container from "@mui/material/Container";
import {
  controllaLogin,
  monFetch,
  checkAutista,
  checkRuoloUtente,
} from "../../inc/inc";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import HomeIcon from "@mui/icons-material/Home";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import PhotoIcon from "@mui/icons-material/Photo";
import ListAltIcon from "@mui/icons-material/ListAlt";

import HikingIcon from "@mui/icons-material/Hiking";
import TourIcon from "@mui/icons-material/Tour";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import FlightIcon from "@mui/icons-material/Flight";
import LuggageIcon from "@mui/icons-material/Luggage";
import HotelIcon from '@mui/icons-material/Hotel';
import ArticleIcon from '@mui/icons-material/Article';

const Comp = (props) => {
  const [menuAperto, setMenuAperto] = useState(false);
  const [apriMenuUtente, setApriMenuUtente] = useState(false);
  const [emailUtente, setEmailUtente] = useState("");
  const [nomeCognome, setNomeCognome] = useState("");
  const [level, setLevel] = useState(null);
  const [isAutista, setIsAutista] = useState(false);

  let location = useLocation();

  useEffect(() => {
    //   //in file inc. redirect se non in pagina libri
    controllaLogin();

    //check se sono loggato come autista
    if (checkAutista()) {
      setIsAutista(true);
      getUtente(true);
    } else {
      getUtente();
    }
  }, [location]);

  async function getUtente(isAutista = false) {
    let res = await monFetch("/userGet", {
      id: Cookies.get("id"),
      autistaId: Cookies.get("autistaId"),
      isAutista: isAutista,
    });
    if (res.user) {
      setEmailUtente(res.user.email);
      setNomeCognome(res.user.first_name + " " + res.user.last_name);
      setLevel(res.user.level);
    } else if (res.bus) {
      setLevel("Fornitore Autobus");
      setEmailUtente(res.bus.email);
      setNomeCognome(res.bus.name);
    }
  }

  const logout = () => {
    Cookies.remove("roles");
    Cookies.remove("token");
    Cookies.remove("id");
    Cookies.remove("adminLevel");
    Cookies.remove("companyId");
    Cookies.remove("autistaId");
    Cookies.remove("filtriLista");
    window.location.href = "/login";
  };

  return (
    <>
      <AppBar position="static" sx={{ mb: 2 }} className="barraTop">
        <Container style={{ paddingLeft: 0, paddingRight: 0 }} maxWidth={false}>
          <Toolbar
            style={{ justifyContent: "space-between", flexWrap: "wrap" }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                size="large"
                edge="start"
                sx={{ mr: 2 }}
                onClick={() => setMenuAperto(!menuAperto)}
              >
                <MenuIcon style={{ color: "white" }} />
              </IconButton>

              {/* <Link to="/" style={{ marginRight: "15px", display: "flex" }}>
                <img
                  src="/img/logo-bianco.svg"
                  style={{ width: "40px", padding: "10px 0" }}
                />
              </Link> */}
            </div>
            <div
              className="barraContDinamico"
              style={{
                flexGrow: 1,
                padding: "0 20px 0 0",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap:6
              }}
            >
              {props.elementiBarraTop}
            </div>
            <div style={{ display: "flex" }}>
              <Button color="inherit" onClick={logout}>
                Logout
              </Button>
              <IconButton
                className="bottoneUtente"
                onClick={() => setApriMenuUtente(!apriMenuUtente)}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                open={apriMenuUtente}
                anchorEl={document.querySelector(".bottoneUtente")}
                // id="menu-appbar"
                onClick={() => setApriMenuUtente(!apriMenuUtente)}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={() => setApriMenuUtente(!apriMenuUtente)}>
                  <div
                    onClick={(e) => {
                      if (isAutista) {
                        window.location.href =
                          "/fornitori-bus/" + Cookies.get("autistaId");
                      }
                    }}
                  >
                    {level ? (
                      <>
                        {level}
                        <br />
                      </>
                    ) : null}
                    <b>{nomeCognome}</b>
                    <br />
                    {emailUtente}
                  </div>
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
          <audio id="pop-sound" src="/sound/pop.wav" />
        </Container>
      </AppBar>

      <Drawer
        className="menuLaterale"
        anchor="left"
        open={menuAperto}
        onClose={() => setMenuAperto(!menuAperto)}
      >
        <List>
          {!isAutista && (
            <ListItem disablePadding>
              <ListItemButton href="/">
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Homepage" />
              </ListItemButton>
            </ListItem>
          )}

          <ListItem disablePadding>
            <ListItemButton href="/preventivi">
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Preventivi" />
            </ListItemButton>
          </ListItem>

          {!isAutista && (
            <ListItem disablePadding>
              <ListItemButton href="/files">
                <ListItemIcon>
                  <InsertDriveFileIcon />
                </ListItemIcon>
                <ListItemText primary="Files globali" />
              </ListItemButton>
            </ListItem>
          )}

          {Cookies.get("adminLevel") > 0 && (
            <ListItem disablePadding>
              <ListItemButton href="/fornitori-bus">
                <ListItemIcon>
                  <AirportShuttleIcon />
                </ListItemIcon>
                <ListItemText primary="Fornitori Bus" />
              </ListItemButton>
            </ListItem>
          )}

          {Cookies.get("adminLevel") == 1 && (
            <ListItem disablePadding>
              <ListItemButton href="/modificaPrezzi">
                <ListItemIcon>
                  <AttachMoneyIcon />
                </ListItemIcon>
                <ListItemText primary="Modifica Prezzi" />
              </ListItemButton>
            </ListItem>
          )}
          {Cookies.get("adminLevel") == 1 && (
            <ListItem disablePadding>
              <ListItemButton href="/modificaMarkup">
                <ListItemIcon>
                  <AttachMoneyIcon />
                </ListItemIcon>
                <ListItemText primary="Modifica Markup" />
              </ListItemButton>
            </ListItem>
          )}
          {Cookies.get("adminLevel") > 0 && (
            <ListItem disablePadding>
              <ListItemButton href="/descrizioniPdf">
                <ListItemIcon>
                  <ViewListIcon />
                </ListItemIcon>
                <ListItemText primary="Descrizioni PDF" />
              </ListItemButton>
            </ListItem>
          )}

          {(Cookies.get("adminLevel") > 0 ||
            checkRuoloUtente("destination-plan")) && (
              <ListItem disablePadding>
                <ListItemButton href="/fornitori_hotel">
                  <ListItemIcon>
                    <LuggageIcon />
                  </ListItemIcon>
                  <ListItemText primary="Tour Operator" />
                </ListItemButton>
              </ListItem>
            )}

          {(Cookies.get("adminLevel") > 0 ||
            checkRuoloUtente("destination-guide")) && (
              <ListItem disablePadding>
                <ListItemButton href="/dest_guide">
                  <ListItemIcon>
                    <TourIcon />
                  </ListItemIcon>
                  <ListItemText primary="Visite guidate" />
                </ListItemButton>
              </ListItem>
            )}

          {(Cookies.get("adminLevel") > 0 ||
            checkRuoloUtente("destination-extra")) && (
              <ListItem disablePadding>
                <ListItemButton href="/dest_escursioni">
                  <ListItemIcon>
                    <HikingIcon />
                  </ListItemIcon>
                  <ListItemText primary="Escursioni" />
                </ListItemButton>
              </ListItem>
            )}

          {(Cookies.get("adminLevel") > 0 ||
            checkRuoloUtente("destination-entrance")) && (
              <ListItem disablePadding>
                <ListItemButton href="/dest_ingressi">
                  <ListItemIcon>
                    <LocalActivityIcon />
                  </ListItemIcon>
                  <ListItemText primary="Ingressi" />
                </ListItemButton>
              </ListItem>
            )}

          {(Cookies.get("adminLevel") > 0 ||
            checkRuoloUtente("destination-extra")) && (
              <ListItem disablePadding>
                <ListItemButton href="/dest_hotel">
                  <ListItemIcon>
                    <HotelIcon />
                  </ListItemIcon>
                  <ListItemText primary="Hotel" />
                </ListItemButton>
              </ListItem>
            )}

          {(Cookies.get("adminLevel") > 0 ||
            checkRuoloUtente("destination-plan")) && (
              <ListItem disablePadding>
                <ListItemButton href="/dest_programmi">
                  <ListItemIcon>
                    <ListAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="Programmi" />
                </ListItemButton>
              </ListItem>
            )}

          {(Cookies.get("adminLevel") > 0 ||
            checkRuoloUtente("destination-image")) && (
              <ListItem disablePadding>
                <ListItemButton href="/dest_immagini">
                  <ListItemIcon>
                    <PhotoIcon />
                  </ListItemIcon>
                  <ListItemText primary="Immagini" />
                </ListItemButton>
              </ListItem>
            )}

          {(Cookies.get("adminLevel") > 0 || checkRuoloUtente("flight")) && (
            <ListItem disablePadding>
              <ListItemButton href="/flight">
                <ListItemIcon>
                  <FlightIcon />
                </ListItemIcon>
                <ListItemText primary="Voli" />
              </ListItemButton>
            </ListItem>
          )}

          {Cookies.get("adminLevel") > 0 && (
            <ListItem disablePadding>
              <ListItemButton href="/utenti">
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>
                <ListItemText primary="Utenti" />
              </ListItemButton>
            </ListItem>
          )}
          {Cookies.get("adminLevel") > 1 && (
            <ListItem disablePadding>
              <ListItemButton href="/ruoli">
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="Ruoli" />
              </ListItemButton>
            </ListItem>
          )}
          {Cookies.get("adminLevel") > 1 && (
            <ListItem disablePadding>
              <ListItemButton href="/aziende">
                <ListItemIcon>
                  <ApartmentIcon />
                </ListItemIcon>
                <ListItemText primary="Aziende" />
              </ListItemButton>
            </ListItem>
          )}

          {(Cookies.get("adminLevel") > 0 ||
            checkRuoloUtente("faq-question")) && (
              <ListItem disablePadding>
                <ListItemButton href="/faqdomande">
                  <ListItemIcon>
                    <ListAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="FAQ Domande" />
                </ListItemButton>
              </ListItem>
            )}
          {(Cookies.get("adminLevel") > 0 ||
            checkRuoloUtente("faq-category")) && (
              <ListItem disablePadding>
                <ListItemButton href="/faqcategorie">
                  <ListItemIcon>
                    <ListAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="FAQ Categorie" />
                </ListItemButton>
              </ListItem>
            )}
          {(Cookies.get("adminLevel") > 0 || checkRuoloUtente("faq-user")) && (
            <ListItem disablePadding>
              <ListItemButton href="/faqutenti">
                <ListItemIcon>
                  <ListAltIcon />
                </ListItemIcon>
                <ListItemText primary="FAQ Utenti" />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Drawer>
      <Container maxWidth={false}>
        {/* //mostra la route figlia */}
        <Outlet />
      </Container>
      <br />
      <br />
      <br />
    </>
  );
};
export default Comp;
