// import Tooltip from "@mui/material/Tooltip";
// import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
// import TextField from "@mui/material/TextField";
import {
  // monFetch,
  // monForm,
  // monMedia,
  checkRuoloUtente,
  checkAutista,
} from "../../inc/inc";

import { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";

import LinkIcon from "@mui/icons-material/Link";

const MediaBottoniForm = (p) => {
  let tree = false;

  if (p.tree) {
    tree = true;
  }

  //// blocco cartella singolo + upload
  // const [nomeFile, setNomeFile] = useState("");

  // async function caricaFile(e) {
  //   e.preventDefault();
  //   var formData = new FormData();
  //   let file = document.querySelector("#fileUpload" + p.tipo).files[0];

  //   const d = new Date();
  //   let nomeFile = d.getTime() + idPrev;

  //   formData.append("file", file);
  //   formData.append("quote_id", idPrev);
  //   formData.append("file_name", nomeFile);
  //   formData.append("media_type", p.tipo);

  //   const res = await monForm("/mediaAdd", formData);
  //   if (res.media) {
  //     setNomeFile("File Caricato!");
  //     document.querySelector("#fileUpload" + p.tipo).value = null;
  //     queryMedia();
  //   }
  //   //queryMediaAssociati();
  //   //const resDisp = controllaDisponibilita(res.libri);
  // }
  return (
    <div style={{ display: "flex" }}>
      <form style={{ marginRight: "10px" }}>
        {/* <Tooltip open={nomeFile != ""} title={nomeFile}> */}
        <IconButton
          className="bottoneC"
          variant="contained"
          component="label"
          style={{ padding: 0 }}
          onClick={(e) => {
            if (tree) {
              p.setTipoPopup("tree");
            } 
            // else {
            //   p.setTipoPopup("");
            // }
            p.setTipoMedia(p.idCat);
            // console.log(111, tree, p);
            p.setPopupUpload(true);
          }}
        >
          {/* <input
                hidden
                type="file"
                id={"fileUpload" + p.tipo}
                onChange={(e) => {
                  setNomeFile(
                    e.target.files[0]?.name ? e.target.files[0]?.name : ""
                  );
                  caricaFile(e);
                }}
              /> */}
          {!checkAutista() && <AddIcon />}
        </IconButton>
        {/* </Tooltip> */}
      </form>
      {checkRuoloUtente("invio-preventivo") && (
        <form>
          <IconButton
            className="bottoneC"
            variant="contained"
            component="label"
            style={{ padding: 0 }}
            onClick={(e) => {
              if (tree) {
                p.setTipoPopup("tree");
              } 
              // else {
              //   p.setTipoPopup("");
              // }
              p.setTipoMedia(p.idCat);
              p.setPopupAllegati(true);
            }}
          >
            <LinkIcon />
          </IconButton>
        </form>
      )}
    </div>
  );
};

export { MediaBottoniForm };
