import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { monFetch, monForm } from "../../inc/inc";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";

const Comp = (p) => {
  const [nomeFile, setNomeFile] = useState("");
  const [file, setFile] = useState(null);
  const [descrizione, setDescrizione] = useState([]);
  const [dataInizio, setDataInizio] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [dataFine, setDataFine] = useState("");

  async function caricaFile(e, file) {
    e.preventDefault();

    var formData = new FormData();
    // let file = document.querySelector("#fileUpload").files[0];
    if (!file) {
      return;
    }
    // let idPrev = p.idPrev;

    const d = new Date();
    let nomeFile = d.getTime();

    formData.append("file", file);
    // formData.append("quote_id", idPrev);
    formData.append("file_name", nomeFile);
    formData.append("description", descrizione);
    formData.append("date_from", dataInizio);
    formData.append("date_to", dataFine);

    const res = await monForm("/fileAdd", formData);
    if (res.media) {
      setNomeFile("File Caricato!");
      p.fnSuccess();
    }
  }

  return (
    <div className="boxMedia boxUpload">
      <h2 style={{ marginTop: 0 }}>Carica File</h2>
      <form
        onSubmit={(e) => {
          caricaFile(e, file);
        }}
      >
        <Grid container spacing={2}>
          <Grid item sm={4} xs={12}>
            {/* <label
              variant="contained"
              component="label"
              className="inputCustom"
            >
              Scegli File
              <input
                hidden
                type="file"
                id="fileUpload"
                onChange={(e) => {
                  setNomeFile(
                    e.target.files[0]?.name ? e.target.files[0]?.name : ""
                  );
                }}
              />
            </label> */}
            <FileUploader
              classes="fileArea"
              label="Carica file"
              name="fileUpload"
              handleChange={(file) => {
                setNomeFile(file?.name ? file?.name : "");
                setFile(file);
              }}
            />
            {" " + nomeFile}
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              label="Data Inizio"
              required
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              type="date"
              variant="outlined"
              className="inputCustom"
              value={dataInizio}
              onChange={(e) => {
                setDataInizio(e.target.value);
              }}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              label="Data Fine"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              type="date"
              variant="outlined"
              className="inputCustom"
              value={dataFine}
              onChange={(e) => {
                setDataFine(e.target.value);
              }}
            />
          </Grid>

          <Grid item sm={12} xs={12}>
            <TextField
              size="small"
              label="Descrizione"
              multiline
              rows={3}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              required
              variant="outlined"
              className="inputCustom"
              value={descrizione}
              onChange={(e) => {
                setDescrizione(e.target.value);
              }}
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <Button variant="contained" type="submit">
              Carica
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Comp;
