// import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

const PopupVedi = (props) => {
  let tagMedia;
  if (props.mime?.startsWith("image")) {
    tagMedia = <img src={"data:" + props.mime + ";base64," + props.media} />;
  } else if (props.mime?.includes("pdf") || props.mime?.startsWith("text")) {
    tagMedia = (
      <object
        data={"data:" + props.mime + ";base64," + props.media}
        type={props.mime}
        style={{ width: "100%" }}
      />
    );
  } else if (props.mime?.startsWith("audio")) {
    tagMedia = (
      <audio controls>
        <source
          src={"data:" + props.mime + ";base64," + props.media}
          type={props.mime}
          style={{ width: "100%" }}
        />
      </audio>
    );
  } else if (props.mime?.startsWith("video")) {
    tagMedia = (
      <video controls>
        <source
          src={"data:" + props.mime + ";base64," + props.media}
          type={props.mime}
          style={{ width: "100%" }}
        />
      </video>
    );
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.fnAnnulla}
      fullWidth
      maxWidth="md"
      className="popupVedi"
    >
      <DialogContent>{props.mime && tagMedia}</DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Chiudi</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupVedi ;
