import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { monFetch } from "../../inc/inc";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import BoxNotifica from "../componenti_generici/BoxNotifica";

const Comp = (props) => {
  let location = useLocation();

  const [categorie, setCategorie] = useState([]);

  const [popupElimina, setPopupElimina] = useState(false);
  const [daEliminare, setDaEliminare] = useState(null);

  const [apriSnackbar, setApriSnackbar] = useState(false);
  const [messaggioSnackbar, setMessaggioSnackbar] = useState("");

  const chiudiSnackbar = (event, reason) => {
    setApriSnackbar(false);
  };

  async function query() {
    const res = await monFetch("/faqCategoryList", {});
    setCategorie(res.res);
  }

  async function elimina(id) {
    const res = await monFetch("/faqCategoryDelete", { id });
    if (res.success) {
      setPopupElimina(false);
      query();
    } else {
      setPopupElimina(false);
      setApriSnackbar(true);
      setMessaggioSnackbar(res.msg);
    }
  }

  useEffect(() => {
    let barraTop = <h2>Categorie Faq</h2>;
    props.setElementiBarraTop(barraTop);
  }, []);

  useEffect(() => {
    query();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={3} xs={12}>
          <Button variant="contained" href="/faqcategorie/nuovo">
            Nuova
          </Button>
        </Grid>
        <Grid item sm={6} xs={12}></Grid>
        {/* <Grid item sm={3} xs={12}>
          <TextField
            size="small"
            label="Cerca Destinazione"
            fullWidth
            variant="outlined"
            className="inputCustom"
            value={filtroCitta || ""}
            onChange={(e) => {
              setFiltroCitta(e.target.value.toUpperCase());
            }}
          />
        </Grid> */}
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Descrizione</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {categorie.map((p) => {
            return (
              <TableRow
                key={p.id}
                className="rigaSelezionabile"
                onClick={(e) => {
                  e.stopPropagation();
                  window.location.href = "/faqcategorie/" + p.id;
                }}
              >
                <TableCell>{p.name}</TableCell>

                <TableCell>{p.description}</TableCell>
                <TableCell
                  style={{
                    textAlign: "right",
                  }}
                >
                  <DeleteIcon
                    color="error"
                    onClick={(e) => {
                      e.stopPropagation();
                      setPopupElimina(true);
                      setDaEliminare(p.id);
                    }}
                    style={{ marginRight: "20px" }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <br />
      {/* <Pagination
        count={Math.ceil(totaleRis / risPerPagina)}
        page={pagina}
        onChange={(e, p) => {
          setPagina(p);
        }}
      /> */}
      <Popup
        open={popupElimina}
        fnAnnulla={(e) => setPopupElimina(false)}
        fnSuccess={(e) => elimina(daEliminare)}
      />

      <BoxNotifica
        apriSnackbar={apriSnackbar}
        chiudiSnackbar={chiudiSnackbar}
        messaggioSnackbar={messaggioSnackbar}
        tipo="error"
      />
    </>
  );
};

const Popup = (props) => {
  return (
    <Dialog open={props.open} onClose={props.fnAnnulla}>
      <DialogTitle>Conferma Eliminazione</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Confermi l'eliminazione dell'elemento?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Annulla</Button>
        <Button onClick={props.fnSuccess} variant="contained">
          Elimina
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Comp;
