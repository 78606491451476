// import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";

import FileLista from "./FileLista";


import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";


const PopupAllegati = (props) => {
  return (
    <Dialog open={props.open} onClose={props.fnAnnulla} fullWidth maxWidth="md">
      <DialogContent>
        <FileLista
          {...props}
          subCat={props.subCat}
          setSubCat={props.setSubCat}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Chiudi</Button>
        <Button
          variant="contained"
          onClick={props.fnSuccess}
          disabled={!props.tipoMedia}
        >
          Associa
        </Button>
      </DialogActions>
    </Dialog>
  );
};


export default PopupAllegati ;
