import {
  monFetch,
  checkRuoloUtente,
  notifyMe,
  notificationPolling,
  settaCookieFiltri,
  resettaCookieFiltri,
} from "../inc/inc";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

import Chart from "react-apexcharts";

const Comp = (props) => {
  //const ruoloLogin = controllaLogin();

  let location = useLocation();

  const colors = ["#e54707", "#666"];
  // data
  let date = new Date();
  let datePrec = new Date();
  datePrec.setFullYear(date.getFullYear() - 1);
  let month = date.getMonth() + 1;
  let monthPrec = datePrec.getMonth() + 1;
  if (month <= 9) {
    month = "0" + month;
  }
  if (monthPrec <= 9) {
    monthPrec = "0" + monthPrec;
  }

  const [prevInScadenzaOggi, setPrevInScadenzaOggi] = useState(0);
  const [prevInseriti, setPrevInseriti] = useState(0);
  const [prevDaAssegnare, setPrevDaAssegnare] = useState(0);
  const [prevDaRealizzare, setPrevDaRealizzare] = useState(0);
  const [prevDaControllare, setPrevDaControllare] = useState(0);
  const [prevDaRivedere, setPrevDaRivedere] = useState(0);
  const [prevDaFinalizzare, setPrevDaFinalizzare] = useState(0);
  const [prevDaInviare, setPrevDaInviare] = useState(0);
  const [prevConfermati, setPrevConfermati] = useState(0);

  const [prevConfermatiGraf, setPrevConfermatiGraf] = useState([]);
  const [prevConfermatiLabel, setPrevConfermatiLabel] = useState([]);

  const [prevConfermatiMezzo, setPrevConfermatiMezzo] = useState([]);
  const [prevConfermatiLabelMezzo, setPrevConfermatiLabelMezzo] = useState([]);

  const [statisticheMezzi, setStatisticheMezzi] = useState({ axes: [], data: [] });







  async function getPreventiviCounter() {
    const res = await monFetch("/homepageStats", {});
    setPrevInScadenzaOggi(res.todayDeadlineCnt);
    setPrevInseriti(res.totalQuoteCnt);
  }

  async function getPreventiviMezzo(anno) {
    const res = await monFetch("/homepageTransport", {});
    setPrevConfermatiMezzo(res.count);
    setPrevConfermatiLabelMezzo(res.labels);
  }

  async function getPreventiviConfermatiGraf() {
    const res = await monFetch("/homepageConfirmed", {});
    setPrevConfermatiGraf(res.count);
    setPrevConfermatiLabel(res.labels);
  }

  async function getCounterPerUtente() {
    const res = await notificationPolling();

    if (checkRuoloUtente("assegnazione-operatore")) {
      setPrevDaAssegnare(res.toAssign);
    }
    if (checkRuoloUtente("realizzazione-preventivo")) {
      setPrevDaRealizzare(res.toDo);
      setPrevDaRivedere(res.toReview);
    }
    if (checkRuoloUtente("controllore")) {
      setPrevDaControllare(res.toCheck);
    }
    if (checkRuoloUtente("finalizzazione-preventivo")) {
      setPrevDaFinalizzare(res.toFinalize);
    }
    if (checkRuoloUtente("invio-preventivo")) {
      setPrevDaInviare(res.toSend);
    }

    setPrevConfermati(res.confirmed)
  }

  async function transportCount() {
    const res = await monFetch("/transportCount", {});
    setStatisticheMezzi(res.statisticheMezzi);
  }

  useEffect(() => {
    //se loggato come fornitore bus
    if (Cookies.get("autistaId")) {
      window.location.href = "/preventivi";
    }
    //

    // notificationPolling();
    props.setElementiBarraTop(<h2>Homepage</h2>);
    getPreventiviCounter();
    getPreventiviConfermatiGraf();
    getPreventiviMezzo();
    transportCount();



    getCounterPerUtente();
  }, []);
  const current = new Date();
  const today = `${current.getDate()}/${current.getMonth() + 1
    }/${current.getFullYear()}`;

  return (
    <Grid container spacing={2}>
      <BloccoDashboard
        titolo="Preventivi inseriti"
        larghezza={3}
        className="pointer"
        dati={<p className="datoNumero">{prevInseriti}</p>}
        onClick={() => {
          resettaCookieFiltri();
          window.location.href = "/preventivi";
        }}
      />
      <BloccoDashboard
        titolo={"Preventivi in scadenza oggi " + today}
        larghezza={3}
        className="pointer"
        dati={<p className="datoNumero">{prevInScadenzaOggi}</p>}
        onClick={() => {
          resettaCookieFiltri();
          settaCookieFiltri("dataScad", new Date().toISOString().split("T")[0]);
          window.location.href = "/preventivi";
        }}
      />

      {checkRuoloUtente("assegnazione-operatore") && (
        <BloccoDashboard
          titolo="Preventivi da assegnare"
          larghezza={3}
          className="pointer"
          dati={<p className="datoNumero">{prevDaAssegnare}</p>}
          onClick={() => {
            resettaCookieFiltri();
            settaCookieFiltri("statusId", 1);
            window.location.href = "/preventivi";
          }}
        />
      )}

      {checkRuoloUtente("realizzazione-preventivo") && (
        <BloccoDashboard
          titolo="Preventivi da realizzare"
          larghezza={3}
          className="pointer"
          dati={<p className="datoNumero">{prevDaRealizzare}</p>}
          onClick={() => {
            resettaCookieFiltri();
            settaCookieFiltri("statusId", 5);
            settaCookieFiltri("userId", Cookies.get("id"));
            window.location.href = "/preventivi";
          }}
        />
      )}
      {checkRuoloUtente("realizzazione-preventivo") && (
        <BloccoDashboard
          titolo="Preventivi da rivedere"
          larghezza={3}
          className="pointer"
          dati={<p className="datoNumero">{prevDaRivedere}</p>}
          onClick={() => {
            resettaCookieFiltri();
            settaCookieFiltri("statusId", 15);
            window.location.href = "/preventivi";
          }}
        />
      )}

      {checkRuoloUtente("controllore") && (
        <BloccoDashboard
          titolo="Preventivi da controllare"
          larghezza={3}
          className="pointer"
          dati={<p className="datoNumero">{prevDaControllare}</p>}
          onClick={() => {
            resettaCookieFiltri();
            settaCookieFiltri("statusId", 10);
            window.location.href = "/preventivi";
          }}
        />
      )}

      {checkRuoloUtente("finalizzazione-preventivo") && (
        <BloccoDashboard
          titolo="Preventivi controllati"
          larghezza={3}
          className="pointer"
          dati={<p className="datoNumero">{prevDaFinalizzare}</p>}
          onClick={() => {
            resettaCookieFiltri();
            settaCookieFiltri("statusId", 20);
            settaCookieFiltri("userId", Cookies.get("id"));
            window.location.href = "/preventivi";
          }}
        />
      )}

      {checkRuoloUtente("invio-preventivo") && (
        <BloccoDashboard
          titolo="Preventivi da inviare"
          larghezza={3}
          className="pointer"
          dati={<p className="datoNumero">{prevDaInviare}</p>}
          onClick={() => {
            resettaCookieFiltri();
            settaCookieFiltri("statusId", 25);
            window.location.href = "/preventivi";
          }}
        />
      )}

      {checkRuoloUtente("finalizzazione-preventivo") && (
        <BloccoDashboard
          titolo="Preventivi confermati"
          larghezza={3}
          className="pointer"
          dati={<p className="datoNumero">{prevConfermati}</p>}
          onClick={() => {
            resettaCookieFiltri();
            settaCookieFiltri("statusId", 50);
            window.location.href = "/preventivi";
          }}
        />
      )}
      {prevConfermatiLabel.length > 0 && <BloccoDashboard
        titolo="Andamento Conferme"
        dati={
          prevConfermatiGraf.length == 0 ? (
            "Nessun dato da mostrare"
          ) : (
            <Chart
              type="pie"
              series={prevConfermatiGraf}
              options={{
                labels: prevConfermatiLabel,
              }}
            />
          )
        }
      />}

      {statisticheMezzi.axes.length > 0 && <BloccoDashboard
        larghezza={8}
        titolo="Statistiche mezzi di trasporto richiesti"
        dati={
          <Chart
            type="bar"
            height={350}
            series={statisticheMezzi.data}
            options={{
              chart: {
                stacked: true,
                toolbar: {
                  show: false,
                },
              },
              xaxis: {
                categories: statisticheMezzi.axes
              },
            }}
          />
        }
      />
      }

      {prevConfermatiLabelMezzo.length > 0 && <BloccoDashboard
        titolo="Andamento conferme per mezzo"
        dati={
          prevConfermatiMezzo.length == 0 ? (
            "Nessun dato da mostrare"
          ) : (
            <Chart
              type="pie"
              series={prevConfermatiMezzo}
              options={{
                labels: prevConfermatiLabelMezzo,
              }}
            />
          )
        }
      />}
    </Grid>
  );
};

const BloccoDashboard = (props) => {
  return (
    <Grid
      item
      sm={props.larghezza || 4}
      xs={12}
      style={{ display: "flex" }}
      {...props}
    >
      <div className="dashBox" style={{ width: "100%" }}>
        <div className="">
          <h3 style={{ marginTop: 0, marginBottom: 10 }}>{props.titolo}</h3>
        </div>

        {props.dati}
      </div>
    </Grid>
  );
};

export default Comp;
