import * as React from "react";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Cookies from "js-cookie";
import Alert from "@mui/material/Alert";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InputAdornment from "@mui/material/InputAdornment";
import LockIcon from "@mui/icons-material/Lock";
import { monFetch } from "../inc/inc";

export default function Comp(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errore, setErrore] = useState(false);
  const [bkgImg, setBkgImg] = useState("");

  async function login(e) {
    e.preventDefault();
    const res = await monFetch("/login", {
      email,
      password,
    });
    // console.log(res);
    if (res.success) {
      if (res.isAutista) {
        //se provider bus
        Cookies.set("token", res.token, { expires: 7 });
        Cookies.set("autistaId", res.autistaId, { expires: 7 });

        Cookies.remove("roles");
        Cookies.remove("companyId");
        Cookies.remove("adminLevel");

        Cookies.remove("filtriLista");
        window.location.href = "/preventivi";
      } else {
        Cookies.remove("autistaId");
        Cookies.set("token", res.token, { expires: 7 });
        Cookies.set("id", res.id, { expires: 7 });
        Cookies.set("roles", JSON.stringify(res.role), { expires: 7 });
        Cookies.set("companyId", res.company_id, { expires: 7 });
        Cookies.set("adminLevel", res.admin_level, { expires: 7 });
        Cookies.remove("filtriLista");
        window.location.href = "/";
      }
    } else {
      setErrore(true);
    }
  }

  async function loadBkg() {
    let res = await monFetch("/utilityBackground", {});
    setBkgImg('url("' + res.image + '")');
    // console.log(res);
  }
  useEffect(() => {
    loadBkg();
  }, []);
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
        backgroundImage: bkgImg,
        backgroundSize: "cover",
        backgroundRepeat: "none",
      }}
    >
      <div className="boxlogin">
        <img src="/img/logo-bianco.svg" />
        <br />
        {errore && (
          <Alert severity="error" sx={{ mb: 2 }}>
            Credenziali errate
          </Alert>
        )}
        <form onSubmit={(e) => login(e)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              Email
              <TextField
                className="email inputBianco"
                
                type="email"
                value={email}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              Password
              <TextField
                className="password inputBianco"
                
                type="password"
                value={password}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" fullWidth type="submit">
                Accedi
              </Button>
              <p style={{ textAlign: "center" }}>
                <a href="/recupera-password">
                  <AccountCircleIcon
                    style={{ verticalAlign: "middle", marginRight: "5px", "color": "#fff" }}
                  />
                  <span style={{ verticalAlign: "middle", "color": "#fff" }}>
                    Password Dimenticata
                  </span>
                </a>
              </p>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}
