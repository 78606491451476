import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {
  Pallino,
  convertiData,
  controllaLogin,
  monFetch,
  monMedia,
} from "../../inc/inc";
import Cookies from "js-cookie";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ImageIcon from "@mui/icons-material/Image";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import GridOnIcon from "@mui/icons-material/GridOn";
import Checkbox from "@mui/material/Checkbox";
import FileModifica from "./FileModifica";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const Comp = (props) => {
  // console.log(22222, props);
  let tree = false;
  if (props.tipoPopup == "tree") {
    tree = true;
  }
  //const ruoloLogin = controllaLogin();
  const selettori = props.selettori;

  let location = useLocation();

  const [files, setFiles] = useState([]);

  const [popupElimina, setPopupElimina] = useState(false);
  const [fileDaEliminare, setFileDaEliminare] = useState(null);

  const [popupUpload, setPopupUpload] = useState(false);
  const [popupVedi, setPopupVedi] = useState(false);
  const [vediMedia, setVediMedia] = useState(null);
  const [vediMediaMime, setVediMediaMime] = useState(null);

  const [optionsTipiMedia, setOptionsTipiMedia] = useState([]);
  const [optionSelezionata, setOptionSelezionata] = useState(null);

 
  const [optionsSubCategorie, setOptionsSubCategorie] = useState([]);

  async function querySubCategorie() {
    const res = await monFetch("/mediaSubCatList", {
      idCat: props.tipoMedia,
    });
    // console.log(res);
    const temp = [
      // { label: "Nessuna", value: 0 }
    ];
    res.res.forEach((el) => {
      temp.push({ label: el.media_subcat, value: el.id });
    });
    setOptionsSubCategorie(temp);
  }

  // async function queryTipiMedia() {
  //   const res = await monFetch("/mediaTypeList", {});
  //   // console.log(res);
  //   const stati = [];
  //   res.media.forEach((el) => {
  //     stati.push({ label: el.media_type, value: el.id });
  //   });
  //   setOptionsTipiMedia(stati);
  // }

  async function query() {
    const res = await monFetch("/fileList", {});
    // console.log(res);
    setFiles(res.files);
  }

  async function getDatiMedia(id) {
    const res = await monFetch("/fileGet", { id: id });
    // console.log(res);
    setVediMedia(res.file);
    setVediMediaMime(res.mime);
  }

  async function eliminaFile(id) {
    const res = await monFetch("/fileDelete", { id });
    // console.log(res);
    if (res.success) {
      setPopupElimina(false);
      query();
    }
  }

  async function setFileSelezionato(id, val) {
    let temp = { ...props.checkSel };
    temp[id] = val;

    // console.log(temp);

    props.setCheckSel(temp);
  }

  async function getFiles() {
    let res;
    if (tree) {
      res = await monFetch("/fileQuoteGetTree", {
        quote_id: props.idPrev,
        category_id: props.tipoMedia,
      });
    } else {
      res = await monFetch("/fileQuoteGet", {
        quote_id: props.idPrev,
        media_type_id: props.tipoMedia,
      });
    }

    let temp = {};
    res.res.forEach((r) => {
      temp[r.file_id] = true;
    });
    props.setCheckSel(temp);
  }

  async function downloadFile(id, nomeFile) {
    const res = await monMedia("/fileDownload", { media_id: id });
    // console.log(res);

    var url = window.URL.createObjectURL(res);
    var a = document.createElement("a");
    a.href = url;
    a.download = nomeFile;
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove(); //afterwards we remove the element again
  }

  useEffect(() => {
    if (props.setElementiBarraTop) {
      props.setElementiBarraTop(<h2>Elenco Files</h2>);
    }
    query();
  }, []);

  useEffect((e) => {
    if (tree) {
      querySubCategorie();
    } 
    // else {
    //   queryTipiMedia();
    // }
  }, []);

  useEffect(
    (e) => {
      if (props.idPrev) {
        //se aperto nel popup del preventivo
        //popolo i checkbox
        getFiles();
      }
    },
    [props.tipoMedia]
  );

  useEffect(
    (e) => {
      if (optionsTipiMedia && optionsTipiMedia.length > 0) {
        if (props.tipoMedia) {
          setOptionSelezionata(
            optionsTipiMedia.find((op) => op.value == props.tipoMedia)
          );
        } else {
          setOptionSelezionata(null);
        }
      }
    },
    [props.tipoMedia, optionsTipiMedia]
  );

  return (
    <>
      <Button
        variant="contained"
        //href="/files-nuovo"
        onClick={() => {
          setPopupUpload(true);
        }}
      >
        Carica Nuovo{" "}
      </Button>

      {selettori && (
        <div style={{ marginTop: "20px" }}>
          {tree ? (
            //new Tree
            <>
              <Autocomplete
                value={
                  optionsSubCategorie.find((op) => op.value == props.subCat)
                    ? optionsSubCategorie.find((op) => op.value == props.subCat)
                    : null
                }
                onChange={(e, value) => {
                  // console.log("change autoc", value);
                  props.setSubCat(value?.value);
                }}
                options={optionsSubCategorie}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Sottocategoria"
                    className="inputCustom"
                    // required
                  />
                )}
              />
            </>
          ) : (
            <>
              {/* // old tipo media */}
              <Autocomplete
                value={optionSelezionata}
                onChange={(e, value) => {
                  // console.log("change autoc", value);
                  props.setTipoMedia(value?.value);
                }}
                options={optionsTipiMedia}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Tipo allegato"
                    className="inputCustom"
                    required
                  />
                )}
              />
            </>
          )}
        </div>
      )}

      {(props.tipoMedia || !selettori) && (
        <Table>
          <TableHead>
            <TableRow>
              {selettori && <TableCell></TableCell>}
              <TableCell></TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>Descrizione</TableCell>
              <TableCell>Data Inizio</TableCell>
              <TableCell>Data Fine</TableCell>
              <TableCell>Data Aggiunta</TableCell>
              {!selettori && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {files.map((file) => {
              let mime = "doc";
              let visualizza = false;
              if (file.mime_type.startsWith("image")) {
                mime = "img";
                visualizza = true;
              } else if (file.mime_type.startsWith("audio")) {
                mime = "audio";
                visualizza = true;
              } else if (file.mime_type.startsWith("video")) {
                mime = "video";
                visualizza = true;
              } else if (file.mime_type.startsWith("text")) {
                visualizza = true;
              } else if (file.mime_type.includes("pdf")) {
                mime = "pdf";
                visualizza = true;
              } else if (file.mime_type.includes("spreadsheet")) {
                mime = "excel";
              }

              return (
                <TableRow key={file.id} className="rigaSelezionabile">
                  {selettori && (
                    <TableCell>
                      <Checkbox
                        checked={props.checkSel[file.id] || false}
                        onChange={(e) =>
                          setFileSelezionato(file.id, e.target.checked)
                        }
                      />
                    </TableCell>
                  )}
                  <TableCell>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FileDownloadIcon
                        onClick={(e) => {
                          downloadFile(file.id, file.original_name);
                        }}
                      />
                      {visualizza && (
                        <VisibilityIcon
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setPopupVedi(true);
                            getDatiMedia(file.id);
                          }}
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {mime == "doc" && <InsertDriveFileIcon />}
                      {mime == "img" && <ImageIcon />}
                      {mime == "pdf" && <PictureAsPdfIcon />}
                      {mime == "audio" && <VolumeDownIcon />}
                      {mime == "video" && <OndemandVideoIcon />}
                      {mime == "excel" && <GridOnIcon />}{" "}
                      <span>{file.original_name}</span>
                    </div>
                  </TableCell>
                  <TableCell
                    dangerouslySetInnerHTML={{
                      __html: file.description?.replace(
                        /(?:\r\n|\r|\n)/g,
                        "<br>"
                      ),
                    }}
                  ></TableCell>
                  <TableCell>{convertiData(file.date_from)}</TableCell>
                  <TableCell>{convertiData(file.date_to)}</TableCell>
                  <TableCell>{convertiData(file.date_added)}</TableCell>
                  {!selettori && (
                    <TableCell>
                      <DeleteIcon
                        color="error"
                        onClick={(e) => {
                          e.stopPropagation();
                          setPopupElimina(true);
                          setFileDaEliminare(file.id);
                        }}
                      />
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
      <Popup
        open={popupElimina}
        fnAnnulla={(e) => setPopupElimina(false)}
        fnSuccess={(e) => eliminaFile(fileDaEliminare)}
      />

      <PopupVedi
        open={popupVedi}
        media={vediMedia}
        mime={vediMediaMime}
        fnAnnulla={(e) => {
          setPopupVedi(false);
          setVediMediaMime(null);
        }}
      />

      <PopupUpload
        open={popupUpload}
        // idPrev={idPrev}
        fnSuccess={(e) => {
          setPopupUpload(false);
          query();
        }}
        fnAnnulla={(e) => setPopupUpload(false)}
      />
    </>
  );
};

const Popup = (props) => {
  return (
    <Dialog open={props.open} onClose={props.fnAnnulla}>
      <DialogTitle>Conferma Eliminazione</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Confermi l'eliminazione dell'elemento?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Annulla</Button>
        <Button onClick={props.fnSuccess} variant="contained">
          Elimina
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PopupUpload = (props) => {
  return (
    <Dialog open={props.open} onClose={props.fnAnnulla} fullWidth maxWidth="md">
      <DialogContent>
        <FileModifica fnSuccess={props.fnSuccess} />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Chiudi</Button>
      </DialogActions>
    </Dialog>
  );
};

const PopupVedi = (props) => {
  let tagMedia;
  if (props.mime?.startsWith("image")) {
    tagMedia = <img src={"data:" + props.mime + ";base64," + props.media} />;
  } else if (props.mime?.includes("pdf") || props.mime?.startsWith("text")) {
    tagMedia = (
      <object
        data={"data:" + props.mime + ";base64," + props.media}
        type={props.mime}
        style={{ width: "100%" }}
      />
    );
  } else if (props.mime?.startsWith("audio")) {
    tagMedia = (
      <audio controls>
        <source
          src={"data:" + props.mime + ";base64," + props.media}
          type={props.mime}
          style={{ width: "100%" }}
        />
      </audio>
    );
  } else if (props.mime?.startsWith("video")) {
    tagMedia = (
      <video controls>
        <source
          src={"data:" + props.mime + ";base64," + props.media}
          type={props.mime}
          style={{ width: "100%" }}
        />
      </video>
    );
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.fnAnnulla}
      fullWidth
      maxWidth="md"
      className="popupVedi"
    >
      <DialogContent>{props.mime && tagMedia}</DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Chiudi</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Comp;
