import TextField from "@mui/material/TextField";
// import { monFetch } from "../inc/inc";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";

import { monFetch } from "../../inc/inc";

const Comp = (props) => {
  let settaDati = props.settaDati;
  let dati = props.dati;
  let arr = props.arr;
  let index = props.index;

  const arrotonda = (valore) => {
    return Math.round(valore * 100) / 100;
  };

  const [compagnie, setCompagnie] = useState([]);
  const [tipi, setTipi] = useState([]);

  if (!dati.treni) {
    //se non ci sono treni
    dati.treni = [];
  }

  //ordino per andata e ritorno
  dati.treni.sort((a, b) => {
    // console.log(a, b, a.direction < b.direction);
    return a.direction < b.direction ? -1 : 1;
  });

  ///calcolo totale
  let totaleAPagante = 0;
  let totale = Number(props.totaleNonPaganti) + Number(props.totalePaganti);

  dati.treni.forEach((el) => {
    // console.log(999, el)
    let value = Number(el.costoPax || 0);

    if (el.gratuita > 0) {
      let gratuiti = Math.floor(totale / (el.gratuita + 1));
      // console.log(999, gratuiti)
      // let gratuiti =
      //   (totale - Math.floor(totale / (el.gratuita + 1))) /
      //   Number(props.totalePaganti);
      if (gratuiti > el.max) {
        gratuiti = el.max;
      }
      // console.log(999, gratuiti, el.max)
      value = ((totale - gratuiti) * value) / Number(props.totalePaganti);
    } else {
      value = value * (totale / Number(props.totalePaganti));
    }
    totaleAPagante += value;
  });
  totaleAPagante = arrotonda(totaleAPagante);

  // console.log(999, totaleAPagante)

  async function queryCompagnie() {
    const res = await monFetch("/trainCompanyList", {});

    let temp = res.res.map((el) => {
      return {
        label: el.name,
        value: el.id,
        gratuita: el["1free"],
        max: el.max,
      };
    });

    setCompagnie(temp);
  }

  async function queryTipi() {
    const res = await monFetch("/trainTypeList", {});

    let temp = res.res.map((el) => {
      return {
        label: el.name,
        value: el.id,
        train_company_id: el.train_company_id,
      };
    });

    setTipi(temp);
  }

  useEffect(() => {
    queryCompagnie();
    queryTipi();
  }, []);

  useEffect(() => {
    // console.log("TRENO IN", dati, index, totaleAPagante);
    let timeout = setTimeout((el) => {
      settaDati(
        arr,
        {
          totaleAPagante: totaleAPagante,
        },
        index
      );
    }, 50);

    return () => clearTimeout(timeout);
  }, [totaleAPagante, dati.treni, props.idProp]);

  let ritornoFirst = true;

  return (
    <>
      <Grid item xs={6} className="alignCenter">
        <label className="labelGruppo noBorder">Proposta {index + 1}</label>
      </Grid>

      <Grid item xs={3}>
        <TextField
          size="small"
          label="Quota a pagante"
          disabled
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom totalePaganti euro"
          value={dati.totaleAPagante || ""}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          size="small"
          label="Quota totale"
          disabled
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom totaleComplessivo euro"
          value={arrotonda((dati.totaleAPagante || 0) * props.totalePaganti)}
        />
      </Grid>

      {dati.treni.map((el, index2) => {
        let titolo = null;

        if (index2 == 0) {
          titolo = (
            <Grid item xs={12}>
              <div className="andataRitornoTesto">Andata</div>
            </Grid>
          );
        }

        if (el.direction == "r" && ritornoFirst) {
          titolo = (
            <>
              {/* <Grid item xs={12} className=" hideOnPrint">
                <div className="labelGruppoCont"></div>
              </Grid> */}
              <Grid item xs={12}>
                <div className="andataRitornoTesto"> Ritorno</div>
              </Grid>
            </>
          );
          ritornoFirst = false;
        }
        return (
          <Grid item sm={12} xs={12} key={index2} className="rigaTrenoSingolo">
            <div className="rigaTrenoSingolo2">
              <Grid item container sm={12} xs={12} spacing={1}>
                {titolo}
                <Grid item xs={3}>
                  {/* compagnia  treno*/}
                  <Autocomplete
                    value={
                      compagnie.length > 0 && el.train_company
                        ? compagnie.find((op) => op.value == el.train_company)
                        : null
                    }
                    onChange={(e, value) => {
                      //console.log(value)
                      if (!value) {
                        settaDati(arr, { train_company: "" }, index, index2);
                        return;
                      }
                      settaDati(
                        arr,
                        {
                          train_company: value?.value,
                          gratuita: value ? value["gratuita"] : "",
                          max: value ? value["max"] : "",
                        },
                        index,
                        index2
                      );
                    }}
                    options={compagnie}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Vettore"
                        size="small"
                        className="inputCustom"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  {/* tipo  treno*/}
                  <Autocomplete
                    value={
                      tipi.length > 0 && el.train_type
                        ? tipi.find((op) => op.value == el.train_type)
                        : null
                    }
                    onChange={(e, value) => {
                      //console.log(value)
                      if (!value) {
                        settaDati(arr, { train_type: "" }, index, index2);
                        return;
                      }
                      settaDati(
                        arr,
                        { train_type: value?.value },
                        index,
                        index2
                      );
                    }}
                    options={tipi.filter((el2) => {
                      return el2.train_company_id == el.train_company;
                    })}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipo"
                        size="small"
                        className="inputCustom"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    size="small"
                    label="Loc. Partenza"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="inputCustom"
                    value={el.partenzaLoc || ""}
                    onChange={(e) => {
                      settaDati(
                        arr,
                        { partenzaLoc: e.target.value },
                        index,
                        index2
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    size="small"
                    label="Loc. Arrivo"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="inputCustom"
                    value={el.arrivoLoc || ""}
                    onChange={(e) => {
                      settaDati(
                        arr,
                        { arrivoLoc: e.target.value },
                        index,
                        index2
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    size="small"
                    label="Orario Partenza"
                    type="time"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="inputCustom"
                    value={el.partenza || ""}
                    onChange={(e) => {
                      settaDati(
                        arr,
                        { partenza: e.target.value },
                        index,
                        index2
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    size="small"
                    label="Orario Arrivo"
                    type="time"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="inputCustom"
                    value={el.arrivo || ""}
                    onChange={(e) => {
                      settaDati(arr, { arrivo: e.target.value }, index, index2);
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    size="small"
                    label="€ / pax"
                    type="number"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="inputCustom euro"
                    value={el.costoPax || ""}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => {
                      settaDati(
                        arr,
                        { costoPax: e.target.value },
                        index,
                        index2
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    size="small"
                    label="Gratuità"
                    disabled
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="inputCustom"
                    value={el.gratuita || "No"}
                  />
                </Grid>

                <Grid item xs={1}>
                  <DeleteIcon
                    className="pointer"
                    color="error"
                    onClick={(e) => {
                      props.eliminaTreno(index, index2);
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        );
      })}
      <Grid item xs={12} style={{ marginTop: 15 }} className="hideOnPrint">
        <Button
          variant="contained"
          style={{ marginRight: 15 }}
          onClick={(e) => {
            dati.treni.push({ direction: "a" });
            settaDati(arr, { treni: dati.treni }, index);
          }}
        >
          Agg. Andata
        </Button>

        <Button
          variant="contained"
          onClick={(e) => {
            dati.treni.push({ direction: "r" });
            settaDati(arr, { treni: dati.treni }, index);
          }}
        >
          Agg. Ritorno
        </Button>
      </Grid>
    </>
  );
};

export default Comp;
