import React, { useState, useEffect } from "react";

import Snackbar from "@mui/material/Snackbar";

import MuiAlert from "@mui/material/Alert";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Comp = (props) => {
  // la prop tipo è "error" in caso di notifica errore
  return (
    <Snackbar
      open={props.apriSnackbar}
      autoHideDuration={6000}
      onClose={props.chiudiSnackbar}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert severity={props.tipo || "success"} sx={{ width: "100%" }}>
        {props.messaggioSnackbar}
      </Alert>
    </Snackbar>
  );
};

export default Comp;
