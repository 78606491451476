// import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";


import { MediaUpload } from "./MediaUpload";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

const PopupUpload = (props) => {
  return (
    <Dialog open={props.open} onClose={props.fnAnnulla} fullWidth maxWidth="md">
      <DialogContent>
        <MediaUpload
          idPrev={props.idPrev}
          fnSuccess={props.fnSuccess}
          tipoMedia={props.tipoMedia}
          setTipoMedia={props.setTipoMedia}
          tipoPopup={props.tipoPopup}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.fnAnnulla();
            props.setTipoMedia("");
          }}
        >
          Chiudi
        </Button>
      </DialogActions>
    </Dialog>
  );
};



export default PopupUpload ;
