import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";

const Comp = (props) => {
  const arrotonda = (valore) => {
    return Math.round(valore * 100) / 100;
  };

  let settaDati = props.settaDati;
  let dati = props.dati;
  let arr = props.arr;
  let index1 = props.index1;
  let index2 = props.index2;
  let totalePagante = dati.lordo + props.assicurazione;

  const [totaleForzato, setTotaleForzato] = useState("");
  const [updateManuale, setUpdateManuale] = useState(false);

  let totaleForzatoInput =
    arrotonda(Number(totaleForzato) || Number(totalePagante)) || 0;

  let markupForzato =
    props.markup + (totaleForzatoInput - arrotonda(totalePagante || 0));

  function settaRiepilogoSave(index1, index2, key, val) {
    let temp = props.totaliRiepilogoSave;
    if (!temp[index1]) {
      temp[index1] = [];
    }
    if (!temp[index1][index2]) {
      temp[index1][index2] = {};
    }
    temp[index1][index2][key] = val;

    props.setTotaliRiepilogoSave(temp);
  }

  useEffect(() => {
    let totaleForzato2 = totaleForzato;
    // console.log(999, updateManuale);
    if (updateManuale) {
      setTotaleForzato(totaleForzato2);
      settaRiepilogoSave(index1, index2, "forzato", totaleForzato2);
      setUpdateManuale(false);
      return;
    }
    // console.log(props.totaliRiepilogoDB, index1, index2);
    if (
      props.totaliRiepilogoDB[index1] &&
      props.totaliRiepilogoDB[index1][index2] &&
      !props.totaliRiepilogoDB[index1][index2].forzato
    ) {
      ///resetto forzato dopo cambio proposta
      // console.log(1111, index1, index2)
      setTotaleForzato("");
      settaRiepilogoSave(index1, index2, "forzato", "");
      // setUpdateManuale(false)
    }
    if (
      // !updateManuale &&
      props.totaliRiepilogoDB.length > 0
    ) {
      if (
        props.totaliRiepilogoDB[index1] &&
        props.totaliRiepilogoDB[index1][index2] &&
        props.totaliRiepilogoDB[index1][index2].forzato == 1
      ) {
        totaleForzato2 = props.totaliRiepilogoDB[index1][index2].totale;
        if (totaleForzato2 && totaleForzato2 > 0) {
          // console.log(222, index1, index2)
          setTotaleForzato(totaleForzato2);
          settaRiepilogoSave(index1, index2, "forzato", totaleForzato2);
        }
      }
    }
    settaRiepilogoSave(index1, index2, "automatico", arrotonda(totalePagante));

    return () => {
      settaRiepilogoSave(index1, index2, "automatico", 0);
      settaRiepilogoSave(index1, index2, "forzato", 0);
    };
  }, [props.assicurazione, dati.lordo, props.calcQuoteId]);

  return (
    <>
      <Grid item sm={12} xs={12}>
        <label className="labelGruppo">Riepilogo {props.dati.nome}</label>
      </Grid>
      <Grid item xs={3}>
        <TextField
          size="small"
          label="Quota netta"
          disabled
          type="text"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          value={arrotonda(Number(dati.netto) || 0)}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          size="small"
          label="Markup a persona"
          disabled
          type="text"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          value={arrotonda(markupForzato)}
        />
      </Grid>
      <Grid item sm={3}>
        <Tooltip title={props.policyNome}>
          <TextField
            size="small"
            type="text"
            label="Assicurazione"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            disabled
            variant="outlined"
            className="inputCustom"
            value={arrotonda(props.assicurazione || 0)}
          />
        </Tooltip>
      </Grid>

      <Grid item xs={3}>
        <TextField
          size="small"
          label="Serv + assic."
          disabled
          type="text"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          value={arrotonda(
            (Number(dati.netto) || 0) + Number(props.assicurazione || 0)
          )}
        />
      </Grid>

      {/* <Grid item xs={3}>
        <TextField
          size="small"
          label="Quota con Mk."
          disabled
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          onWheel={(e) => e.target.blur()}
          variant="outlined"
          className="inputCustom euro  totalePaganti"
          value={arrotonda(totalePagante || 0)}
        />
      </Grid> */}
      {/* <Grid item xs={3}>
        <TextField
          size="small"
          label="Markup"
          disabled
          type="text"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          value={arrotonda(props.markup)}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          size="small"
          label="MU %"
          disabled
          type="text"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom percentuale"
          value={dati.muPercent || 0}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          size="small"
          label="Margine Quota totale"
          disabled
          type="text"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          value={arrotonda(dati.margine || 0)}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          size="small"
          label="Quota vendita."
          disabled
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro  totalePaganti"
          onWheel={(e) => e.target.blur()}
          value={totaleForzatoInput}
        />
      </Grid> */}
      <Grid item xs={3}>
        <TextField
          size="small"
          label="Da fatturare"
          disabled
          type="text"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          value={dati.lordoTot || 0}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          size="small"
          label="Markup in %"
          disabled
          type="text"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom percentuale"
          value={arrotonda(
            markupForzato > 0
              ? (markupForzato /
                (totaleForzatoInput > 0 ? dati.lordo || 100 : 9999)) *
              100
              : 0
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          size="small"
          label="Margine quota tot"
          disabled
          type="text"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          value={arrotonda(markupForzato * props.totalePaganti)}
        />
      </Grid>

      {/* <Grid item sm={3}></Grid> */}
      <Grid item sm={3}>
        <TextField
          size="small"
          type="number"
          label="Quota a pagante"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className={
            "inputCustom inputBig totaleForzato" + (totaleForzato ? " forzato " : "")
          }
          onWheel={(e) => e.target.blur()}
          value={totaleForzatoInput}
          onChange={(e) => {
            let val = Number(e.target.value);
            setTotaleForzato(val);
            setUpdateManuale(true);
            props.calcolaRiepilogo();
            settaRiepilogoSave(index1, index2, "forzato", val);

            ////lancio il salvataggio
            // props.fnSalva(true, props.parametri);
          }}
        />
      </Grid>
    </>
  );
};

export default Comp;
