import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { monFetch } from "../inc/inc";

import {  useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

import "react-image-crop/dist/ReactCrop.css";

const Comp = (props) => {
    let params = useParams();

    let id = params.id;

    const [name, setName] = useState("");
    const [cabin, setCabin] = useState("");
    const [cabinStiva, setCabinStiva] = useState("");
    const [luggage, setLuggage] = useState("");

    const [attivoDa, setAttivoDa] = useState(
        new Date().toISOString().split("T")[0]
    );
    const [attivoA, setAttivoA] = useState("");

    const [errore, setErrore] = useState("");

    async function query(v) {
        const res = await monFetch("/flightSupplierGet", {
            id,
        });

        setName(res.res.name);
        setCabin(res.res.cabin);
        setCabinStiva(res.res.cabin_stiva);
        setLuggage(res.res.luggage);

        setAttivoDa(res.res.valid_from);
        setAttivoA(res.res.valid_to);
    }

    async function salva(e) {
        e.preventDefault();

        const res = await monFetch("/flightSupplierSet", {
            id,
            name: name,
            cabin: cabin,
            cabinStiva: cabinStiva,
            luggage: luggage,
            valid_from: attivoDa, 
            valid_to: attivoA
        });
        if (res.success) {
            window.location.href = "/flight";
        } else {
            setErrore("Errore nel salvataggio dei dati");
        }
    }

    // async function queryVoli() {
    //     const res = await monFetch("/faqCategoryList", {});
    //     console.log(res);
    //     let temp = [];
    //     res.res.forEach((el) => {
    //         temp.push({ label: el.name, id: el.id });
    //     });

    //     setOptionsVoli(temp);
    //     return temp;
    // }

    useEffect(() => {
        if (id) {
            query();
        }
        let barraTop = (
            <h2>{!id ? "Nuova Compagnia Aerea" : "Modifica Compagnia Aerea"}</h2>
        );
        props.setElementiBarraTop(barraTop);
    }, []);

    return (
        <form onSubmit={(e) => salva(e)}>
            <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                    {errore && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {errore}
                        </Alert>
                    )}
                </Grid>

                <Grid item sm={3} xs={12}>
                    <TextField
                        required
                        label="Compagnia aerea"
                        fullWidth
                        variant="outlined"
                        className="inputCustom"
                        value={name}
                        // disabled={id ? true : false}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <TextField
                        label="Bagaglio a mano"
                        type="number"
                        required
                        fullWidth
                        variant="outlined"
                        className="inputCustom"
                        value={cabin}
                        onChange={(e) => {
                            setCabin(e.target.value);
                        }}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <TextField
                        label="Bagaglio piccolo in stiva"
                        type="number"
                        required
                        fullWidth
                        variant="outlined"
                        className="inputCustom"
                        value={cabinStiva}
                        onChange={(e) => {
                            setCabinStiva(e.target.value);
                        }}
                    />
                </Grid>

                <Grid item sm={3} xs={12}>
                    <TextField
                        label="Bagaglio grande in stiva"
                        type="number"
                        required
                        fullWidth
                        variant="outlined"
                        className="inputCustom"
                        value={luggage}
                        onChange={(e) => {
                            setLuggage(e.target.value);
                        }}
                    />
                </Grid>

                <Grid item sm={3} xs={12}>
                    <TextField
                        label="Attivo da"
                        type="date"
                        fullWidth
                        variant="outlined"
                        className="inputCustom"
                        value={attivoDa || ""}
                        onChange={(e) => {
                            setAttivoDa(e.target.value);
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item sm={3} xs={12}>
                    <TextField
                        label="Attivo Fino a"
                        type="date"
                        fullWidth
                        variant="outlined"
                        className="inputCustom"
                        value={attivoA || ""}
                        onChange={(e) => {
                            setAttivoA(e.target.value);
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item sm={12} xs={12}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Button className="inputCustom" variant="contained" href="/flight">
                            Indietro
                        </Button>

                        <Button
                            className="inputCustom bottoneSalva"
                            type="submit"
                            variant="contained"
                        >
                            Salva
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </form>
    );
};

export default Comp;
