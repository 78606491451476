import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import LibriAggiorna from "./LibriAggiorna";
// import PrenotaLibro from "./PrenotaLibro";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Pallino, convertiData, controllaLogin, monFetch } from "../inc/inc";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const Comp = (props) => {
  //const ruoloLogin = controllaLogin();

  let location = useLocation();

  const [ruoli, setRuoli] = useState([]);

  const [popupElimina, setPopupElimina] = useState(false);
  const [ruoloDaEliminare, setRuoloDaEliminare] = useState(null);

  // const [listaAutori, setListaAutori] = useState([]);
  // const [listaGeneri, setListaGeneri] = useState([]);

  // const [elementoDaAggiornare, setElementoDaAggiornare] = useState(null);
  // const [apriAggiorna, setApriAggiorna] = useState(false);
  // const [apriPrenota, setApriPrenota] = useState(false);

  // const [cercaTitolo, setCercaTitolo] = useState("");
  // const [cercaAutore, setCercaAutore] = useState("");
  // const [cercaGeneri, setCercaGeneri] = useState("");
  // const [pagina, setPagina] = useState(1);
  // const [risPerPagina, setRisPerPagina] = useState(20);

  // const controllaDisponibilita = (ogg) => {
  //   ogg.forEach((el) => {
  //     const dataFine = new Date(el.dataFine);
  //     const dataOdierna = new Date();
  //     const diffDate = dataOdierna - dataFine;
  //     if (diffDate < 0) {
  //       el.disponibilePren = 0;
  //     } else {
  //       el.disponibilePren = 1;
  //     }
  //   });

  //   return ogg;
  // };

  // async function getListaAutori() {
  //   const response = await fetch(
  //     process.env.REACT_APP_BACKEND + "/listaAutori",
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: Cookies.get("ruoloToken"),
  //       },
  //       body: JSON.stringify({}),
  //     }
  //   );
  //   let res = await response.json();
  //   console.log(1111, res);
  //   setListaAutori(res);
  // }

  // async function getListaGeneri() {
  //   const response = await fetch(
  //     process.env.REACT_APP_BACKEND + "/listaGeneri",
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: Cookies.get("ruoloToken"),
  //       },
  //       body: JSON.stringify({}),
  //     }
  //   );
  //   let res = await response.json();
  //   console.log(1111, res);
  //   setListaGeneri(res);
  // }

  async function query() {
    const res = await monFetch("/roleList", {});
    // console.log(res);
    setRuoli(res.role);
  }

  async function eliminaRuolo(id) {
    const res = await monFetch("/roleDelete", {id});
    // console.log(res);
    setPopupElimina(false);
    query();
  }

  useEffect(() => {
    props.setElementiBarraTop(<h2>Ruoli</h2>);
    query();
  }, []);

  return (
    <>
      <Button variant="contained" href="/ruolo-nuovo">
        Nuovo{" "}
      </Button>
      
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Descrizione</TableCell>
            <TableCell>Chiave</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ruoli.map((ruolo) => {
            return (
              <TableRow
                key={ruolo.id}
                className="rigaSelezionabile"
                onClick={(e) => {
                  window.location.href = "/ruolo/" + ruolo.id;
                }}
              >
                <TableCell>{ruolo.name}</TableCell>
                <TableCell>{ruolo.description}</TableCell>
                <TableCell>{ruolo.roleKey}</TableCell>
                <TableCell>
                  <DeleteIcon
                    color="error"
                    onClick={(e) => {
                      e.stopPropagation();
                      setPopupElimina(true);
                      setRuoloDaEliminare(ruolo.id)
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Popup open={popupElimina} fnAnnulla={(e)=>setPopupElimina(false)} 
      fnSuccess={(e)=>eliminaRuolo(ruoloDaEliminare)}
      />
    </>
  );
};

const Popup = (props) => {
  return (
    <Dialog open={props.open} onClose={props.fnAnnulla}>
      <DialogTitle>Conferma Eliminazione</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Confermi l'eliminazione dell'elemento?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Annulla</Button>
        <Button onClick={props.fnSuccess} variant="contained">
          Elimina
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Comp;
