import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { monFetch } from "../../inc/inc";

import { useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

import "react-image-crop/dist/ReactCrop.css";

const Comp = (props) => {
  let params = useParams();
  let isModifica = false;

  let id = params.id;
  const [nome, setNome] = useState("");
  const [descrizione, setDescrizione] = useState("");
 

  const [errore, setErrore] = useState("");

  async function query() {
    const res = await monFetch("/faqCategoryGet", {
      id,
    });

    setDescrizione(res.res.description);
    setNome(res.res.name);
  }

  async function salva(e) {
    e.preventDefault();
    const res = await monFetch("/faqCategorySet", {
      id,
      name: nome,
      description: descrizione
    });

    if (res.success) {
      window.location.href = "/faqcategorie";
    } else {
      setErrore("Errore nel salvataggio dei dati");
    }
  }

  useEffect(() => {
    if (id) {
      query();
    }
    let barraTop = (
      <h2>{!id ? "Nuova Categoria Faq" : "Modifica Categoria Faq"}</h2>
    );
    props.setElementiBarraTop(barraTop);
  }, []);

  return (
    <form onSubmit={(e) => salva(e)}>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          {errore && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {errore}
            </Alert>
          )}
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextField
            required
            label="Nome"
            fullWidth
            variant="outlined"
            className="inputCustom"
            value={nome}
            // disabled={id ? true : false}
            onChange={(e) => {
              setNome(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={8} xs={12}>
          <TextField
            label="Descrizione"
            fullWidth
            variant="outlined"
            className="inputCustom"
            value={descrizione}
            // disabled={id ? true : false}
            onChange={(e) => {
              setDescrizione(e.target.value);
            }}
          />
        </Grid>

        <Grid item sm={12} xs={12}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              className="inputCustom"
              variant="contained"
              href="/faqcategorie"
            >
              Indietro
            </Button>

            <Button
              className="inputCustom bottoneSalva"
              type="submit"
              variant="contained"
            >
              Salva
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

export default Comp;
