import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {
  convertiData,
  monFetch,
  checkRuoloUtente,
  settaCookieFiltri,
  getValoreCookieFiltri,
  checkAutista,
  convertiDataHM,
} from "../inc/inc";
import Cookies from "js-cookie";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import CalculateIcon from "@mui/icons-material/Calculate";
import { Checkbox } from "@mui/material";
import Testo from "./componenti_generici/Testo";

let timeoutFiltri = 0;

const Comp = (props) => {
  let location = useLocation();

  const [preventivi, setPreventivi] = useState([]);

  const [popupElimina, setPopupElimina] = useState(false);
  const [popupAssegnaGruppo, setPopupAssegnaGruppo] = useState(false);
  const [popupConferma, setPopupConferma] = useState(false);
  const [preventivoDaResettare, setPreventivoDaResettare] = useState(null);
  const [userToAssign, setUserToAssign] = useState(null);
  const [filtriCaricati, setFiltriCaricati] = useState(false);
  const [preventivoDaEliminare, setPreventivoDaEliminare] = useState(null);

  const [dataDa, setDataDa] = useState("");
  const [anno, setAnno] = useState("");
  const [dataA, setDataA] = useState("");
  const [dataScad, setDataScad] = useState("");
  const [customer_id, setCustomerId] = useState(null);
  const [testoSelectClienti, setTestoSelectClienti] = useState("");
  const [userId, setUserId] = useState("");
  const [userIdConferma, setUserIdConferma] = useState("");
  const [statusId, setStatusId] = useState("");
  const [istitutoId, setIstitutoId] = useState(null);
  const [codice, setCodice] = useState("");
  const [destinazione, setDestinazione] = useState("");
  const [mezzo, setMezzo] = useState(null);
  const [tipoViaggio, setTipoViaggio] = useState(null);
  const [tipoGruppo, setTipoGruppo] = useState(null);

  const [optionsUtente, setOptionsUtente] = useState([]);
  const [optionsAnni, setOptionsAnni] = useState([]);
  const [optionsStatus, setOptionsStatus] = useState([]);
  const [optionsIstituto, setOptionsIstituto] = useState([]);
  const [optionsMezzo, setOptionsMezzo] = useState([]);
  const [optionsTipoViaggio, setOptionsTipoViaggio] = useState([]);
  const [optionsTipoGruppo, setOptionsTipoGruppo] = useState([]);

  const [loading, setLoading] = useState(false);

  const [ordinaPer, setOrdinaPer] = useState(null);
  const [direzione, setDirezione] = useState(null);

  const [isAutista, setIsAutista] = useState(false);
  const [selectedList, setSelectedList] = useState([]);

  const optionsOrdinamento = [
    { label: "Data inserimento", value: "id" },
    { label: "Data scadenza", value: "date_delivery" },
    { label: "Codice", value: "code" },
  ];

  const direzioniOrdinamento = [
    { label: "Discendente", value: "desc" },
    { label: "Ascendente", value: "asc" },
  ];

  function toggleSelectedList(id) {
    var sl = [...selectedList];
    var index = sl.indexOf(id);

    if (index === -1) {
      sl.push(id);
    } else {
      sl.splice(index, 1);
    }
    setSelectedList(sl);
  }

  async function queryClienti(value = "", id = "") {
    const res = await monFetch("/customerList", {
      active: true,
      search: value,
      id,
    });

    const istituti = [];
    res.customer.forEach((el) => {
      let nome;
      if (el.first_name) {
        nome =
          el.first_name + " " + el.last_name + " - " + el.city + " - " + el.id;
      } else {
        nome = el.companyName + " - " + el.city + " - " + el.id;
      }
      istituti.push({ label: nome, value: el.id });
    });
    //cerca se il testo è una opzione esatta
    let testoEsatto = false;
    optionsIstituto.forEach((e) => {
      if (e.label == value) {
        testoEsatto = true;
      }
    });
    if (!testoEsatto) {
      setOptionsIstituto(istituti);
    }
  }

  async function queryMezziTrasporto() {
    const res = await monFetch("/transportList", {});
    const trasporti = [];
    res.transport.forEach((el) => {
      trasporti.push({ label: el.name, value: el.id });
    });
    setOptionsMezzo(trasporti);
  }

  async function queryTipiViaggio() {
    if (!tipoGruppo) {
      // setTipoViaggio(null);
      setOptionsTipoViaggio([]);
      return;
    }
    ///tipi viaggio
    let res = await monFetch("/tripTypeList", {
      group_type_id: tipoGruppo,
    });
    const trip_type = [];
    res.trip_type.forEach((el) => {
      trip_type.push({
        label: el.name,
        value: el.id,
      });
    });

    setOptionsTipoViaggio(trip_type);
  }

  async function queryTipiGruppo() {
    ///tipi gruppo
    let res = await monFetch("/groupTypeList", {});
    const temp = [];
    res.res.forEach((el) => {
      temp.push({ label: el.group_type, value: el.id });
    });
    setOptionsTipoGruppo(temp);
  }

  async function queryUtenti() {
    const res = await monFetch("/userListFull", {});
    const utenti = [];
    res.users.forEach((el) => {
      utenti.push({
        label: el.first_name + " " + el.last_name,
        // + " - " + el.email
        value: el.id,
      });
    });
    setOptionsUtente(utenti);
  }

  async function queryAnni() {
    const res = await monFetch("/listAnni", {});
    let temp = [];
    res.res.forEach((el, index) => {
      if (index == 0) {
        setAnno(el.year);
      }
      temp.push({
        label: el.year,
        value: el.year,
      });
    });
    setOptionsAnni(temp);
  }

  async function impostaDaRealizzare(idPrev) {
    const res = await monFetch("/impostaDaRealizzare", { idPrev });

    query({
      date_start: dataDa,
      date_end: dataA,
      customer_id: customer_id,
      user_id: userId,
      user_id_conferma: userIdConferma,
      date_delivery: dataScad,
      status_id: statusId,
      code: codice,
      destination: destinazione,
      transport: mezzo,
      trip_type: tipoGruppo ? tipoViaggio : null,
      anno,
      group_type: tipoGruppo,
    });
    setPopupConferma(false);
  }

  async function queryStatus() {
    const res = await monFetch("/statusList", {});
    const status = [];
    res.status.forEach((el) => {
      status.push({
        label: el.name,
        value: el.id,
      });
    });
    setOptionsStatus(status);
  }

  async function importaClienti() {
    setLoading(true);
    const res = await monFetch("/customerImport", {});
    console.log(res);
    setLoading(false);
  }

  async function query(data = {}) {
    data.ordinamento = ordinaPer;
    data.direzione = direzione;
    data.autistaBus = Cookies.get("autistaId");
    const res = await monFetch("/quoteList", data);
    setPreventivi(res.quote);
  }

  async function eliminaPreventivo(id) {
    const res = await monFetch("/quoteDelete", { id });
    setPopupElimina(false);
    query({
      date_start: dataDa,
      date_end: dataA,
      customer_id: customer_id,
      user_id: userId,
      user_id_conferma: userIdConferma,
      date_delivery: dataScad,
      status_id: statusId,
      code: codice,
      destination: destinazione,
      transport: mezzo,
      trip_type: tipoGruppo ? tipoViaggio : null,
      anno,
      group_type: tipoGruppo,
    });
  }

  async function assegnaGruppo() {
    const res = await monFetch("/quoteAssignGroup", {
      userToAssign,
      selectedList,
    });
    setPopupAssegnaGruppo(false);
    setSelectedList([]);
    query({
      date_start: dataDa,
      date_end: dataA,
      customer_id: customer_id,
      user_id: userId,
      user_id_conferma: userIdConferma,
      date_delivery: dataScad,
      status_id: statusId,
      code: codice,
      destination: destinazione,
      transport: mezzo,
      trip_type: tipoGruppo ? tipoViaggio : null,
      anno,
      group_type: tipoGruppo,
    });
  }

  useEffect(
    () => {
      let barraTop = (
        <>
          <h2>
            <a href="/preventivi" style={{ color: "#fff" }}>
              Preventivi
            </a>
          </h2>
          {/* <div
          className="legenda"
          style={{
            display: "flex",
            maxWidth: 450,
            flexWrap: "wrap",
          }}
        >
          {tipiViaggio.map((el) => {
            return (
              <div style={{ display: "flex", width: "33%" }} key={el.id}>
                <div
                  style={{
                    backgroundColor: el.color,
                    height: 13,
                    width: 13,
                    marginRight: 5,
                    marginLeft: 5,
                    marginBottom: 3,
                  }}
                ></div>
                <span style={{ fontSize: 10 }}>
                  {el.group_type_id == 1 ? "St: " : "Ad: "}
                  {el.name}
                </span>
              </div>
            );
          })}
        </div> */}
        </>
      );
      props.setElementiBarraTop(barraTop);
    },
    [
      // tipiViaggio
    ]
  );

  useEffect(() => {
    //check se sono loggato come autista

    setIsAutista(checkAutista());
    queryAnni();
    queryUtenti();
    queryStatus();

    queryTipiGruppo();
    queryMezziTrasporto();

    ///inizializzo i filtri con i dati del cookie
    setDataA(getValoreCookieFiltri("dataA") || "");
    setDataDa(getValoreCookieFiltri("dataDa") || "");
    setDataScad(getValoreCookieFiltri("dataScad") || "");
    setDestinazione(getValoreCookieFiltri("destinazione") || "");
    setCodice(getValoreCookieFiltri("codice") || "");
    setMezzo(getValoreCookieFiltri("mezzo") || null);
    setTipoViaggio(getValoreCookieFiltri("tipoViaggio") || null);
    setTipoGruppo(getValoreCookieFiltri("tipoGruppo") || null);
    setUserId(getValoreCookieFiltri("userId") || "");
    setStatusId(getValoreCookieFiltri("statusId") || "");
    setFiltriCaricati(true);
  }, []);

  useEffect(() => {
    queryTipiViaggio();
  }, [tipoGruppo]);

  useEffect(() => {
    clearTimeout(timeoutFiltri);
    timeoutFiltri = setTimeout(() => {
      if (filtriCaricati && ordinaPer && direzione) {
        query({
          date_start: dataDa,
          date_end: dataA,
          customer_id: customer_id,
          user_id: userId,
          user_id_conferma: userIdConferma,
          date_delivery: dataScad,
          status_id: statusId,
          code: codice,
          destination: destinazione,
          transport: mezzo,
          trip_type: tipoGruppo ? tipoViaggio : null,
          anno,
          group_type: tipoGruppo,
        });
      }
    }, 500);
  }, [
    dataA,
    dataDa,
    customer_id,
    userId,
    userIdConferma,
    dataScad,
    statusId,
    codice,
    destinazione,
    mezzo,
    tipoViaggio,
    tipoGruppo,
    filtriCaricati,
    ordinaPer,
    direzione,
    anno,
  ]);

  return (
    <>
      <div className="filtriFixed">
        <div
          style={{
            marginBottom: 20,
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div className="listaBottoni">
            {checkRuoloUtente("inserimento-preventivi") && (
              <>
                <Button variant="contained" href="/preventivo-nuovo">
                  Nuovo{" "}
                </Button>{" "}
                <Button variant="contained" onClick={importaClienti}>
                  Importa Clienti {loading == true && <CircularProgress />}
                </Button>
              </>
            )}
            {checkRuoloUtente("assegnazione-operatore") && (
              <>
                {" "}
                <Button
                  variant="contained"
                  color="info"
                  onClick={() => setPopupAssegnaGruppo(true)}
                >
                  Assegna selezionati
                </Button>
              </>
            )}
          </div>
          <div style={{ display: "flex" }} className="flexFiltri">
            {/* ordinamento per: */}
            <Autocomplete
              style={{ width: 200 }}
              value={
                optionsOrdinamento.find((op) => op.value == ordinaPer)
                  ? optionsOrdinamento.find((op) => op.value == ordinaPer)
                  : setOrdinaPer(optionsOrdinamento[0].value)
              }
              onChange={(e, value) => {
                setOrdinaPer(value?.value);
              }}
              options={optionsOrdinamento}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Ordina per"
                  size="small"
                  className="inputCustom"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
            {/* ordinamento direzione: */}
            <Autocomplete
              style={{ width: 200 }}
              value={
                direzioniOrdinamento.find((op) => op.value == direzione)
                  ? direzioniOrdinamento.find((op) => op.value == direzione)
                  : setDirezione(direzioniOrdinamento[0].value)
              }
              onChange={(e, value) => {
                setDirezione(value?.value);
              }}
              options={direzioniOrdinamento}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Direzione"
                  size="small"
                  className="inputCustom"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </div>
        </div>

        <div className="flexFiltri">
          <div>
            <Autocomplete
              value={
                optionsAnni.find((op) => op.value == anno)
                  ? optionsAnni.find((op) => op.value == anno)
                  : ""
              }
              disableClearable
              onChange={(e, value) => {
                if (!value) {
                  return;
                }
                setAnno(value.value);
              }}
              options={optionsAnni}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Anno"
                  size="small"
                  className="inputCustom"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </div>
          <div>
            <TextField
              label="Data Inizio"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              type="date"
              size="small"
              variant="outlined"
              className="inputCustom"
              value={dataDa}
              onChange={(e) => {
                settaCookieFiltri("dataDa", e.target.value);
                setDataDa(e.target.value);
              }}
            />
          </div>
          <div>
            <TextField
              label="Data Fine"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              type="date"
              variant="outlined"
              className="inputCustom"
              value={dataA}
              onChange={(e) => {
                settaCookieFiltri("dataA", e.target.value);
                setDataA(e.target.value);
              }}
            />
          </div>
          <div>
            <TextField
              label="Data Scadenza interna"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              type="date"
              size="small"
              variant="outlined"
              className="inputCustom"
              value={dataScad}
              onChange={(e) => {
                settaCookieFiltri("dataScad", e.target.value);
                setDataScad(e.target.value);
              }}
            />
          </div>
          <div>
            <TextField
              label="Codice Prev."
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              variant="outlined"
              className="inputCustom"
              value={codice}
              onChange={(e) => {
                settaCookieFiltri("codice", e.target.value);
                setCodice(e.target.value);
              }}
            />
          </div>
          <div>
            {/* //clienti */}
            <Autocomplete
              freeSolo
              filterOptions={(x) => x}
              value={
                optionsIstituto.find((op) => op.value == istitutoId) &&
                testoSelectClienti == 0
                  ? optionsIstituto.find((op) => op.value == istitutoId)
                  : testoSelectClienti
              }
              inputValue={testoSelectClienti}
              onInputChange={(e, value) => {
                setTestoSelectClienti(value);
                if (value.length >= 3) {
                  queryClienti(value);
                }
              }}
              onChange={(e, value) => {
                // console.log("change autoc", value);
                setIstitutoId(value?.value);
                settaCookieFiltri("istitutoId", e.target.value);
                setCustomerId(value?.value);
              }}
              options={optionsIstituto}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Cliente"
                  size="small"
                  className="inputCustom"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </div>

          <div>
            <TextField
              label="Destinazione"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              variant="outlined"
              className="inputCustom"
              value={destinazione}
              onChange={(e) => {
                settaCookieFiltri("destinazione", e.target.value);
                setDestinazione(e.target.value);
              }}
            />
          </div>
          {!isAutista && (
            <div>
              {/* //mezzo trasp */}
              <Autocomplete
                value={
                  optionsMezzo.find((op) => op.value == mezzo)
                    ? optionsMezzo.find((op) => op.value == mezzo)
                    : null
                }
                onChange={(e, value) => {
                  settaCookieFiltri("mezzo", value?.value);
                  setMezzo(value?.value);
                }}
                options={optionsMezzo}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Mezzo Trasp."
                    size="small"
                    className="inputCustom"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </div>
          )}
          <div>
            {/* //tipo viaggio */}
            <Autocomplete
              value={
                optionsTipoGruppo.find((op) => op.value == tipoGruppo)
                  ? optionsTipoGruppo.find((op) => op.value == tipoGruppo)
                  : null
              }
              onChange={(e, value) => {
                settaCookieFiltri("tipoGruppo", value?.value);
                setTipoGruppo(value?.value);
              }}
              options={optionsTipoGruppo}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tipo Gruppo"
                  size="small"
                  className="inputCustom"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </div>
          <div>
            {/* //tipo viaggio */}
            <Autocomplete
              value={
                optionsTipoViaggio.find((op) => op.value == tipoViaggio)
                  ? optionsTipoViaggio.find((op) => op.value == tipoViaggio)
                  : null
              }
              onChange={(e, value) => {
                settaCookieFiltri("tipoViaggio", value?.value);
                setTipoViaggio(value?.value);
              }}
              options={optionsTipoViaggio}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tipo di Viaggio"
                  size="small"
                  className="inputCustom"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </div>
          <div>
            {/* utenti */}
            <Autocomplete
              value={
                optionsUtente.length > 0 && userId !== ""
                  ? optionsUtente.find((op) => op.value == userId)
                  : null
              }
              onChange={(e, value) => {
                if (!value) {
                  setUserId("");
                  settaCookieFiltri("userId", "");
                  return;
                }
                settaCookieFiltri("userId", value?.value);
                setUserId(value?.value);
              }}
              options={optionsUtente}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Addetto Preventivo"
                  size="small"
                  className="inputCustom"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </div>
          <div>
            {/* utenti */}
            <Autocomplete
              value={
                optionsUtente.length > 0 && userIdConferma !== ""
                  ? optionsUtente.find((op) => op.value == userIdConferma)
                  : null
              }
              onChange={(e, value) => {
                if (!value) {
                  setUserIdConferma("");
                  settaCookieFiltri("userId", "");
                  return;
                }
                settaCookieFiltri("userId", value?.value);
                setUserIdConferma(value?.value);
              }}
              options={optionsUtente}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Addetto Conferma"
                  size="small"
                  className="inputCustom"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </div>
          <div>
            {/* stati */}
            <Autocomplete
              value={
                optionsStatus.length > 0 && statusId !== ""
                  ? optionsStatus.find((op) => op.value == statusId)
                  : null
              }
              onChange={(e, value) => {
                //console.log(value)
                if (!value) {
                  setStatusId("");
                  settaCookieFiltri("statusId", "");
                  return;
                }
                settaCookieFiltri("statusId", value?.value);
                setStatusId(value?.value);
              }}
              options={optionsStatus}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Stato"
                  size="small"
                  className="inputCustom"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </div>
        </div>
      </div>
      {preventivi.map((p) => {
        ///colori bg
        // let bgColor = { backgroundColor: "#eee" };

        // if (p.color) {
        //   bgColor = { backgroundColor: p.color };
        // }
        return (
          <div
            key={p.id}
            className="rigaPrev"
            style={{
              borderBottom: "2px solid #2656ea ",
              background: p.status_color,
              borderRadius: "0px",
              display: "flex",
              gap: 15,
            }}
            onClick={() => {
              window.location.href = "/preventivo/" + p.id;
            }}
          >
            <div>
              <Grid container spacing={1}>
                <Grid item lg={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <b
                      style={{
                        verticalAlign: "top",
                        display: "inline-block",
                        fontSize: "1.1em",
                        border: "none",
                        marginLeft: 0,
                        marginRight: 15,
                        marginBottom: 10,
                        cursor: "auto",
                      }}
                      className="numeroPreventivo"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {p.code}
                    </b>
                    {/* tipo viaggio */}
                    <div style={{ textAlign: "center", flexGrow: 1 }}>
                      <b
                        style={{
                          verticalAlign: "top",
                          display: "inline-block",
                          fontSize: "1em",
                          border: "none",
                          marginLeft: -40,
                          fontWeight: 500,
                          // marginRight: 15,
                          cursor: "auto",
                          backgroundColor: "#2656ea",
                          padding: "2px 10px",
                          minWidth: "200px",
                          textTransform: "uppercase",
                        }}
                        className="numeroPreventivo"
                        // onClick={(e) => {
                        //   e.stopPropagation();
                        // }}
                      >
                        {p.tripName}
                      </b>
                    </div>
                  </div>{" "}
                </Grid>
                <Grid item lg={4}>
                  <div
                    style={{
                      textWrap: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",

                      marginBottom: 10,
                    }}
                  >
                    <label>
                      <i>Cliente:</i>
                    </label>{" "}
                    <b>
                      {p.companyName
                        ? p.companyName + " - " + p.city
                        : p.cliNome + " " + p.cliCognome}
                    </b>
                  </div>
                </Grid>

                <Grid item lg={3}>
                  <label>
                    <i>Destinazione:</i>
                  </label>{" "}
                  <b>
                    <u>{p.destination}</u>
                  </b>
                </Grid>

                <Grid item lg={3}>
                  <label>
                    <i>Dal:</i>
                  </label>{" "}
                  <b>{convertiData(p.start_date)}</b>
                  <label style={{ marginLeft: 15 }}>
                    <i>Al:</i>
                  </label>{" "}
                  <b>{convertiData(p.end_date)}</b>
                  <label style={{ marginLeft: 15 }}>
                    <i>Giorni:</i>
                  </label>{" "}
                  <b>{p.days}</b>
                </Grid>

                <Grid item lg={2}>
                  <div style={{ display: "flex", gap: 8 }}>
                    <Checkbox
                      style={{ padding: 0 }}
                      checked={selectedList.includes(p.id)}
                      onChange={(e) => {
                        toggleSelectedList(p.id);
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                    <div>
                      <label>
                        <i>Addetto prev: </i>
                      </label>
                      <b>{(p.first_name || "") + " " + (p.last_name || "")}</b>
                    </div>
                  </div>
                  {p.first_nameC && (
                    <div style={{ display: "flex", gap: 8 }}>
                      <label>
                        <i>Addetto conferma </i>
                      </label>
                      <b>
                        {(p.first_nameC || "") + " " + (p.last_nameC || "")}
                      </b>
                    </div>
                  )}
                </Grid>

                <Grid
                  item
                  lg={3}
                  style={{
                    backgroundColor: isAutista ? "#f7bd00" : "transparent",
                  }}
                >
                  <label>
                    <i>Data Consegna Int.:</i>
                  </label>{" "}
                  <b>{convertiDataHM(p.date_delivery)}</b>
                </Grid>

                <Grid item lg={2}>
                  {!isAutista && (
                    <>
                      <label>
                        <i>Doc. amministrativa:</i>
                      </label>{" "}
                      <b>{p.admin_doc_state}</b>
                    </>
                  )}
                </Grid>

                <Grid item lg={2}>
                  {!isAutista && (
                    <span
                      style={
                        {
                          // display: "inline-block",
                          // padding: "5px",
                          // backgroundColor: p.status_color,
                          // borderRadius: "3px",
                        }
                      }
                      onClick={(e) => {
                        if (p.status_id == 99 || p.status_id == 30) {
                          //99 = non presentiamo
                          //30 = inviato

                          e.stopPropagation();
                          setPopupConferma(true);
                          setPreventivoDaResettare(p.id);
                        }
                      }}
                    >
                      <label>
                        <i>Stato:</i>
                      </label>{" "}
                      {p.status_id != 50 ? (
                        <b
                          className="numeroPreventivo"
                          style={{
                            fontWeight: 500,
                            border: "none",
                            fontSize: "0.8em",
                            padding: "2px 10px",
                            backgroundColor: p.label_color || "#ccc",
                            display: "inline-block",
                          }}
                        >
                          {p.status_name.toUpperCase()}
                        </b> ///se controllato
                      ) : (
                        <b style={{ marginLeft: 15 }}>
                          {p.status_name.toUpperCase()}
                        </b>
                      )}
                    </span>
                  )}
                </Grid>

                <Grid item lg={3}>
                  {!isAutista && p.hotel_rec != 4 && (
                    <label style={{ verticalAlign: "middle" }}>
                      <span className="boxColoratoLabel">Hotel</span>
                      <span
                        className="boxColoratoLista"
                        style={{ backgroundColor: p.hotelC }}
                      ></span>
                    </label>
                  )}
                  {p.bus_rec != 4 && (
                    <label>
                      <span className="boxColoratoLabel">Bus</span>
                      <span
                        className="boxColoratoLista"
                        style={{ backgroundColor: p.busC }}
                      ></span>
                    </label>
                  )}
                  {!isAutista && p.transfer_rec != 4 && (
                    <label>
                      <span className="boxColoratoLabel">Transfer</span>
                      <span
                        className="boxColoratoLista"
                        style={{ backgroundColor: p.transferC }}
                      ></span>
                    </label>
                  )}
                </Grid>

                <Grid item lg={2}>
                  <label>
                    <i>Mezzo di trasporto:</i>
                  </label>{" "}
                  <b>{p.transport}</b>
                </Grid>

                <Grid item lg={10}></Grid>
                {!isAutista && (
                  <Grid
                    item
                    lg={2}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <div
                      style={{
                        // paddingLeft: "42px",
                        display: "flex",
                        gap: 7,
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          // paddingLeft: "42px",
                          display: "flex",
                          gap: 7,
                          flexWrap: "wrap",
                        }}
                      >
                        <Button
                          variant="contained"
                          style={{
                            whiteSpace: "nowrap",
                            minWidth: "12px",
                            maxWidth: "12px",
                          }}
                          href={"/preventivo/media/" + p.id}
                        >
                          <AttachFileIcon style={{ height: "20px" }} />
                        </Button>
                        {p.status_id >= 1 && p.status_id < 99 ? (
                          /////se assegnato

                          <Button
                            variant="contained"
                            style={{
                              whiteSpace: "nowrap",
                              minWidth: "16px",
                              maxWidth: "16px",
                            }}
                            href={"/calcoloPreventivo/" + p.id}
                          >
                            <CalculateIcon style={{ height: "20px" }} />
                          </Button>
                        ) : (
                          ""
                        )}
                        {checkRuoloUtente("inserimento-preventivi") && (
                          <Button
                            variant="contained"
                            style={{
                              whiteSpace: "nowrap",
                              minWidth: "16px",
                              maxWidth: "16px",
                            }}
                            className="bottoneCopia"
                            onClick={(e) => {
                              e.stopPropagation();
                              window.location.href =
                                "/preventivo/" + p.id + "?clona=1";
                            }}
                            title="Duplica"
                          >
                            <ContentCopyIcon
                              title="Duplica"
                              style={{ height: "20px" }}
                            />
                          </Button>
                        )}
                      </div>
                      <div>
                        <Button
                          variant="contained"
                          color="error"
                          style={{
                            whiteSpace: "nowrap",
                            minWidth: "16px",
                            maxWidth: "16px",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setPopupElimina(true);
                            setPreventivoDaEliminare(p.id);
                          }}
                          title="Elimina"
                        >
                          <DeleteIcon style={{ height: "20px" }} />
                        </Button>
                      </div>
                    </div>
                  </Grid>
                )}
              </Grid>
            </div>
            {/* <div style={{ display: "flex", alignItems: "center", gap: 15 }}> */}
            {/* <div
                className="iconaLista"
                title={p.tripName}
                style={{ backgroundColor: p.label_color }}
              >
                <img src={"img/" + p.icon} />
              </div> */}

            {/* </div> */}
          </div>
        );
      })}

      <Popup
        open={popupElimina}
        fnAnnulla={(e) => setPopupElimina(false)}
        fnSuccess={(e) => eliminaPreventivo(preventivoDaEliminare)}
      />
      <PopupAssegnazione
        open={popupAssegnaGruppo}
        fnAnnulla={(e) => setPopupAssegnaGruppo(false)}
        fnSuccess={(e) => assegnaGruppo()}
        opzioni={optionsUtente}
        fnSetUserToAssign={(e) => setUserToAssign(e)}
      />
      <PopupConferma
        open={popupConferma}
        fnAnnulla={(e) => setPopupConferma(false)}
        fnSuccess={(e) => impostaDaRealizzare(preventivoDaResettare)}
      />
    </>
  );
};

const PopupAssegnazione = (props) => {
  return (
    <Dialog open={props.open} onClose={props.fnAnnulla}>
      <DialogTitle>Assegnazione utente</DialogTitle>
      <DialogContent>
        <DialogContentText>
          A quale utente vuoi assegnare i preventivi selezionati?
        </DialogContentText>
        <Autocomplete
          onChange={(e, value) => {
            if (!value) {
              props.fnSetUserToAssign(null);
              return;
            }
            props.fnSetUserToAssign(value?.value);
          }}
          options={props.opzioni}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Addetto preventivo"
              size="small"
              className="inputCustom"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Annulla</Button>
        <Button onClick={props.fnSuccess} variant="contained">
          Salva
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Popup = (props) => {
  return (
    <Dialog open={props.open} onClose={props.fnAnnulla}>
      <DialogTitle>Conferma Eliminazione</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Confermi l'eliminazione dell'elemento?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Annulla</Button>
        <Button onClick={props.fnSuccess} variant="contained">
          Elimina
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PopupConferma = (props) => {
  return (
    <Dialog open={props.open} onClose={props.fnAnnulla}>
      <DialogTitle>Imposta come "da realizzare"</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Vuoi assegnare lo stato "da realizzare" a questo preventivo?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Annulla</Button>
        <Button onClick={props.fnSuccess} variant="contained">
          Conferma
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Comp;
