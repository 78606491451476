import * as React from "react";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Cookies from "js-cookie";
import Alert from "@mui/material/Alert";
import Input from "@mui/material/Input";
import { flexbox } from "@mui/system";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DragHandleIcon from "@mui/icons-material/DragHandle";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/Email";
import HttpsIcon from "@mui/icons-material/Https";
import { monFetch } from "../inc/inc";

export default function Comp(props) {
  const [password, setPassword] = useState("");
  const [confermaPassword, setConfermaPassword] = useState("");
  const [errore, setErrore] = useState(false);
  const [mexErrore, setMexErrore] = useState(null);
  const [mexOk, setMexOk] = useState(null);

  const [bkgImg, setBkgImg] = useState("");

  const [id, setId] = useState("");
  const [token, setToken] = useState("");

  async function reimpostaPassword(e, id) {
    e.preventDefault();
    // console.log(id, password, confermaPassword, token);
    const res = await monFetch("/password-reset", {
      password,
      password_confirm: confermaPassword,
      id: id,
      t: token,
    });
    // console.log(res);

    if (!res.success) {
      setErrore(true);
      setMexOk(false);
      setMexErrore(res.msg);
    } else if (res.success) {
      setErrore(false);
      setMexOk(res.msg);
      setTimeout(() => {
        window.location.replace("/login");
      }, 8000);
    }
  }

  async function loadBkg() {
    let res = await monFetch("/utilityBackground", {});
    setBkgImg('url("' + res.image+'")');
    // console.log(res);
  }

  useEffect(() => {
    loadBkg();
    setToken(new URLSearchParams(location.search).get("t"));
    setId(new URLSearchParams(location.search).get("id"));
  }, []);

  return (
    <div style={{ minHeight: "100vh", display: "flex", alignItems: "center", justifyContent:"left", backgroundImage: bkgImg, backgroundSize: "cover", backgroundRepeat: "none" }}>
      <div className="boxlogin">
        <a href="/login">
          <img src="/img/logo_login.png" />
        </a>
        <form onSubmit={(e) => reimpostaPassword(e, id)}>
          <Grid container spacing={2}>
            {!mexOk && (
              <Grid item xs={12}>
                <h2 style={{ marginTop: 0 }}>Reimposta Password</h2>
                <div className="inputImmagine">
                  <HttpsIcon sx={{ ml: 2 }} />
                  <Input
                    type="password"
                    className="password"
                    placeholder="Nuova Password"
                    variant="filled"
                    value={password}
                    required
                    fullWidth
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="inputImmagine">
                  <HttpsIcon sx={{ ml: 2 }} />
                  <Input
                    type="password"
                    className="password"
                    placeholder="Conferma Password"
                    variant="filled"
                    value={confermaPassword}
                    required
                    fullWidth
                    onChange={(e) => setConfermaPassword(e.target.value)}
                  />
                </div>
              </Grid>
            )}

            <Grid item xs={12}>
              {errore && (
                <>
                  <br />
                  <Alert severity="error" sx={{ mb: 2 }}>
                    <span
                      dangerouslySetInnerHTML={{ __html: mexErrore }}
                    ></span>
                  </Alert>
                </>
              )}
              {mexOk && (
                <>
                  <br />
                  <Alert severity="success" sx={{ mb: 2 }}>
                    <span dangerouslySetInnerHTML={{ __html: mexOk }}></span>
                  </Alert>
                </>
              )}
              {!mexOk && (
                <Button variant="contained" type="submit" fullWidth>
                  Reimposta Password
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <p style={{ textAlign: "center" }}>
                <a href="/login">
                  <AccountCircleIcon
                    style={{ verticalAlign: "middle", marginRight: "5px" }}
                  />
                  <span style={{ verticalAlign: "middle", "color": "#fff" }}>
                    Torna al login
                  </span>
                </a>
              </p>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}
