import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import LibriAggiorna from "./LibriAggiorna";
// import PrenotaLibro from "./PrenotaLibro";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Pallino, convertiData, controllaLogin, monFetch } from "../inc/inc";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const Comp = (props) => {
  //const ruoloLogin = controllaLogin();

  let location = useLocation();

  const [fornBus, setFornBus] = useState([]);

  const [popupElimina, setPopupElimina] = useState(false);
  const [fornBusDaEliminare, setFornBusDaEliminare] = useState(null);


  async function query() {
    const res = await monFetch("/busProviderList", {});
    // console.log(res);
    setFornBus(res.res);
  }

  async function eliminaFornBus(id) {
    const res = await monFetch("/busProviderDelete", {id});
    // console.log(res);
    setPopupElimina(false);
    query();
  }

  useEffect(() => {
    query();
    let barraTop = (
      <>
        <h2>Fornitori Bus</h2>
      </>
    );
    props.setElementiBarraTop(barraTop);
  }, []);

  return (
    <>
      <Button variant="contained" href="/fornitori-bus/nuovo">
        Nuovo{" "}
      </Button>
      <Table>
        <TableHead>
          <TableRow>
          {/* <TableCell>Azienda</TableCell> */}
            <TableCell>Nome</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Attivo</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fornBus.map((fornBus) => {
            return (
              <TableRow
                key={fornBus.id}
                className="rigaSelezionabile"
                onClick={(e) => {
                  window.location.href = "/fornitori-bus/" + fornBus.id;
                }}
              >
                {/* <TableCell>{fornBus.company_name}</TableCell> */}
                <TableCell>{fornBus.name}</TableCell>
                <TableCell>{fornBus.email}</TableCell>
                <TableCell>{fornBus.active ? "SI" : "NO"}</TableCell>
                <TableCell>
                  <DeleteIcon
                    color="error"
                    onClick={(e) => {
                      e.stopPropagation();
                      setPopupElimina(true);
                      setFornBusDaEliminare(fornBus.id)
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Popup open={popupElimina} fnAnnulla={(e)=>setPopupElimina(false)} 
      fnSuccess={(e)=>eliminaFornBus(fornBusDaEliminare)}
      />
    </>
  );
};

const Popup = (props) => {
  return (
    <Dialog open={props.open} onClose={props.fnAnnulla}>
      <DialogTitle>Conferma Eliminazione</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Confermi l'eliminazione dell'elemento?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Annulla</Button>
        <Button onClick={props.fnSuccess} variant="contained">
          Elimina
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Comp;
