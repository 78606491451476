import TextField from "@mui/material/TextField";
// import { monFetch } from "../inc/inc";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import Input from "@mui/material/Input";
import Autocomplete from "@mui/material/Autocomplete";
// import { useLocation, useParams } from "react-router-dom";

const Comp = (props) => {
  let settaDati = props.settaDati;
  let dati = props.dati;
  let arr = props.arr;
  let index = props.index;

  const arrotonda = (valore) => {
    return Math.round(valore * 100) / 100;
  };

  const [pesoBagManoAndata, setPesoBagManoAndata] = useState(0);
  const [pesoBagManoRitorno, setPesoBagManoRitorno] = useState(0);

  const [pesoBagManoInStivaAndata, setPesoBagManoInStivaAndata] = useState(0);
  const [pesoBagManoInStivaRitorno, setPesoBagManoInStivaRitorno] = useState(0);

  const [pesoBagStivaAndata, setPesoBagStivaAndata] = useState(0);
  const [pesoBagStivaRitorno, setPesoBagStivaRitorno] = useState(0);

  // const [timeoutId, setTimeoutId] = useState(null);

  //calcolo totale
  let totaleN =
    Number(dati.costoPaxR || 0) +
    Number(dati.costoPaxA || 0) +
    Number(dati.bagManoTotale || 0) +
    Number(dati.bagManoInStivaTotale || 0) +
    Number(dati.bagStivaTotale || 0);

  // console.log(totaleN);
  //   dati.notti *
  //   ((props.totalePass - Math.floor(props.totalePass / dati.gratutita)) *
  //     dati.costoNotte +
  //     Number(props.totaleInSingola * dati.suppSingola) +
  //     Number(props.totaleInDoppia * dati.suppDoppia));

  let totaleAPagante =
    totaleN *
    ((Number(props.totaleNonPaganti) + Number(props.totalePaganti)) /
      Number(props.totalePaganti));

  totaleAPagante = arrotonda(totaleAPagante);

  let totaleBagMano =
    Number(dati.bagManoPartenza || 0) + Number(dati.bagManoArrivo || 0);
  let totaleBagManoInStiva =
    Number(dati.bagManoInStivaPartenza || 0) +
    Number(dati.bagManoInStivaArrivo || 0);
  let totaleBagStiva =
    Number(dati.bagStivaPartenza || 0) + Number(dati.bagStivaArrivo || 0);

  async function calcolaPesoBagagli(value, ritorno = false) {
    let oggi = new Date().toISOString().split("T")[0];
    //// cerco se la compagnia è memorizzata e calcolo il peso dei bagagli da mostrare
    let vettore = props.vettoriAereo.find((el) => {
      return (
        //controllo valore e data validità
        el.label == value &&
        (el.valid_from <= oggi || !el.valid_from) &&
        (el.valid_to >= oggi || !el.valid_to)
      );
    });
    if (vettore) {
      if (!ritorno) {
        setPesoBagManoAndata(vettore.cabin || 0);
        setPesoBagManoInStivaAndata(vettore.cabin_stiva || 0);
        setPesoBagStivaAndata(vettore.luggage || 0);
      } else {
        setPesoBagManoRitorno(vettore.cabin || 0);
        setPesoBagManoInStivaRitorno(vettore.cabin_stiva || 0);
        setPesoBagStivaRitorno(vettore.luggage || 0);
      }
    } else {
      setPesoBagManoAndata(0);
      setPesoBagManoInStivaAndata(0);
      setPesoBagStivaAndata(0);
      setPesoBagManoRitorno(0);
      setPesoBagManoInStivaRitorno(0);
      setPesoBagStivaRitorno(0);
    }
    // console.log(vettore, props.vettoriAereo, value);
  }

  useEffect(() => {
    // if (
    //   !Number(totaleBagStiva) &&
    //   !Number(totaleBagMano) &&
    //   !Number(totaleAPagante)
    // ) {
    //   console.log("NO TRIGGER");
    //   return;
    // }
    // console.log("SI TRIGGER", props.idProp);
    let timeout = setTimeout((el) => {
      // settaDati(arr, "totaleAPagante", index, totaleAPagante);
      // settaDati(arr, "bagManoTotale", index, totaleBagMano);
      // settaDati(arr, "bagStivaTotale", index, totaleBagStiva);

      settaDati(
        arr,
        {
          bagStivaTotale: totaleBagStiva,
          bagManoTotale: totaleBagMano,
          bagManoInStivaTotale: totaleBagManoInStiva,
          totaleAPagante: totaleAPagante,
        },
        index
      );
    }, 50);

    return () => clearTimeout(timeout);
  }, [totaleAPagante, totaleBagMano, totaleBagManoInStiva, totaleBagStiva, props.idProp]);

  useEffect(() => {
    calcolaPesoBagagli(dati.vettoreA);
    calcolaPesoBagagli(dati.vettoreR, true);
  }, [dati.vettoreA, dati.vettoreR, props.vettoriAereo]);

  return (
    <>
      <Grid item xs={6} className="alignCenter">
        <label className="labelGruppo noBorder">Proposta {index + 1}</label>
      </Grid>
      <Grid item xs={3}>
        <TextField
          size="small"
          label="Quota a pagante"
          disabled
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom totalePaganti euro"
          value={dati.totaleAPagante || ""}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          size="small"
          label="Quota totale"
          disabled
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom totaleComplessivo euro"
          value={arrotonda((dati.totaleAPagante || 0) * props.totalePaganti)}
        />
      </Grid>
      <Grid item sm={12} xs={12} className="hideOnPrint">
        <div className="labelGruppoCont"></div>
      </Grid>
      <Grid item xs={3}>
        <div className="andataRitornoTesto">Andata</div>
      </Grid>
      <Grid item xs={3}>
        {/* vettore andata */}
        <Autocomplete
          freeSolo
          value={dati.vettoreA || ""}
          onInputChange={(e, value) => {
            settaDati(arr, { vettoreA: value }, index);
            calcolaPesoBagagli(value);
          }}
          options={props.vettoriAereo || []}
          disableClearable
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              label="Vettore"
              className="inputCustom"
            />
          )}
        />
        {/* <TextField
          size="small"
          label="Vettore"
          type="text"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom"
          value={dati.vettoreA || ""}
          onChange={(e) => {
            settaDati(arr, { vettoreA: e.target.value }, index);
          }}
        /> */}
      </Grid>
      <Grid item xs={3}>
        <TextField
          size="small"
          label="Loc. Partenza"
          type="text"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom"
          value={dati.locPartenza || ""}
          onChange={(e) => {
            settaDati(arr, { locPartenza: e.target.value }, index);
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          size="small"
          label="Loc. Arrivo"
          type="text"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom"
          value={dati.locPartenzaEnd || ""}
          onChange={(e) => {
            settaDati(arr, { locPartenzaEnd: e.target.value }, index);
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          size="small"
          label="N. volo"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom"
          value={dati.nrPartenza || ""}
          onChange={(e) => {
            settaDati(arr, { nrPartenza: e.target.value }, index);
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          size="small"
          label="Orario Partenza"
          type="time"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom"
          value={dati.partenzaA || ""}
          onChange={(e) => {
            settaDati(arr, { partenzaA: e.target.value }, index);
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          size="small"
          label="Orario Arrivo"
          type="time"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom"
          value={dati.arrivoA || ""}
          onChange={(e) => {
            settaDati(arr, { arrivoA: e.target.value }, index);
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          size="small"
          label="€ / pax"
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          value={dati.costoPaxA || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            settaDati(arr, { costoPaxA: e.target.value }, index);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
      <Grid item xs={3}>
        <div className="andataRitornoTesto">Ritorno</div>
      </Grid>
      <Grid item xs={3}>
        {/* vettore ritorno */}
        <Autocomplete
          freeSolo
          value={dati.vettoreR || ""}
          onInputChange={(e, value) => {
            settaDati(arr, { vettoreR: value }, index);
            calcolaPesoBagagli(value, true);
          }}
          options={props.vettoriAereo || []}
          disableClearable
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              label="Vettore"
              className="inputCustom"
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          size="small"
          label="Loc. Partenza"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom"
          value={dati.locRitorno || ""}
          onChange={(e) => {
            settaDati(arr, { locRitorno: e.target.value }, index);
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          size="small"
          label="Loc. Arrivo"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom"
          value={dati.locRitornoEnd || ""}
          onChange={(e) => {
            settaDati(arr, { locRitornoEnd: e.target.value }, index);
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          size="small"
          label="N. volo"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom"
          value={dati.nrRitorno || ""}
          onChange={(e) => {
            settaDati(arr, { nrRitorno: e.target.value }, index);
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          size="small"
          label="Orario Partenza"
          type="time"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom"
          value={dati.partenzaR || ""}
          onChange={(e) => {
            settaDati(arr, { partenzaR: e.target.value }, index);
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          size="small"
          label="Orario Arrivo"
          type="time"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom"
          value={dati.arrivoR || ""}
          onChange={(e) => {
            settaDati(arr, { arrivoR: e.target.value }, index);
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          size="small"
          label="€ / pax"
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          value={dati.costoPaxR || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            settaDati(arr, { costoPaxR: e.target.value }, index);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
      {/* bag a mano ------------------------- */}
      <Grid item xs={12}>
        Bag. a Mano (
        {"A: " + pesoBagManoAndata + "kg | R: " + pesoBagManoRitorno + "kg"})
      </Grid>
      <Grid item xs={4} style={{ display: "flex" }}>
        <div>
          <span style={{ fontSize: 14 }}>Comp.</span>
          <input
            type="checkbox"
            checked={dati.compresoManoA ? true : false}
            onChange={(e) =>
              settaDati(
                arr,
                { compresoManoA: e.target.checked, bagManoPartenza: "" },
                index
              )
            }
          />
        </div>
        <TextField
          size="small"
          label="Andata"
          type="number"
          fullWidth
          disabled={dati.compresoManoA ? true : false}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          value={dati.bagManoPartenza || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            settaDati(arr, { bagManoPartenza: e.target.value }, index);
          }}
        />
      </Grid>
      <Grid item xs={4} style={{ display: "flex" }}>
        <div>
          <span style={{ fontSize: 14 }}>Comp.</span>
          <input
            type="checkbox"
            checked={dati.compresoManoR ? true : false}
            onChange={(e) =>
              settaDati(
                arr,
                { compresoManoR: e.target.checked, bagManoArrivo: "" },
                index
              )
            }
          />
        </div>
        <TextField
          size="small"
          label="Ritorno"
          type="number"
          fullWidth
          disabled={dati.compresoManoR ? true : false}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          value={dati.bagManoArrivo || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            settaDati(arr, { bagManoArrivo: e.target.value }, index);
          }}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          size="small"
          label="€ / pax"
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          disabled
          variant="outlined"
          className="inputCustom euro"
          value={dati.bagManoTotale || ""}
          onWheel={(e) => e.target.blur()}
        />
      </Grid>

      {/* bag a mano in stiva------------------------- */}
      <Grid item xs={12}>
        Bag. in Stiva (
        {"A: " +
          pesoBagManoInStivaAndata +
          "kg | R: " +
          pesoBagManoInStivaRitorno +
          "kg"}
        )
      </Grid>
      <Grid item xs={4} style={{ display: "flex" }}>
        <div>
          <span style={{ fontSize: 14 }}>Comp.</span>
          <input
            type="checkbox"
            checked={dati.compresoManoInStivaA ? true : false}
            onChange={(e) =>
              settaDati(
                arr,
                {
                  compresoManoInStivaA: e.target.checked,
                  bagManoInStivaPartenza: "",
                },
                index
              )
            }
          />
        </div>
        <TextField
          size="small"
          label="Andata"
          type="number"
          fullWidth
          disabled={dati.compresoManoInStivaA ? true : false}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          value={dati.bagManoInStivaPartenza || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            settaDati(arr, { bagManoInStivaPartenza: e.target.value }, index);
          }}
        />
      </Grid>
      <Grid item xs={4} style={{ display: "flex" }}>
        <div>
          <span style={{ fontSize: 14 }}>Comp.</span>
          <input
            type="checkbox"
            checked={dati.compresoManoInStivaR ? true : false}
            onChange={(e) =>
              settaDati(
                arr,
                {
                  compresoManoInStivaR: e.target.checked,
                  bagManoInStivaArrivo: "",
                },
                index
              )
            }
          />
        </div>
        <TextField
          size="small"
          label="Ritorno"
          type="number"
          fullWidth
          disabled={dati.compresoManoInStivaR ? true : false}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          value={dati.bagManoInStivaArrivo || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            settaDati(arr, { bagManoInStivaArrivo: e.target.value }, index);
          }}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          size="small"
          label="€ / pax"
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          disabled
          variant="outlined"
          className="inputCustom euro"
          value={dati.bagManoInStivaTotale || ""}
          onWheel={(e) => e.target.blur()}
        />
      </Grid>

      {/* bag stiva ------------------------- */}

      <Grid item xs={12}>
        Bag. in Stiva (
        {"A: " + pesoBagStivaAndata + "kg | R: " + pesoBagStivaRitorno + "kg"})
      </Grid>
      <Grid item xs={4} style={{ display: "flex" }}>
        <div>
          <span style={{ fontSize: 14 }}>Comp.</span>
          <input
            type="checkbox"
            checked={dati.compresoStivaA ? true : false}
            onChange={(e) =>
              settaDati(
                arr,
                { compresoStivaA: e.target.checked, bagStivaPartenza: "" },
                index
              )
            }
          />
        </div>
        <TextField
          size="small"
          label="Andata"
          type="number"
          fullWidth
          disabled={dati.compresoStivaA ? true : false}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          value={dati.bagStivaPartenza || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            settaDati(arr, { bagStivaPartenza: e.target.value }, index);
          }}
        />
      </Grid>
      <Grid item xs={4} style={{ display: "flex" }}>
        <div>
          <span style={{ fontSize: 14 }}>Comp.</span>
          <input
            type="checkbox"
            checked={dati.compresoStivaR ? true : false}
            onChange={(e) =>
              settaDati(
                arr,
                { compresoStivaR: e.target.checked, bagStivaArrivo: "" },
                index
              )
            }
          />
        </div>
        <TextField
          size="small"
          label="Ritorno"
          type="number"
          fullWidth
          disabled={dati.compresoStivaR ? true : false}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom euro"
          value={dati.bagStivaArrivo || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            settaDati(arr, { bagStivaArrivo: e.target.value }, index);
          }}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          size="small"
          label="€ / pax"
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          disabled
          variant="outlined"
          className="inputCustom euro"
          value={dati.bagStivaTotale || ""}
          onWheel={(e) => e.target.blur()}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          size="small"
          label="Note"
          multiline
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          className="inputCustom"
          value={dati.noteAereo || ""}
          onChange={(e) => {
            settaDati(arr, { noteAereo: e.target.value }, index);
          }}
        />
      </Grid>
    </>
  );
};

export default Comp;
