import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import LibriAggiorna from "./LibriAggiorna";
// import PrenotaLibro from "./PrenotaLibro";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Pallino, convertiData, controllaLogin, monFetch } from "../inc/inc";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const Comp = (props) => {
  //const ruoloLogin = controllaLogin();

  let location = useLocation();

  const [prezzi, setPrezzi] = useState([]);

  async function queryPrezzi() {
    const res = await monFetch("/priceList", {});
    // console.log(res);
    setPrezzi(res.price);
  }

  async function salvaPrezzo(dati, index) {
    const res = await monFetch("/priceSet", dati);
    if (res.success) {
      settaPrezzo(index, "disabled", true);
    }
    queryPrezzi();
  }

  function settaPrezzo(index, key, value) {
    let temp = [...prezzi];
    temp[index][key] = value;
    setPrezzi(temp);
  }

  useEffect(() => {
    queryPrezzi();
    let barraTop = (
      <>
        <h2>Modifica Prezzi</h2>
      </>
    );
    props.setElementiBarraTop(barraTop);
  }, []);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            {/* <TableCell>Azienda</TableCell> */}
            <TableCell>Descrizione</TableCell>
            <TableCell>Area</TableCell>
            <TableCell>Da</TableCell>
            <TableCell>Fino a</TableCell>
            <TableCell>Valore</TableCell>
            <TableCell>Extra</TableCell>
            {/* <TableCell>Data da</TableCell>
            <TableCell>Data a</TableCell> */}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {prezzi.map((prezzo, index) => {
            return (
              <TableRow
                key={prezzo.id}
              // className="rigaSelezionabile"
              >
                {/* <TableCell>{prezzo.company_name}</TableCell> */}
                <TableCell>{prezzo.description}</TableCell>
                <TableCell>{prezzo.area}</TableCell>
                <TableCell>
                  {prezzo.startingfrom !== null && (
                    <TextField
                      size="small"
                      label="Da"
                      fullWidth
                      disabled={prezzo.disabled !== false}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="inputCustom"
                      value={prezzo.startingfrom || 0}
                      onChange={(e) => {
                        settaPrezzo(index, "startingfrom", e.target.value);
                      }}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {prezzo.upto !== null && (
                    <TextField
                      size="small"
                      label="Fino a"
                      fullWidth
                      disabled={prezzo.disabled !== false}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="inputCustom"
                      value={prezzo.upto || 0}
                      onChange={(e) => {
                        settaPrezzo(index, "upto", e.target.value);
                      }}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    label="Valore"
                    fullWidth
                    type="number"
                    disabled={prezzo.disabled !== false}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="inputCustom"
                    value={prezzo.value || 0}
                    onChange={(e) => {
                      settaPrezzo(index, "value", e.target.value);
                    }}
                  />
                </TableCell>
                <TableCell>
                  {prezzo.extra !== null && (
                    <TextField
                      size="small"
                      label="Extra"
                      fullWidth
                      type="number"
                      disabled={prezzo.disabled !== false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      className="inputCustom"
                      value={prezzo.extra || 0}
                      onChange={(e) => {
                        settaPrezzo(index, "extra", e.target.value);
                      }}
                    />
                  )}
                </TableCell>

                <TableCell style={{ minWidth: 250 }}>
                  {prezzo.disabled !== false ? (
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        settaPrezzo(index, "disabled", false);
                      }}
                    >
                      Modifica
                    </Button>
                  ) : (
                    <>
                      <Button
                        style={{ marginRight: 15 }}
                        onClick={(e) => {
                          settaPrezzo(index, "disabled", true);
                        }}
                      >
                        Annulla
                      </Button>
                      <Button
                        variant="contained"
                        onClick={(e) => {
                          salvaPrezzo(
                            {
                              id: prezzo.id,
                              priceKey: prezzo.priceKey,
                              description: prezzo.description,
                              area: prezzo.area,
                              upto: Number(prezzo.upto),
                              startingfrom: Number(prezzo.startingfrom),
                              value: Number(prezzo.value),
                              extra: Number(prezzo.extra),
                              date_from: prezzo.date_from,
                              date_to: prezzo.date_to,
                            },
                            index
                          );
                        }}
                      >
                        Salva
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {/* <Popup open={popupElimina} fnAnnulla={(e)=>setPopupElimina(false)} 
      fnSuccess={(e)=>eliminaprezzo(utenteDaEliminare)}
      /> */}
    </>
  );
};

// const Popup = (props) => {
//   return (
//     <Dialog open={props.open} onClose={props.fnAnnulla}>
//       <DialogTitle>Conferma Eliminazione</DialogTitle>
//       <DialogContent>
//         <DialogContentText>
//           Confermi l'eliminazione dell'elemento?
//         </DialogContentText>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={props.fnAnnulla}>Annulla</Button>
//         <Button onClick={props.fnSuccess} variant="contained">
//           Elimina
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

export default Comp;
