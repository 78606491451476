import Button from "@mui/material/Button";
import { checkAutista, monFetch, monForm } from "../../inc/inc";
import { useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import CalculateIcon from "@mui/icons-material/Calculate";
import ArticleIcon from "@mui/icons-material/Article";

import { MediaLista } from "./Media";

const Comp = (props) => {
  let params = useParams();
  let isModifica = false;
  let id = null;
  if (params.id) {
    id = params.id;
    isModifica = true;
  }
  let location = useLocation();

  const [codice, setCodice] = useState("");
  const [codiceKreo, setCodiceKreo] = useState("");
  const [barraTop, setBarraTop] = useState(null);
  const [barraTop2, setBarraTop2] = useState(null);

  // const [isAutista, setIsAutista] = useState(false);

  async function queryPreventivo() {
    const res = await monFetch("/quoteGet", {
      id,
      fromMedia: true,
    });

    const prev = res.quote;
    setCodice(prev.code || "");
    setCodiceKreo(prev.code_kreosoft || "");
  }

  useEffect(() => {
    queryPreventivo();
    if (!codice) {
      return;
    }

    let barraTop = (
      <h2 style={{ marginBottom: 0, marginTop: 0 }}>
        {codice != "" && <span className="numeroPreventivo">
          {codice} {codiceKreo ? " - K" + codiceKreo : ""}
        </span>}
      </h2>
    );
    let barraTop2;

    if (isModifica) {
      barraTop2 = (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <Button
            variant="contained"
            href={"/calcoloPreventivo/" + id}
            // color="info"
            style={{ marginRight: 15 }}
            title="Vai alla pagina di calcolo"
          >
            <CalculateIcon />
          </Button>
          <Button
            variant="contained"
            href={
              checkAutista() ? "/calcoloPreventivo/" + id : "/preventivo/" + id
            }
            title="Vai al preventivo"
          >
            <ArticleIcon />
          </Button>
        </div>
      );
    }
    setBarraTop(barraTop);
    setBarraTop2(barraTop2);
  }, [codice]);

  return (
    <>
      {barraTop && (
        <MediaLista
          idPrev={id}
          setElementiBarraTop={props.setElementiBarraTop}
          elementiBarraTop={barraTop}
          elementiBarraTop2={barraTop2}
          codice={codice}
        />
      )}
    </>
  );
};

export default Comp;
