import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { monFetch } from "../../inc/inc";

import { useLocation, useParams } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

import "react-image-crop/dist/ReactCrop.css";

const Comp = (props) => {
  let params = useParams();
  let isModifica = false;

  let id = params.id;
  const [nome, setNome] = useState("");
  const [cognome, setCognome] = useState("");
  const [email, setEmail] = useState("");

  const [errore, setErrore] = useState("");

  // const [aziende, setAziende] = useState([]);
  // const [azienda, setAzienda] = useState([]);

  const [optionsCategorie, setOptionsCategorie] = useState([]);
  // const [optionsCategorieSel, setOptionsCategorieSel] = useState([]);
  const [categorie, setCategorie] = useState([]);

  const [attivoDa, setAttivoDa] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [attivoA, setAttivoA] = useState("");

  async function query(cat) {
    const res = await monFetch("/faqUserGet", {
      id,
    });

    setCognome(res.res.last_name);
    setNome(res.res.first_name);
    setEmail(res.res.email);
    // setAzienda(res.res.company_id);
    setAttivoDa(res.res.active_from);
    setAttivoA(res.res.active_to);

    ///categorie
    // setCategorie(res.res.categories);
    let temp = [];
    res.res.categories.forEach((el) => {
      ////cerco nelle categorie
      let opt = cat.find((el2) => {
        return el.id == el2.id;
      });
      if (opt) {
        opt.from = el.from;
        opt.to = el.to;
        temp.push(opt);
      }
    });
    setCategorie(temp);
  }

  async function queryCategorie() {
    const res = await monFetch("/faqCategoryList", {});
    // console.log(res);
    let temp = [];
    res.res.forEach((el) => {
      temp.push({ label: el.name, id: el.id });
    });
    // console.log(aziende);
    setOptionsCategorie(temp);
    return temp;
  }

  async function salva(e) {
    e.preventDefault();
    console.log(categorie);

    const res = await monFetch("/faqUserSet", {
      id,
      first_name: nome,
      last_name: cognome,
      email: email,
      // company_id: azienda,
      categories: categorie,
      active_from: attivoDa,
      active_to: attivoA,
    });

    if (res.success) {
      window.location.href = "/faqutenti";
    } else {
      setErrore("Errore nel salvataggio dei dati");
    }
  }

  useEffect(() => {
    async function fn() {
      let cat = await queryCategorie();
      if (id) {
        query(cat);
      }
      // queryAziende();
    }
    fn();

    let barraTop = <h2>{!id ? "Nuovo Utente Faq" : "Modifica Utente Faq"}</h2>;
    props.setElementiBarraTop(barraTop);
  }, []);

  return (
    <form onSubmit={(e) => salva(e)}>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          {errore && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {errore}
            </Alert>
          )}
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextField
            required
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            className="inputCustom"
            value={email}
            // disabled={id ? true : false}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextField
            required
            label="Nome"
            fullWidth
            variant="outlined"
            className="inputCustom"
            value={nome}
            // disabled={id ? true : false}
            onChange={(e) => {
              setNome(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextField
            label="Cognome"
            required
            fullWidth
            variant="outlined"
            className="inputCustom"
            value={cognome}
            // disabled={id ? true : false}
            onChange={(e) => {
              setCognome(e.target.value);
            }}
          />
        </Grid>
        {/* <Grid item sm={4} xs={12}>
          <Autocomplete
            value={aziende.find((op) => op.id == azienda) || null}
            onChange={(e, value) => {
              // console.log("change autoc", aziende, value);
              if (!value) {
                return;
              }
              setAzienda(value.id);
            }}
            options={aziende}
            renderInput={(params) => (
              <TextField {...params} label="Azienda" required />
            )}
          />
        </Grid> */}

        <Grid item sm={6} xs={12}>
          <Autocomplete
            multiple
            value={categorie}
            onChange={(e, value) => {
              // console.log("change autoc", value);
              const temp = [];
              //const opzioniSelezionate = [];
              value.forEach((el, index) => {
                temp.push({ id: el.id, name: el.label });
              });
              console.log(value);
              setCategorie(value);
              // setOptionsCategorieSel(value);
            }}
            options={optionsCategorie}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Categorie"
                className="inputCustom"
              />
            )}
          />
        </Grid>

        <Grid item sm={3} xs={12}>
          <TextField
            label="Attivo da"
            type="date"
            fullWidth
            variant="outlined"
            className="inputCustom"
            value={attivoDa || ""}
            onChange={(e) => {
              setAttivoDa(e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item sm={3} xs={12}>
          <TextField
            label="Attivo Fino a"
            type="date"
            fullWidth
            variant="outlined"
            className="inputCustom"
            value={attivoA || ""}
            onChange={(e) => {
              setAttivoA(e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item sm={12} xs={12}>
          <h4 style={{ marginTop: 0, marginBottom: 15 }}>Limita Categorie</h4>
          {categorie.map((el, index) => {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingBottom: 7,
                  borderBottom: "1px solid #eee",
                  marginBottom: 10,
                }}
              >
                <span style={{ marginRight: 15 }}>{el.label}</span>
                <TextField
                  label="Attivo Da"
                  type="date"
                  size="small"
                  variant="outlined"
                  className="inputCustom"
                  value={el.from || ""}
                  onChange={(e) => {
                    let temp = [...categorie];
                    temp[index].from = e.target.value;
                    setCategorie(temp);
                  }}
                  style={{ marginRight: 15 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="Attivo Fino a"
                  type="date"
                  size="small"
                  variant="outlined"
                  className="inputCustom"
                  value={el.to || ""}
                  onChange={(e) => {
                    let temp = [...categorie];
                    temp[index].to = e.target.value;
                    setCategorie(temp);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            );
          })}
        </Grid>

        <Grid item sm={12} xs={12}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              className="inputCustom"
              variant="contained"
              href="/faqutenti"
            >
              Indietro
            </Button>

            <Button
              className="inputCustom bottoneSalva"
              type="submit"
              variant="contained"
            >
              Salva
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

export default Comp;
